export const DELETED_USER = 'Deleted User';
export const DELETED_USERGROUP = 'Deleted User Group';
export const RECURRING = 'Recurring';
export const ONETIME = "One time";
export const CREATION_DATE_FORMAT = 'MM/dd/yyyy - hh:mm aa';
export const DATE_FORMAT = 'MM/dd/yyyy';

export const GIFTCARDS = {
    ISSUED_USER_TYPE: {
        SINGLE_USER : 'singleUser',
        USER_GROUP : 'userGroup',
    },
    ISSUE_TYPES: {
        LABEL_NAMES : {
            USER : "User",
            GROUP : "Group",
        },
        TAB_BUTTON_NAMES : {
            SINGLE_USER : 'Single User',
            USER_GROUP :  'User Group'
        },
        DROPDOWN_LABEL : {
            ADD_USER : "Add User" ,
            ADD_GROUP : "Add Group"
        },
        PLACEHOLDER:{
            SEARCH : 'Type to search'
        }
    },

}