import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PortalHelp from 'app/pages/auth/PortalHelp';

import Dashboard from './Dashboard/Dashboard';
import Tickets from './Tickets/Tickets';
import TicketsMessageBox from './Tickets/components/TicketsMessageBox';
import SalesAndReportingPage from './SalesReport/SalesAndReportingPage';
// eslint-disable-next-line import/no-named-as-default
import Customers from './Customers/components/Customers/Customers';
// eslint-disable-next-line import/no-named-as-default
import CustomerDetails from './Customers/components/Customers/components/CustomerDetails/CustomerDetails';
import InviteCustomers from './Customers/components/InviteCustomers/InviteCustomers';
import Loyalty from './Loyalty/Loyalty';
import LoyaltyDetails from './Loyalty/components/LoyaltyDetails/LoyaltyDetails';
// eslint-disable-next-line import/no-named-as-default
import Groups from './Customers/components/Groups/Groups';
import GroupDetails from './Customers/components/Groups/components/GroupDetails/GroupDetails';
import Tabs from './Customers/components/Tabs/Tabs';
// eslint-disable-next-line import/no-named-as-default
import Sales from './Ledger/components/Sales/Sales';
// eslint-disable-next-line import/no-named-as-default
import SaleDetails from './Ledger/components/SaleDetails/SaleDetails';
import AddOns from './AddOns/AddOns';
import AddOnsDetails from './AddOns/components/AddOnsDetails/AddOnsDetails';
import Operators from './Operators/Operators/Operators';
import OperatorDetails from './OperatorDetails/OperatorDetails';
import Locations from './Operators/Locations/Locations';
import LocationDetails from './Operators/Locations/components/LocationDetails/LocationDetails';
// eslint-disable-next-line import/no-named-as-default
import Promotions from './Promotions/Promotions';
// eslint-disable-next-line import/no-named-as-default
import PromotionDetails from './Promotions/components/PromotionDetails/PromotionDetails';
import General from './Setup/General/General';
import Tax from './Setup/Tax/Tax';
import Users from './Setup/Users/Users';
import UserDetails from './Setup/Users/components/UserDetails/UserDetails';
import Roles from './Setup/Roles/Roles';
import RoleDetails from './Setup/Roles/components/RoleDetails/RoleDetails';
import SetupOperators from './Setup/SetupOperators/SetupOperators';
import SetupLocations from './Setup/SetupLocations/SetupLocations';
import SetupLocationDetails from './Setup/SetupLocations/components/SetupLocationDetails/SetupLocationDetails';
import LocationGroups from './Setup/SetupLocations/components/LocationGroups/LocationGroups';
import LocationGroupDetails
  from './Setup/SetupLocations/components/LocationGroups/components/LocationGroupDetails/LocationGroupDetails';
import OrderEquipment from './Setup/SetupLocations/components/OrderEquipment/OrderEquipment';
import { LayoutSplashScreen } from '../../_metronic';
import Products from './Products/components/Products/Products';
import ProductDetails from './Products/components/Products/components/ProductDetails/ProductDetails';
import OperatorApplication from './OperatorApplication/components/OperatorApplication/OperatorApplication';
import ApplicationsTable from './OperatorApplication/components/ApplicationsTable/ApplicationsTable';
// eslint-disable-next-line import/no-named-as-default

import { OrderHistory } from './Ledger/components/OrderHistory/OrderHistory';
import GiftCards from './Ledger/components/GiftCards/GiftCards';
import CashCollections from './AddOns/CashCollections';
import GiftCardsDetails from './Ledger/components/GiftCards/GiftCardsDetails/GiftCardsDetails';
import BillValidatorDetails from './AddOns/components/BillValidatorDetails/BillValidatorDetails';
import AddOperator from './Operators/AddOperator/AddOperator';

export default function HomePage(props) {
  return (
    <Suspense fallback={<LayoutSplashScreen/>}>
      <Switch>
        <Redirect exact from="/" to="/dashboard"/>
        <Route path="/dashboard" component={() => <Dashboard {...props}/>}/>
        <Route path="/tickets" exact component={() => <Tickets {...props}/>}/>
        <Route path="/tickets/:id" component={() => <TicketsMessageBox {...props}/>}/>
        <Route path="/report/sales" component={() => <SalesAndReportingPage {...props}/>}/>
        <Route path="/report/inventory" component={() => <SalesAndReportingPage {...props}/>}/>
        <Route path="/report/metrics" component={() => <SalesAndReportingPage {...props}/>}/>
        <Route path="/report/customers" component={() => <SalesAndReportingPage {...props}/>}/>
        <Route path="/report/tax" component={() => <SalesAndReportingPage {...props}/>}/>
        <Route path="/report/loyalty" component={() => <SalesAndReportingPage {...props}/>}/>
        <Route path="/report/kiosks" component={() => <SalesAndReportingPage {...props}/>}/>
        <Route path="/report/balances" component={() => <SalesAndReportingPage {...props}/>}/>
        <Route path="/report/cash" component={() => <SalesAndReportingPage {...props}/>}/>
        <Route path="/customers" exact component={() => <Customers {...props}/>}/>
        <Route path="/customers/:id" exact component={() => <CustomerDetails {...props}/>}/>
        <Route path="/customers/invite" exact component={() => <InviteCustomers {...props}/>}/>
        <Route path="/groups" exact component={() => <Groups {...props}/>}/>
        <Route path="/groups/:id" exact component={() => <GroupDetails {...props}/>}/>
        <Route path="/tabs" exact component={() => <Tabs {...props}/>}/>
        <Route path="/sales/order" exact component={() => <Sales {...props}/>}/>
        <Route path="/sales/valueAdd" exact component={() => <Sales {...props}/>}/>
        <Route path="/sales/tabPayment" exact component={() => <Sales {...props}/>}/>
        <Route path="/giftCards" exact component={() => <GiftCards {...props}/>}/>
        <Route path="/giftCards/:id" exact component={() => <GiftCardsDetails {...props}/>}/>
        <Route path="/sales/carts" exact component={() => <Sales {...props}/>}/>
        <Route path="/sales/:id" exact component={() => <SaleDetails {...props}/>}/>
        <Route path="/loyalty" exact component={() => <Loyalty {...props}/>}/>
        <Route path="/loyalty/:id" exact component={() => <LoyaltyDetails {...props}/>}/>
        <Route path="/cashCollections" exact component={() => <CashCollections {...props}/>}/>
        <Route path="/cashCollections/:id" exact component={() => <BillValidatorDetails {...props}/>}/>
        <Route path="/add-ons/coolers" exact component={() => <AddOns {...props}/>}/>
        <Route path="/add-ons/coolers/:locationId/:id" exact component={() => <AddOnsDetails {...props}/>}/>
        <Route path="/operators" exact component={() => <Operators {...props}/>}/>
        <Route path="/operators/new" exact component={() => <AddOperator {...props}/>}/>
        <Route path="/operators/:id" exact component={() => <OperatorDetails {...props}/>}/>
        <Route path="/locations" exact component={() => <Locations {...props}/>}/>
        <Route path="/locations/:id" exact component={() => <LocationDetails {...props}/>}/>
        <Route path="/promotions" exact component={() => <Promotions {...props}/>}/>
        <Route path="/promotionsTest/:id" exact component={() => <PromotionDetails {...props}/>}/>
        <Route path="/setup/general" exact component={() => <General {...props}/>}/>
        <Route path="/setup/tax" exact component={() => <Tax {...props}/>}/>
        <Route path="/setup/users" exact component={() => <Users {...props}/>}/>
        <Route path="/setup/users/:id" exact component={() => <UserDetails {...props}/>}/>
        <Route path="/setup/roles" exact component={() => <Roles {...props}/>}/>
        <Route path="/setup/roles/:id" exact component={() => <RoleDetails {...props}/>}/>
        <Route path="/setup/operators" exact component={() => <SetupOperators {...props}/>}/>
        <Route path="/setup/operators/:id" exact component={() => <OperatorDetails {...props}/>}/>
        <Route path="/setup/locations" exact component={() => <SetupLocations {...props}/>}/>
        <Route path="/setup/locations/:id" exact component={() => <SetupLocationDetails {...props}/>}/>
        <Route path="/setup/locations/:id/equipment" exact component={() => <OrderEquipment {...props}/>}/>
        <Route path="/setup/groups" exact component={() => <LocationGroups {...props}/>}/>
        <Route path="/setup/groups/:id" exact component={() => <LocationGroupDetails {...props}/>}/>
        <Route path="/setup/applications" exact component={() => <ApplicationsTable {...props}/>}/>
        <Route path="/setup/applications/:id" exact component={() => <OperatorApplication {...props}/>}/>
        <Route path="/products/all" exact component={() => <Products {...props}/>}/>
        <Route path="/products/:id" exact component={() => <ProductDetails {...props}/>}/>
        <Route path="/help" component={() => <PortalHelp {...props}/>}/>
        <Route path="/orderHistory" exact component={() => <OrderHistory {...props}/>}/>
        <Redirect to="/error/error-v1"/>
      </Switch>
    </Suspense>
  );
}
