import React, { useState, useEffect } from 'react';
import to from 'await-to-js';
import clsx from 'clsx';
import { Button, Paper, Tab, Tabs } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import ResponseModal from 'app/components/Helpers/ResponseModal';
import { accessPermission } from 'app/common/helperFunction';
import LoadingSpinner from 'app/partials/content/LoadingSpinner';
import { useCustomResponse } from 'app/hooks/useCustomResponse';

import Search from '../../../partials/content/Customers/Search';
import UsersTable from './components/UsersTable/UsersTable';
import { getLoggedUserAccountId2, hasMultipleAccounts } from '../../../services/helpers/account-id';
import { getAccountUsers } from '../../../services/account';
import  OperatorSpecificModal  from '../../Helpers/OperatorSpecificModal';

import styles from './users.module.scss';
import { PAGINATION_INITIAL_STATE } from 'app/common/utils/generic';
import useDebounce from 'app/hooks/useDebounce';

const rolesFilters = [{
  id: -1,
  name: 'All Roles'
}, {
  id: 1,
  name: 'Account Owner'
}, {
  id: 2,
  name: 'Admin'
}];

const locationsFilter = [{
  id: -1,
  name: 'All Locations'
}];

export default function Users(props) {
  const { filter: { locations } } = props;
  const { push } = useHistory();
  const [users, setUsers] = useState([]);
  const [totalUser, setTotalUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [operatorSpecificModalOpen, setOperatorSpecificModalOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [responseValue, setResponseValue] = useCustomResponse()
  const [page, setPage] = useState(0);
  const[limit,setLimit]= useState(PAGINATION_INITIAL_STATE.limit)
  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION_INITIAL_STATE.limit);
  const[pagination,setPagination]=useState({});
  const searchQuery = useDebounce(search,1500);


  /**
   * Fetch users data on component rendering
   */
  console.log("responseValue",responseValue);
  useEffect(() => {
    
    if(!accessPermission("SetupAll")){
      push({pathname: "/dashboard"});
    }

    if (locations && locations.length){
    }
    if(searchQuery.length>=3){
//set page = 1 while passing searchkeyword
      getUsersList(limit,0,searchQuery);
    }else{
      getUsersList(limit,page);
    }
  }, [searchQuery, page, limit]);


  useEffect(() => {
    /*
    * filtering users depend on serach string (name and email)
    */
    const filteredUser = totalUser.filter((ele) => {
      const fullName = `${ele.firstName} ${ele.lastName}`;
      return ele.email.toLowerCase().indexOf(searchQuery.trim().toLowerCase()) !== -1 || fullName.toLowerCase().indexOf(searchQuery.trim().toLowerCase()) !== -1
    })

    setUsers(filteredUser);

  },[searchQuery])

  async function getUsersList(limit,page,searchQuery) {

    if(hasMultipleAccounts()){
      setOperatorSpecificModalOpen(true);
      return
    }

    const id = getLoggedUserAccountId2();
    const [err, res] = await to(getAccountUsers(id,limit,page+1,searchQuery));
    setIsLoaded(true);
    if (err){
      Sentry.captureException(err);
      if (!hasMultipleAccounts())
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`Error while getting user list. ${err}`});    
      return console.error(err);
    }

    const { users } = res?.data ?? [];
    if (users) {
      setUsers(users);
      setTotalUsers(users);
      setPagination(res?.data?.pagination)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getUsersList(limit, newPage);
  };
 
  const handleChangeRowsPerPage = (event) => {
    const newSize = parseInt(event.target.value);
    const newPage = parseInt(((page * rowsPerPage) + 1) / newSize);
    setRowsPerPage(newSize);
    setLimit(newSize);
    setPage(newPage);
    getUsersList(newSize,newPage);
  };
 

  function renderAddButton() {
    return (
      <div className={clsx('d-flex justify-content-end', styles.btn)}>
        <Button
          startIcon={<Add/>}
          size="large"
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => push({ pathname: '/setup/users/new' })}
        >
          Add
        </Button>
      </div>
    );
  }

  function changeEnabled(row) {
    const updatedUsers = users?.map(user => {
      if (user.id !== row.id)
        return user;

      return {
        ...user,
        enabled: !user.enabled
      };
    });

    setUsers(updatedUsers);
  }

  
  return (
    <>
      {!isLoaded ? (
        <LoadingSpinner/>
      ) : (
      <>
        <Paper id="users_paper_wrapper"  className={clsx(styles.wrapper, 'd-flex justify-content-between')}>
          <Tabs 
            id="users_tabs"
            value="users"
            onChange={() => push({ pathname: '/setup/roles' })}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab id="users_tab_users" label="Users" value="users"/>
            <Tab id="users_tab_roles" label="Roles" value="roles"/>
          </Tabs>
          {renderAddButton()}
        </Paper>

        <div id="users_search_root_wrapper" className={clsx(styles.searchWrapper, 'd-flex', 'justify-content-between', 'align-items-center')}>
          <div id="users_inner_search_wrapper"  className="d-flex">
            <Search
              id="users"
              label="Search by email or name"
              placeholder="Search"
              iconPosition="start"
              query={searchQuery}
              onChange={e => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div id="users_table_wrapper" >
          <UsersTable
           rows={users}
           pagination={pagination}
           onEnabledChange={changeEnabled}
           handleChangePage={handleChangePage}
           handleChangeRowsPerPage={handleChangeRowsPerPage}
           rowsPerPage={rowsPerPage}
           page={page}

          />
        </div>
        <ResponseModal
          isSuccess={responseValue?.isSuccess}
          message={responseValue?.message}
          open={responseValue?.isMessageOpen}
          onClose={value => setResponseValue({...responseValue, isMessageOpen:value})}
        />
      </>)}
      {operatorSpecificModalOpen && (
        <OperatorSpecificModal
          open={operatorSpecificModalOpen}
          onClose={value => setOperatorSpecificModalOpen(value)}
        />
      )}
    </>
  );
}
