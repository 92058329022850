import React, { useEffect, useState } from 'react';
import to from 'await-to-js';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import csvDownload from 'json-to-csv-export'
import 'jspdf-autotable'
import jsPDF from 'jspdf';
import * as Sentry from "@sentry/react";

import ResponseModal from 'app/components/Helpers/ResponseModal';
import LoadingSpinner from 'app/partials/content/LoadingSpinner';
import { accessPermission } from 'app/common/helperFunction';
import OperatorSpecificModal from 'app/components/Helpers/OperatorSpecificModal';
import { getLoggedUserAccountId2, hasMultipleAccounts } from 'app/services/helpers/account-id';
import { getEveryLocations } from 'app/services/locations';
import useCustomerHeader from 'app/hooks/useCustomHeader';
import { getOperatorDetails } from 'app/services/account';
import { CANCEL_TOKEN, CATEGORIES } from "app/common/constant";

import Filter from '../../../../partials/content/Filter';
import ProductsTable from './components/ProductsTable/ProductsTable';
import ExportAndCustomFilters from '../../../../partials/content/ExportAndCustomFilters';
import { addTopProduct, deleteTopProduct, getLocationsProducts, getProductsFilters, getTopFeatured, locationsForProduct } from '../../../../services/products';

import styles from './products.module.scss';
import { useCustomResponse } from 'app/hooks/useCustomResponse';
import { reportFormats } from 'app/services/reports';
import SearchWithDropdown from 'app/partials/layout/SearchWithDrop/SearchWithDrop';

let searchTimeout = null;

export default function Products(props) {
  const { filter: { locations } } = props;
  const { push } = useHistory();
  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFeaturedLoaded, setIsFeaturedLoaded] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10
  });
  const [availableFilters, setAvailableFilters] = useState({
    brand: [
      { id: 1, name: 'All Brands' }
    ],
    supplier: [
      { id: 1, name: 'All Suppliers' }
    ],
    type: [
      { id: 1, name: 'All Catergories' }
    ],
    keywords: [
      { id: 1, name: 'All Keywords' }
    ],
    status: [
      { id: 1, name: 'Enabled' },
      { id: 2, name: 'Disabled' }
    ]
  });
  const [filters, setFilters] = useState({
    brand: 'All Brands',
    supplier: 'All Suppliers',
    type: 'All Categories',
    keywords: 'All Keywords',
    status: 'Enabled'
  });

  const [search, setSearch] = useState('');
  const [category, setCatagory] = useState('');
  const [operatorSpecificModalOpen, setOperatorSpecificModalOpen] = useState(false);
  const [featuredProductsData, setFeaturedProductsData] = useState({});
  const [everyLocationId, setEveryLocationId] = useState([]);
  const { subHeader, setSubHeader } = useCustomerHeader({
    staticHeader: "Products",
  });
  const [responseValue, setResponseValue] = useCustomResponse();

  useEffect(() => {
    if(!accessPermission("ProductsAll")){
      console.log("user have no access for this page");
      push({pathname: "/dashboard"});
    }
    else if(!hasMultipleAccounts()) {
      getProductFilterList();
      getEveryLocationId();
      getOperatorData();
    } 
  }, []);

  /**
   * Fetch data on component mounting
   * and avoid higher level props initiate re-render
   */
  useEffect(() => {
    if (locations && locations.length) {
      getProductsList(!isLoaded, {
        ...filters,
       [category]:search
      });

    }
  }, [pagination, filters, search, category]);

  useEffect(() => {
    if(everyLocationId.length > 0)
      getFeaturedProductsList();
  }, [everyLocationId]);

  /**
     * Proceed products
     */
  async function getProductsList(isInitialFetch, filters) {
    if(hasMultipleAccounts()){
      setOperatorSpecificModalOpen(true);
      return
    }
    const [err, res] = await to(getLocationsProducts(pagination, filters));
    if (err){
      if(err.message == CANCEL_TOKEN)return;
      Sentry.captureException(err);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`Error while getting product list. ${err}`});     
      return console.error('Failed to fetch products list: ', err);
    }
    const {
      data: { products },
      meta: { totalItems }
    } = res?.data;

    setProducts(products?.map(p => ({ ...p, isExpanded: false })));
    setTotalProducts(Number(totalItems));
    setIsLoaded(true);
  }

  /**
   * set the Header
   */
  const getOperatorData = async() => {
    const id = getLoggedUserAccountId2();
    const [err, res] = await to( getOperatorDetails(id));
    if(err){
      if(err.message === CANCEL_TOKEN) return;
      Sentry.captureException(err);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, messge:`Error while fetching the inventory name. ${err}`}); 
      return  console.error('Unable to fetch inventory name ', err);
    }
        const marketName = res?.data?.provider?.name;
        setSubHeader(subHeader.innerText = `Products${ marketName ? " || "+ marketName : ""}`);
  }

  async function getEveryLocationId() {
    const [locErr, locRes] = await to(getEveryLocations(true, true));
    if (locErr){
      if(locErr.message === CANCEL_TOKEN) return;
      Sentry.captureException(locErr);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, messge:`Error while getting locations for featured product list. ${locErr}`}); 
      return console.error('Failed to fetch locations for featured products list: ', locErr);
    }
    let localEveryLocationId = locRes.data.Locations.map(elm => elm.id);
    setEveryLocationId(localEveryLocationId);
  }

  async function getFeaturedProductsList() {
    const [err, res] = await to(getTopFeatured());
    if (err){
      if(err.message === CANCEL_TOKEN) return;
      Sentry.captureException(err);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`Error while getting featured product list. ${err}`});     
      return console.error('Failed to fetch featured products list: ', err);
    }
    let mappedFeaturedProducts = {};
    let mappedFeaturedProductsFull = {};
    res.data.data.featuredProducts.map((value, index, arr) => {

      if (mappedFeaturedProducts[value.operatorProductId] == undefined) {
        mappedFeaturedProducts[value.operatorProductId] = [value.locationId]
        mappedFeaturedProductsFull[value.operatorProductId] = [value]
      } else {
        mappedFeaturedProducts[value.operatorProductId].push(value.locationId)
        mappedFeaturedProductsFull[value.operatorProductId].push(value)
      }
    });

    setFeaturedProductsData(mappedFeaturedProductsFull);
    setIsFeaturedLoaded(true);
  }

  async function addFeaturedProduct(productId, everywhere) {
    let locationIds = []
    if (everywhere) {
      locationIds = locationIds.concat(everyLocationId);
      if (locationsForProduct(productId, featuredProductsData)) {
        locationsForProduct(productId, featuredProductsData).forEach(locationId => {
          if(locationIds.includes(locationId)) {
           const index = locationIds.indexOf(locationId);
           locationIds.splice(index,1);
          }
        })
     }
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const locationId = urlParams.get("location");
      locationIds.push(locationId);
    }

    const [err, res] = await to(addTopProduct({locationIds: locationIds, operatorProductIds: [productId]}));
    if (err){
      Sentry.captureException(err);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`Error while getting featured product list. ${err}`});
      return console.error('Failed to fetch featured products list: ', err);
    }

    const add = locationIds.map(locationId=>{
      return {locationId, 'operatorProductId' : productId, id: `${productId}-${locationId}`}
    })
      if(featuredProductsData[productId] == undefined){
        featuredProductsData[productId] = add;
      } else {
        featuredProductsData[productId].push(...add);
      }

    setFeaturedProductsData({...featuredProductsData});
  }

  async function deleteFeaturedProduct(productId, everywhere) {
    let locationIds = []
    if (everywhere) {
      locationIds = everyLocationId;
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const locationId = urlParams.get("location");
      locationIds.push(locationId);
    }

    try {
      const [err, res] = await to(deleteTopProduct({locationIds:locationIds, operatorProductIds: [productId]}));
      if (err){
        Sentry.captureException(err);
        setResponseValue({...responseValue, isSuccess:false, message:`Error while getting featured product list. ${err}`});        
        return console.error('Failed to fetch featured products list: ', err);
      }
      delete featuredProductsData[productId];
      setFeaturedProductsData({...featuredProductsData});
    }catch (error) {
      console.log(error)
    }
  }

  /**
   * get product filters list
   */
  async function getProductFilterList(){
    const [err, res] = await to(getProductsFilters());

    if(err){
      Sentry.captureException(err);     
      return console.error('Failed to fetch products filters list: ', err);
    }

    const {data:{brands = [], suppliers = [], categories = [] }} = res.data;

    setAvailableFilters(curr => ({
      ...curr,
      brand: [
        { id: 1, name: 'All Brands' },
        ...brands.map(({ brandId: id, name }) => ({ id, name }))
      ],
      supplier: [
        { id: 1, name: 'All Suppliers' },
        ...suppliers.map(({ supplierId: id, name }) => ({ id, name }))
      ],
      type: [
        { id: 1, name: 'All Categories' },
        ...categories.map(({ categoryId: id, name }) => ({ id, name }))
      ]
    }));
  }

  /**
     * Mark row as selected (checkbox)
     * @param id
     */
  function selectRow(id) {
    if (selectedRows.includes(id))
      setSelectedRows(selectedRows.filter(item => item !== id));
    else
      setSelectedRows([...selectedRows, id]);
  }

  /**
   * Select all rows
   */
  function masterToggle() {
    if (selectedRows.length)
      setSelectedRows([]);
    else
      setSelectedRows(products.map(({ productId }) => productId));
  }

  /**
     * Expand row
     * @param id
     */
  function expandRow(id) {
    setProducts(curr => (curr.map(p => {
      if (p.id === id)
        return {
          ...p,
          isExpanded: !p.isExpanded
        };

      return p;
    })));
  }

  

  // /**
  //  * Set new search string value
  //  */
  const handleSearch = (category, searchTerm) => {
    setCatagory(category);
    setSearch(searchTerm)
    if(pagination.page !== 1)
      setPagination(prevValue => {
          return {page:1,limit: prevValue.limit}
        })
  };
  /**
   * Update selected filters
   */
  function handleFilterChange(e) {
    const { value, name } = e.target;

    setFilters(currentFilters => ({ ...currentFilters, [name]: value }));
    if(pagination.page !== 1)
      setPagination(prevValue => {
          return {page:1,limit: prevValue.limit}
        })
  }

  async function handleExport(type) {

    if(hasMultipleAccounts()){
      setOperatorSpecificModalOpen(true);
      return
    }

    const [err, res] = await to(getLocationsProducts({
      page: 1,
      limit: totalProducts
    }, {
      ...filters,
      keywords: search !== "" ? search : "All Keywords",
    }));
    if (err)
      return console.error(`[Products and Stocks]. Error while fetching data. ${err}`);

    const rows = res.data.data.products;

    if(type === 'pdf'){

      const exportRows = rows.map(ele => [
        ele.name? ele.name: '',
        !ele.product.brand ? 'N/A' : (ele.product.brand.name || 'N/A'),
        !ele.supplier ? 'N/A' : ele.supplier,
        `$ ${(+ele.price - +ele.cost).toFixed(2)}`,
        `$ ${ele.price.toFixed(2)}`,
        !ele.active ? 'Deactivated' : 'Active',
        format(new Date(ele.created), 'dd MMM yyyy'),
       ]);

        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";
        const marginLeft = 30;
        const doc = new jsPDF(orientation,unit, size);
        doc.setFontSize(15);

        const title = "Products";
        const headers = [["Product", "Brand", "Supplier", "Revenue", "Retail Price", "Status", "Created"]];

        let content = {
          startY: 50,
          head: headers,
          body: exportRows,
          styles: {overflow: 'linebreak',
                fontSize: 8},
        };

        doc.text(title, marginLeft, 30);
        doc.autoTable(content);
        doc.save(`Products - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);

    } else if (type === 'csv') {

        const exportRows = [];
        rows.forEach(ele => {
          let obj = {
            Product : ele.name? ele.name: '',
            Brand : !ele.product.brand ? 'N/A' : (ele.product.brand.name || 'N/A'),
            Supplier : !ele.supplier ? 'N/A' : ele.supplier,
            Revenue : `$ ${(+ele.price - +ele.cost).toFixed(2)}`,
            'Retail Price':`$ ${ele.price.toFixed(2)}`,
            Status :!ele.active ? 'Deactivated' : 'Active', 
            Created : format(new Date(ele.created), 'dd MMM yyyy')
          }
          exportRows.push(obj);
        })

      csvDownload(exportRows, `products - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);
    }
  }

 

  return (
    <>
      <ExportAndCustomFilters hasFilters={false} onExport={handleExport} newFilterTag={reportFormats.PDF}/>
      <>
        <div  id={`product_wrapper`} className={styles.searchWrapper}>
          <div id={`product_inner_wrapper`} className={styles.searchProductField}>
          <SearchWithDropdown 
            categories={CATEGORIES} 
            placeholder="Enter name, SKU or supplier code" 
            onSearch={handleSearch}  />
          </div>

          <div id={`product_inner_filter_wrapper`} className={clsx(styles.searchFields, styles.filterAlign)}>
            <Filter
              className={styles.filterOverride}
              isFullWidth
              label="Product Categories"
              name="type"
              selectedFilter={filters.type}
              options={availableFilters.type}
              onChange={handleFilterChange}
            />
          </div>
        
          <div id={`product_inner_search_filter_wrapper_0`} className={styles.searchFields}>
            <Filter
              className={styles.filterOverride}
              isFullWidth
              label="Supplier"
              name="supplier"
              selectedFilter={filters.supplier}
              options={availableFilters.supplier}
              onChange={handleFilterChange}
            />
          </div>

          <div id={`product_inner_search_filter_wrapper_1`} className={styles.searchFields}>
            <Filter
              className={styles.filterOverride}
              isFullWidth
              label="Brand"
              name="brand"
              selectedFilter={filters.brand}
              options={availableFilters.brand}
              onChange={handleFilterChange}
            />
          </div>

          <div id={`product_inner_search_filter_wrapper_2`} className={styles.searchFields}>
            <Filter
              className={styles.filterOverride}
              isFullWidth
              label="Status"
              name="status"
              selectedFilter={filters.status}
              options={availableFilters.status}
              onChange={handleFilterChange}
            />
          </div>
        </div>
        <div id={`product_inner_add_space`} className={styles.addSpace}></div>
        {!isLoaded ? (
          <LoadingSpinner/>
        ) : (
          <ProductsTable
            rows={products}
            totalProducts={totalProducts}
            selectedRows={selectedRows}
            pagination={pagination}
            onMasterToggle={() => masterToggle()}
            onSelectRow={id => selectRow(id)}
            onExpandRow={id => expandRow(id)}
            onEditRowClick={id => push({ pathname: `/products/${id}` })}
            onPaginationChange={value => setPagination(value)}
            addFeaturedProduct={(id, everywhere) => addFeaturedProduct(id, everywhere)}
            deleteFeaturedProduct={(id, everywhere) => deleteFeaturedProduct(id, everywhere)}
            featuredProducts={featuredProductsData}
            everyLocationId={everyLocationId}
            isFeaturedLoaded={isFeaturedLoaded}
          />
        )}
        <ResponseModal
          isSuccess={responseValue?.isSuccess}
          message={responseValue?.message}
          open={responseValue?.isMessageOpen}
          onClose={value => setResponseValue({...responseValue, isMessageOpen:value})}
        />
      </>
      {operatorSpecificModalOpen && (
        <OperatorSpecificModal
          open={operatorSpecificModalOpen}
          onClose={(value) => setOperatorSpecificModalOpen(value)}
        />
      )}
    </>
  );
}
