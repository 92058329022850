export const HTTP_RESPONSE_MESSAGE = {
    REFRESH_RATES:{
      SUCCESS : 'Refresh Rate for All Kiosks updated successfully.',
      ERROR:'Unable to update Refresh rates'
    },
    TWO_TIER_PRICING:{
      SUCCESS: "Successfully added Two Tier Pricing",
      ERROR: "Unable to save two tier pricing ",
    },
    ADDRESS: {
      SUCCESS: "Thank you for filling out your address information!",
      ERROR: "Unable to save your address information",
    },
    CUSTOMERS: {
      GROUPS:{
        ERROR : "[Customers]. Error while fetching data group of selected customers.",
        NO_CUSTOMERS_FOUND :  "No customers found in this group",
      }
    }
  }