import { add, sub } from 'date-fns';
import moment from "moment";

import { getLoggedUserAccountId2 } from '../../../services/helpers/account-id';
import {
  reportTypeFilters,
  measureFilters
} from '../../../partials/content/SalesAndReport/salesReport.mock';
import { calculateInitialTime } from 'app/services/helpers/getDefaultParameters';
import { getStartDateAndEndDateInISOFormat } from "app/services/helpers/getDefaultParameters";

/**
 * Service function to prepare correct json query from current state
 */
export function prepareJsonQuery({
  selectedReportTypeFilter,
  selectedMeasureFilter,
  selectedPeriod,
  selectedTimeFilter,
}) {
  const today = new Date();
  const id = getLoggedUserAccountId2();
  const selectedReportType = reportTypeFilters.find(item => item.name === selectedReportTypeFilter).alias;
  const selectedMeasure = measureFilters.find(item => item.name === selectedMeasureFilter).alias;
  // list of default table metrics
  const metrics = ['revenue', 'basketValue', 'grossProfit', 'margin', 'tax'];
  const periodStart = sub(today, { [!selectedPeriod ? 'years' : selectedPeriod]: 1 });

  if (!metrics.includes(selectedMeasure))
    metrics.push(selectedMeasure);

  const metricsWithTitles = measureFilters
    .filter(item => metrics.includes(item.alias));

  return {
    query: {
      accountIds: [id],
      timeMetric: selectedTimeFilter,
      dateRanges: [
        {
          datebegin: periodStart.toISOString(),
          dateend: today.toISOString()
        }
      ],
      reports: [
        {
          name: 'summary',
          reportTypeCategory: 'ORDER',
          reportTypes: selectedReportType,
          metrics
        },
        {
          name: 'byLocations',
          reportTypeCategory: 'ORDER',
          enableGroupbyDate: true,
          reportTypes: selectedReportType === 'SalesSummary' ? 'Locations' : selectedReportType,
          metrics
        }
      ]
    },
    reportRelatedData: {
      dateRange: {
        startDate: periodStart,
        endDate: today
      },
      metrics: {
        values: metrics,
        titles: metricsWithTitles,
        selectedMeasure
      }
    }
  };
}

export function preparePayloadObj({
  selectedReportTypeFilter,
  selectedMeasureFilter,
  urlParams,
}) {
  const {
    startDate,
    endDate,
    selectedTimeFilter,
  } = getStartDateAndEndDateInISOFormat();

  const payloadObj = {
    locationIds: urlParams.get("location"),
    accountIds: urlParams.get("operator"),
    reports: [{
      reportType: selectedReportTypeFilter === "Customers" ? "customers" : "sales",
      metric:
        measureFilters.find(ele => ele.name === selectedMeasureFilter).alias,
      startDate: startDate,
      endDate: endDate,
      timeMetric: selectedTimeFilter,
    }],
  };
  return payloadObj;
} 
