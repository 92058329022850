import React, { useEffect, useState } from "react";
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const WIDTH = 250;
const ALL = 'all';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: WIDTH,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const MultiSelect = ({
  options = [],
  selectedOption = "",
  setSelectedOptions,
  placeholder="placeholder",
  disableDropDown = false,
  disableDefault= false // Used in theme.jsx to disable the default location, can be used to disable the option selected by default.
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const isAllSelected =
    options?.length > 0 && selected?.length === options?.length;

  const handleChange = (event) => {
    const value = event?.target?.value;
    if ((value?.length - 1 == options.length) && (disableDefault && selectedOption.length)) { // Condition to avoid deselecting default option if it is disabled. Used in Theme component to disable default selected location.
      setSelected([selectedOption])
      setSelectedOptions([selectedOption])
      return;
    } else if (value[value?.length - 1] === ALL) {
      setSelected(
        selected?.length === options?.length
          ? []
          : options?.map((option) => option?.id)
      );
      setSelectedOptions(
        selected?.length === options?.length
          ? []
          : options?.map((option) => option?.id)
      );
      return;
    }
    setSelected(value);
    setSelectedOptions(value);
  };
  useEffect(() => {
    setSelected([...selected, selectedOption]);
  }, []);

  const renderFunction = (selected) => {
    return selected
      ?.map(
        (value) => options?.find((option) => option?.id === value)?.name || ""
      )?.filter(Boolean).join(", ");
  };

  return (
    <Select
      className={classes.formControl}
      labelId="mutiple-select-label"
      placeholder={placeholder}
      multiple
      value={selected}
      onChange={handleChange}
      renderValue={renderFunction}
      MenuProps={MenuProps}
      disabled = {disableDropDown}
    >
      <MenuItem
        value="all"
        classes={{
          root: isAllSelected ? classes.selectedAll : "",
        }}
      >
        <ListItemIcon>
          <Checkbox
            classes={{ root: classes.indeterminateColor }}
            checked={isAllSelected}
            indeterminate={
              selected.length > 0 && selected.length < options.length
            }
          />
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.selectAllText }}
          primary="Select All"
        />
      </MenuItem>
      {options?.map((option) => (
        <MenuItem key={option?.id} value={option?.id} disabled = { disableDefault && option?.id == selectedOption }>
          <ListItemIcon>
            <Checkbox
              classes={{ root: selected.indexOf(option?.id) > -1 &&  classes.indeterminateColor }}
              checked={selected.indexOf(option?.id) > -1}
            />
          </ListItemIcon>
          <ListItemText primary={option?.name} />
        </MenuItem>
      ))}
    </Select>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
    overflowX: "hidden",
  },
  indeterminateColor: {
    color: "#f50057",
    "&.Mui-checked": {
      color: "#f50057 !important",
    },
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

export default MultiSelect;
