import {
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styles from "./GiftCardsBasicDetails.module.scss";
import map from "lodash/map";
import CustomButton from "app/components/Promotions/components/PromotionDetails/components/CustomButton/CustomButton";
import { Autocomplete } from "@material-ui/lab";
import { Delete } from "@material-ui/icons";
import { useStyles } from "app/components/Customers/components/Groups/components/GroupCustomers/Styles";
import { getCustomers } from "app/services/reports";
import to from "await-to-js";
import { getUserGroups } from "app/services/groups";
import { getLoggedUserAccountId2 } from "app/services/helpers/account-id";
import { useSelector } from "react-redux";
import { filterItemById } from "app/services/helpers/filterArrayById";
import { GIFTCARDS } from "app/common/giftCardsConst";

const GiftCardsTypeBtns = [
  { id: 1, title: GIFTCARDS.ISSUE_TYPES.TAB_BUTTON_NAMES.SINGLE_USER, value: GIFTCARDS.ISSUED_USER_TYPE.SINGLE_USER },
  { id: 2, title: GIFTCARDS.ISSUE_TYPES.TAB_BUTTON_NAMES.USER_GROUP, value: GIFTCARDS.ISSUED_USER_TYPE.USER_GROUP},
];

export default function IssueDetails(props) {
const {
  changeSelectedUser,
  changedSelectedGroup,
  changeToType,
  changeIssuedTo,
  selectedGiftCardId
} = props;

  const [amount, setAmount] = useState("");
  const [issueType, setIssueType] = useState(GIFTCARDS.ISSUED_USER_TYPE.SINGLE_USER);
  const bubbleStyles = useStyles();
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedGroup, setSelectedGroup] =  useState([]);
  const [search, setSearch] = useState("");
  const [noAudience, setNoAudience] = useState(true)
  const [optionsList, setOptionsList] = useState([]);
  const pagination = {limit: 10, page:1};
  const storedGiftCardsFromRedux = useSelector((state) => state.customers.giftCards);
  const [isUserGroupTabClicked,setIsUserGroupTabClicked] = useState(false)
  const isSingleUser = issueType === GIFTCARDS.ISSUED_USER_TYPE.SINGLE_USER
  function setIssueTyperMaster(value) {
    setIssueType(value)
    changeToType(value)
    setSelectedGroup([])
    setSelectedUser([])
    changeSelectedUser()
    changedSelectedGroup()
    setIsUserGroupTabClicked(value === GIFTCARDS.ISSUED_USER_TYPE.USER_GROUP)
  }
  useEffect(() => {
    handleEditIssueDetails();
    return;
  }, [selectedGiftCardId]);

  function handleEditIssueDetails () {
    try {
      const result = filterItemById(storedGiftCardsFromRedux,selectedGiftCardId)
      const userType = (result.user != null && isUserGroupTabClicked === false) ? GIFTCARDS.ISSUED_USER_TYPE.SINGLE_USER : GIFTCARDS.ISSUED_USER_TYPE.USER_GROUP;
        setIssueType(userType)
        changeToType(userType)
        if (result.user != null && issueType !== GIFTCARDS.ISSUED_USER_TYPE.USER_GROUP) {
          setSelectedUser(result.user)
          changeSelectedUser(result.user)
          changeIssuedTo(result.user.id)
        } else {
          setSelectedGroup(result.userGroup)
          changedSelectedGroup(result.userGroup)
          changeIssuedTo(result.userGroup.id)
        }
      } catch (error) {
        console.error('Error fetching gift card data:', error);
      }
  }

  useEffect(() => {
    if(isSingleUser)
      getCustomersData(search);
    else getGroupsForFilters();
  },[])

  useEffect(() => {
    if(isSingleUser)
      getCustomersData(search);
    else getGroupsForFilters();
  }, [issueType, search])

  function addUser(user) {
    //adding selected ele to there respective list
    if (user) {
      console.log(user)
      isSingleUser ? setSelectedUser(user) : setSelectedGroup(user)
      isSingleUser ? changeSelectedUser(user) : changedSelectedGroup(user)

      if (user.id == null) {
        setNoAudience(true)
      } else {
        setNoAudience(false)
      }
      
      changeIssuedTo(user.id)
    }
  }

  async function getCustomersData(search) {
    const [err, res] = await to(getCustomers(pagination, search));
    if (err) {
      return console.error(`[Customers]. Error while fetching data. ${err}`);
    }
    var users = res.data.users;

    const formatedUsers = FormatUsersInfo(users);

    setOptionsList(formatedUsers);
  }

  function FormatUsersInfo(users) {
    const uniformatUser = [];
    users.map((ele) => {
      uniformatUser.push(ele.user ? ele.user : ele);
    })
    return uniformatUser;
  }

  async function getGroupsForFilters() {
    const id = getLoggedUserAccountId2();
    const [err, res] = await to(getUserGroups(id, { limit: 10, page: 1 },null,search));
    if (err) {
      return console.error("[Groups]. Error while fetching data.");
    }
    var groups = res?.data?.groups;
    setOptionsList(res?.data?.groups);
  }

  function removeFromSingleUser() {

  }

  function removeFromUserGroup() {

  }

  function Bubble(props) {
    const { value, type } = props;
    return (
      <div id={`issue_details_bibble_box_container_wrapper`}className={bubbleStyles.bubbleContainer}>
        <div
          id={`issue_details_bibble_box_content`}
          className="customerName"
          style={{ whiteSpace: "nowrap", textAlign: "center" }}
        >
          {`${value != null ? value.name : ""}`}
        </div>
        <IconButton
          id={`issue_details_bibble_box_icon_button`}
          onClick={() => {
            if (type == GIFTCARDS.ISSUED_USER_TYPE.SINGLE_USER)
              removeFromSingleUser(value.id);
            else removeFromUserGroup(value.id);
          }}
        >
          <Delete />
        </IconButton>
      </div>
    );
  }

  return (
    <Grid
      id={`issue_details_grid_container`}
      container
      direction="row"
      alignItems="center"
      className={styles.wrapperGrid}
    >
      <Grid  id={`issue_details_grid_box_container`} item xs={10} className={styles.borderBox}>
        <div id={`issue_details_grid_box_inner_container`} className={styles.grids}>
          <Grid
            id={`issue_details_grid_box_inner_item_container`}
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid id={`issue_details_grid_box_inner_item_wrapper`} item className={styles.btnGroup}>
              {map(GiftCardsTypeBtns, (btn) => (
                <CustomButton
                  {...btn}
                  isActive={issueType === btn.value}
                  onClick={(value) => setIssueTyperMaster(value)}
                  key={btn.id}
                  hasMinWidth={false}
                  hasDivider={false}
                  small
                />
              ))}
            </Grid>
          </Grid>
        </div>

        <div id={`issue_details_grid_root_container`} className={styles.grids}>
          <Grid
            id={`issue_details_grid_inner_root_container`}
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Typography id={`issue_details_issue_type_typography`} variant="h5" className={styles.advancedHead}>
              {isSingleUser ? "User" : "Group"}
            </Typography>

            <Grid id={`issue_details_autocomplelte_grid_wrapper`} item xs={8}>
              <div
                 id={`issue_details_bibble_box_grid_wrapper`}
                className={bubbleStyles.bubbleBox}
                style={{ marginTop: "10px" }}
              >
                {(isSingleUser ? selectedUser != null ? selectedUser.email : "" : selectedGroup != null ? selectedGroup.name : "") ?? ""}
              </div>

              <Autocomplete
                id="combo-box-demo"
                noOptionsText={"Searching..."}
                fullWidth
                options={optionsList}
                getOptionLabel={(option) => (isSingleUser ? option.email ?? "" : option.name ?? "") ?? ""}
                onChange={(e, value) => addUser(value)}
                value={isSingleUser ? selectedUser : selectedGroup}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="note"
                    label={isSingleUser ? GIFTCARDS.ISSUE_TYPES.DROPDOWN_LABEL.ADD_USER : GIFTCARDS.ISSUE_TYPES.DROPDOWN_LABEL.ADD_GROUP}
                    placeholder= {GIFTCARDS.ISSUE_TYPES.PLACEHOLDER.SEARCH}
                    multiline
                    fullWidth
                    variant="outlined"
                    onChange={(e) => setSearch(e.target.value)}
                    onClick={(e) => setSearch(e.target.value)}
                    helperText={(noAudience) && (
                      <FormHelperText  id={`issue_details_required_form_helper_text_label`} style={{ color: "black", fontSize: "14px" }}>
                        Required
                      </FormHelperText>
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}
