import axios from "axios";

import { CANCEL_TOKEN } from "app/common/constant";

import {
  getDefaultParameters_V2,
  getStartAndEndDate,
} from "./helpers/getDefaultParameters";
import axiosInstance from "./helpers/axiosInstance";
import { getLoggedUserAccountId2, getScope } from "./helpers/account-id";
import { getOperatorsList } from "./account";
import { ROLE } from "app/common/roleConstant";

/**
 * Get list of all available locations
 * @returns {Promise<AxiosResponse<any>>}
 */
const CancelToken = axios.CancelToken;
let getLocationsCancel = null;
export function getLocations(
  includeKiosk = false,
  filter = false,
  keywords = "",
  getCompressedLocation = false,
  accountId = null,
  page = 1,
  limit =10
 
) {
  if (getLocationsCancel) {
    getLocationsCancel(CANCEL_TOKEN);
    getLocationsCancel = null;
  }
  let url = "/locations";
  url += `?keywords=${keywords}`;
  url += `&getCompressedLocation=${getCompressedLocation}`;
  url += `&page=${page}`;
  url += `&limit=${limit}`;
 
  if (includeKiosk) url += "&includeKiosk=true";

  if (filter) {
    url += getDefaultParameters_V2(false);
  }

  if (accountId) url += `&operator=${accountId}`;
  return axios.get(url, {
    cancelToken: new CancelToken(function executor(c) {
      getLocationsCancel = c;
    }),
  });
}

let getLocation2Cancel = null;
export function getLocations2(
  includeKiosk = false,
  filter = false,
  keywords = "",
  getCompressedLocation = false,
  accountId = null,
  limit = 10,
  page = 1
) {
  if (getLocation2Cancel) {
    getLocation2Cancel(CANCEL_TOKEN);
    getLocation2Cancel = null;
  }

  let url = "/locations";
  let currentUrl = new URL(window.location.href);
  let defaultParameters = "";
  url += `?keywords=${keywords}`;
  url += `&getCompressedLocation=${getCompressedLocation}`;
  url+= `&limit=${limit}`;
  url+= `&page=${page}`;
  if (includeKiosk) url += "&includeKiosk=true";

  if (filter) {
    defaultParameters = currentUrl.searchParams;

    if (defaultParameters) defaultParameters.set("location", "1");

    url += "&" + defaultParameters;
  }

  if (accountId) url += `&operator=${accountId}`;

  return axios.get(url, {
    cancelToken: new CancelToken(function executor(c) {
      getLocation2Cancel = c;
    }),
  });
}

/**
 * get locations list with always location 1
 */
let getEveryLocationsCancel = null;
export function getEveryLocations(
  filter = false,
  getCompressedLocation = false
) {
  if (getEveryLocationsCancel) {
    getEveryLocationsCancel(CANCEL_TOKEN);
    getEveryLocationsCancel = null;
  }

  let url = "/locations";
  url += `?getCompressedLocation=${getCompressedLocation}`;

  let currentUrl = new URL(window.location.href);
  let defaultParameters = "";
  if (filter) {
    defaultParameters = currentUrl.searchParams;

    if (defaultParameters) defaultParameters.set("location", "1");

    url += "&" + defaultParameters;
  }

  return axios.get(url, {
    cancelToken: new CancelToken(function executor(c) {
      getEveryLocationsCancel = c;
    }),
  });
}

/**
 * Get location by id
 */
export function getLocation(id, includeKiosk) {
  let url = `/locations/${id}`;

  if (includeKiosk) url += "?includeKiosk=true" + getDefaultParameters_V2(false);
  else url += getDefaultParameters_V2(true);

  return axios.get(url);
}

/**
 * Update location details based on user changes
 */
export function updateLocationDetails(id, location) {
  return axiosInstance.patch(`/locations/${id}`, location);
}

/**
 * Get location theme
 */
export function getLocationTheme(id) {
  return axios.get(`/locations/themes/${id}`);
}

/**
 * Update theme of location(s)
 */
export function updateLocationTheme(payload) {
  return axios.post(`/locations/themes/sync`, payload);
}

/**
 * Update kiosk details based on user changes
 */
export function updateKiosk(id, kiosk) {
  return axios.post(`/locations/kiosks/${id}`, kiosk);
}

/**
 * Send one of the commands to the kiosk
 */

export function sendKioskCommand(locationId, kioskId, command) {
  return axios.get(
    `/locations/${locationId}/kiosks/${kioskId}?command=${command}`
  );
}

export function uploadLogo(locationId, themeId) {
  return axiosInstance.patch(
    `/locations/${locationId}/themes/${themeId}/images`
  );
}

export function uploadImageToAws(uploadUrl, data) {
  return axiosInstance(uploadUrl, {
    method: "PUT",
    body: data,
  });
}

export function addingBillValidator(accountId, locationId, kioskId) {
  const payload = {
    accountId: accountId,
    locationId: locationId,
    kioskId: kioskId,
  };
  return axios.post("/billvalidators", payload);
}

export function billValidatorDetails(
  { page = 1, limit = 10 },
  billValidatorId,
  searchQuery
) {
  let url = `/billvalidators/${billValidatorId}?page=${page}&limit=${limit}`;
  url += getStartAndEndDate();
  if (searchQuery) {
    url += "&keywords=" + searchQuery;
  }
  return axios.get(url);
}

let getThemesCancel = null;
export function getLocationsThemes({ page = 1, limit = 10 }, searchQuery = "") {
  if (getThemesCancel) {
    getThemesCancel(CANCEL_TOKEN);
    getThemesCancel = null;
  }

  let url = `/locations/themes/search?page=${page}&limit=${limit}`;
  if (searchQuery) {
    url += "&keywords=" + searchQuery;
  }
  return axios.get(url, {
    cancelToken: new CancelToken(function executor(c) {
      getThemesCancel = c;
    }),
  });
}

/**
 * get kiosks
 */
let getKioskCancel = null;
export function getKiosks(page = 1, limit = 10) {
  if (getKioskCancel) {
    getKioskCancel(CANCEL_TOKEN);
    getKioskCancel = null;
  }

  const operatorId = getLoggedUserAccountId2();
  const location = new URLSearchParams(window.location.search).get("location");
  const queryParam =`page=${page}&limit=${limit}&operator=${operatorId}&location=${location}`
  return axios.get(`/kiosks/list?${queryParam}`,{
    cancelToken: new CancelToken(function executor(c) {
      getKioskCancel = c;
    }),
  });
}

export function getAllLocations() {
  return axios.get(`/locations/all`);
}