import React, { useState, useEffect, useCallback } from 'react';
import to from 'await-to-js';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { accessPermission } from 'app/common/helperFunction';
import { getScope, hasMultipleAccounts } from 'app/services/helpers/account-id';
import { useCustomResponse } from 'app/hooks/useCustomResponse';

import OperatorSpecificModal from "../Helpers/OperatorSpecificModal";
import Filter from '../../partials/content/Filter';
import AutocompleteFilter from './AutocompleteFilter';
import {
  reportTypeFilters,
  measureFilters
} from '../../partials/content/SalesAndReport/salesReport.mock';
import ReportTable from '../../partials/content/SalesAndReport/ReportTable';
import { getCustomers, getSalesReport_2 } from '../../services/reports';
import { preparePayloadObj, mapReportDateToView_V2 } from './helpers';
import ResponseModal from '../Helpers/ResponseModal';
import { getLocationsProducts } from 'app/services/products';
import BubbleContainer from './BubbleContainer';

import styles from '../../../_metronic/_assets/sass/pages/sales-report.module.scss';
import { useSelector } from 'react-redux';
import { getLocations } from 'app/services/locations';
import { CANCEL_TOKEN } from 'app/common/constant';
import { ROLE } from 'app/common/roleConstant';


let fixedMeasureFilter = measureFilters[6].name;
let fixedReportTypeFilter = reportTypeFilters[0].name;

export default function SalesAndReportingPage(props) {
  const {push} = useHistory();
  const { filter: { locations, period, location, operator, operators } } = props;
  
  const activeOperator = useSelector(
    (state) => state.subHeader.selectedOperator
    );  

  const [
    selectedReportTypeFilter,
    setSelectedReportTypeFilter
  ] = useState(fixedReportTypeFilter);
  const [
    selectedMeasureFilter,
    setSelectedMeasureFilter
  ] = useState(fixedMeasureFilter);
  const urlParams = new URLSearchParams(window.location.search);
  const selectedTimeFilter = urlParams.get("period");
  const defaultReportData = {
    firstHeadersRow: [],
    secondHeadersRow: [],
    body: [],
    footer: []
  };
  const [reportData, setReportData] = useState(defaultReportData);
  const [loading, setLoading] = useState({
    initialFetch: false,
    noData: false
  });
  const getLoadingMessage = useCallback(
    loading => {
      if (!loading.initialFetch)
        return 'Your report is loading...';
      if (loading.noData)
        return 'Not enough data to show the report';
    },
    []
  );
  const [reduxDataloading, setReduxDataLoading] = useState(true); // Track loading state

  const [operatorSpecificModalOpen, setOperatorSpecificModalOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [productSearch, setProductSearch] = useState('');
  const [customerSearch, setCustomerSearch] = useState('');
  const [showMoreFilters, setShowMoreFilters] = useState(false)
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 1000
  });

  const [filters, setFilters] = useState({
    keywords: '',
    status: 'Enabled'
  });

  const [responseValue, setResponseValue] = useCustomResponse();

  useEffect(() => {
    if(!accessPermission("SalesAndReportingAll")){
      push({pathname: "/dashboard"});
    }
  }, []);

  useEffect(() => {
    if(productSearch){
      handleGetProducts()
    }
  }, [productSearch])

  useEffect(() => {
    if(customerSearch) {
      handleGetCustomers()
    }
  }, [customerSearch])

  useEffect(() => {
    const delay = setTimeout(() => {
      setReduxDataLoading(false); 
    }, 0);

    return () => clearTimeout(delay); 
  }, []); 
  /**
   * Get reports for selected filters on available locations
   */
  useEffect(() => {
    if (locations && locations.length)
    if(hasMultipleAccounts()){
      setOperatorSpecificModalOpen(true);
      return
    }
      if (!reduxDataloading){
        getDataForReport2();
      } 
    // eslint-disable-next-line
  }, [
    reduxDataloading,
    period,
    location,
    selectedMeasureFilter,
    selectedReportTypeFilter,
    selectedTimeFilter
  ]);

  const storedLocationsFromRedux = useSelector(
    (state) => state.subHeader.compressedLocationList
  );


  async function getDataForReport2() {
    setLoading({ ...loading, initialFetch: true });
    const storedLocations = storedLocationsFromRedux?.length ? storedLocationsFromRedux: []
    
    const payloadObj = preparePayloadObj({
      selectedReportTypeFilter,
      selectedMeasureFilter,
      urlParams,
    })
    if(!payloadObj?.locationIds || !payloadObj?.accountIds) {
      setLoading({ ...loading, initialFetch: false });
      return;
    }
    
    if (payloadObj?.locationIds) {
      // Make the API call only if locationIds is defined, not null and not empty
      const [err, res] = await to(getSalesReport_2(payloadObj));

      if (err) {
        Sentry.captureException(err);
        setResponseValue({ ...responseValue, isMessageOpen: true, isSuccess: false, message: `Error while getting report data. ${err}` });
        setLoading({ ...loading, initialFetch: false });
        return console.error('Error with fetching the report data: ', err);
      }

    const {reports} = res.data;

    if(!(reports && reports.length)) {
      setLoading({ initialFetch: false, noData: true });
      return;
    }

    const mappedReport = storedLocations?.length && mapReportDateToView_V2(res.data, storedLocations);
    if (storedLocations && storedLocations?.length !== 0) {
      setReportData(mappedReport);
    } else {
      setReportData(defaultReportData);
    }
   }
      setLoading({ ...loading, initialFetch: false });
  }

  function handleProductSearch(search) {
      setProductSearch(search);
  }

  function handleGetProducts(){
    getProducts({
      ...filters,
      keywords: productSearch,
    });
  }

  async function getProducts(filters){
    const products = await to(getLocationsProducts(pagination,filters));
    setProducts(products[1]?.data?.data?.products)
  }

  function handleCustomerSearch(search) {
    setCustomerSearch(search)
  }

  function handleGetCustomers(){
    getCustomersData(customerSearch)
  }

  async function getCustomersData(search, groupFilter) {
    const [err, res] = await to(getCustomers(pagination, search, groupFilter));
    if (err) {
      Sentry.captureException(err);
      setResponseValue({
        ...responseValue,  
        isMessageOpen: true,
        isSuccess:false,
        message:`We had trouble grabbing your customers.\nError: ${err}`
      });
      return console.error(`[Customers]. Error while fetching data. ${err}`);
    }
    var users = res.data.users
    const updatedUsers = users.map((user) =>{return {...user , name: `${user.firstName} ${user.lastName}`}})

    setCustomers(updatedUsers);
  }

function addProducts(product) {
  setSelectedProducts((cur) => [...cur,product])
}

function removeProducts(product) {
  const newProductList = selectedProducts.filter((ele) => ele.productId !== product.productId);
  setSelectedProducts(newProductList);
}

function addCustomers(customer){
  setSelectedCustomers((cur) => [...cur,customer])
}

function removeCustomers(customer){
  const newCustomersList = selectedCustomers.filter((ele) => ele.id !== customer.id);
  setSelectedCustomers(newCustomersList);
}

  return (
    <>
      <div id={`sales_and_reporting_page_wrapper_0`}>
        <Filter
          selectedFilter={selectedReportTypeFilter}
          label="Report Type"
          options={reportTypeFilters}
          onChange={(e) => {setSelectedReportTypeFilter(e.target.value); fixedReportTypeFilter = e.target.value}}
        />
        <Filter
          selectedFilter={selectedMeasureFilter}
          label="Measure"
          options={measureFilters}
          onChange={(e) => {setSelectedMeasureFilter(e.target.value); fixedMeasureFilter = e.target.value;}}
        />
        {showMoreFilters ? (
          <>
            <AutocompleteFilter
              label="Products"
              options={products}
              placeholder="Type to search"
              TextFieldOnChange={(e) => {
                handleProductSearch(e);
              }}
              addElements={addProducts}
            />
            <AutocompleteFilter
              label="Customers"
              options={customers}
              placeholder="Type to search"
              TextFieldOnChange={(e) => {
                handleCustomerSearch(e);
              }}
              addElements={addCustomers}
            />
          </>
        ) : null}
      </div>
      {loading.noData ? (
        getLoadingMessage(loading)
      ) : (
        <>
          <div id={`sales_and_reporting_page_wrapper_1`}>
            {selectedProducts && selectedProducts.length > 0 && (
              <BubbleContainer
                title="Selected Products"
                options={selectedProducts}
                removeElements={removeProducts}
              />
            )}

            {selectedCustomers && selectedCustomers.length > 0 && (
              <BubbleContainer
                title="Selected Customers"
                options={selectedCustomers}
                removeElements={removeCustomers}
              />
            )}
          </div>
        </>
      )}
      <div id={`sales_and_reporting_page_wrapper_2`} className={styles.wrapper}>
        <ReportTable
          data={reportData}
          selectedMeasureFilter={selectedMeasureFilter}
          selectedReportTypeFilter
          loading={loading}
        />
      </div>
      <ResponseModal
        isSuccess={responseValue?.isSuccess}
        message={responseValue?.message}
        open={responseValue?.isMessageOpen}
        onClose={(value) => setResponseValue({...responseValue, isMessageOpen:value})}
      />
      {operatorSpecificModalOpen && (
        <OperatorSpecificModal
          open={operatorSpecificModalOpen}
          onClose={(value) => setOperatorSpecificModalOpen(value)}
        />
      )}
    </>
  );
}
