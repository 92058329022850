import React, { useState } from 'react';
import clsx from 'clsx';
import to from 'await-to-js';
import {
  Typography,
  Button,
  Card,
  CardContent,
  Select,
  InputLabel,
  Input,
  MenuItem, FormControl, TextField
} from '@material-ui/core';
import isEmpty  from 'lodash/isEmpty';
import * as Sentry from "@sentry/react";
import { useSelector } from 'react-redux';

import { updateKiosk } from 'app/services/locations';
import ResponseModal from 'app/components/Helpers/ResponseModal';
import { useCustomResponse } from 'app/hooks/useCustomResponse';
import { BLANK, GLOBAL_CONNECT, INFORMATION, KIOSK_IN_ESPER, KIOSK_IN_ESPER_DISABLE, NO_OPENER, REBOOT_KIOSK } from 'app/common/constant';
import { getScope } from 'app/services/helpers/account-id';
import { ROLE } from 'app/common/roleConstant';

import styles from './kiosk.module.scss';
import { getLoyaltyProgram } from '_metronic/layout/MenuConfig';


export default function Kiosk(props) {
  const {
    id,
    name,
    serialNumber,
    uniqueLoginString,
    barcodeScanner,
    tabletType,
    mount,
    creditCardReader,
    color,
    connectionType,
    sendCommand,
    cellProvider,
    diagnosticReport,
    index,
  } = props;
  const [_serialNumber, setSerialNumber] = useState(serialNumber);
  const [_uniqueLoginString, setUniqueLoginString] = useState(uniqueLoginString);
  const [_barcodeScanner, setBarcodeScanner] = useState(barcodeScanner);
  const [_mount, setMount] = useState(mount);
  const [_creditCardReader, setCreditCardReader] = useState(creditCardReader);
  const [_cellProvider, setCellProvider] = useState(cellProvider)
  const [_color, setColor] = useState(color);
  const [_tabletType, setTabletType] = useState(tabletType);
  const [_password, setPassword] = useState('')
  const [responseValue, setResponseValue] = useCustomResponse();
  const loyaltyProgram = useSelector(getLoyaltyProgram);
  const isSuperAdmin = getScope() === ROLE.SUPERADMIN
  /**
   *  grabbing KIOSK_ESPER_URL from env file
   *  */
  const {REACT_APP_KIOSK_ESPER_URL} = process.env


  const cardReaders = [
    {
      id: 1,
      name: 'IP'
    },
    {
      id: 2,
      name: 'Rambler'
    },
    {
      id: 3,
      name: 'C2X'
    }
  ];
  const barcodeScanners = [
    {
      id: 1,
      name: 'IP'
    },
    {
      id: 2,
      name: 'NLV3101'
    },
    {
      id: 3,
      name: 'Zebra/Dolphin'
    }
  ];

  const colors = [
    {
      id: 1,
      name: 'White'
    },
    {
      id: 2,
      name: 'Black'
    }
  ];

  const mountTypes = [
    {
      id: 1,
      name: 'Table Top'
    },
    {
      id: 2,
      name: 'Wall'
    }
  ];

  const tabletTypes = [
    {
      name: 'Apple'
    },
    {
      name: 'Samsung'
    }
  ];

  const cellProviders = [
    {
      name: 'None'
    },
    {
      name: 'AT&T'
    },
    {
      name: 'Verizon'
    }
  ]

  async function onSave() {
    console.log(_serialNumber)
    const payload = _password
      ? {
          serialNumber: _serialNumber,
          uniqueLoginString: _uniqueLoginString,
          barcodeScanner: _barcodeScanner,
          tabletType: _tabletType,
          mount: _mount,
          creditCardReader: _creditCardReader,
          color: _color,
          cellProvider: _cellProvider,
          connectionType,
          password: _password,
        }
      : {
          serialNumber: _serialNumber,
          uniqueLoginString: _uniqueLoginString,
          barcodeScanner: _barcodeScanner,
          tabletType: _tabletType,
          mount: _mount,
          creditCardReader: _creditCardReader,
          color: _color,
          cellProvider: _cellProvider,
          connectionType,
        };
    const [err, res] = await to(updateKiosk(id, payload))

    if (err) {
      Sentry.captureException(err);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`We had trouble updating this kiosk. ${err}`})
      return console.error(`Kiosk failed updating ${err}`)
    } else {
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:true, message:`Kiosk updated!`})

    }
  }
  const esperId = !isEmpty(diagnosticReport) && diagnosticReport?.blob?.esperId;

  return (
    <Card className={styles.cardWrapper}>
      <CardContent>
        <div className={clsx('d-flex', 'justify-content-between', styles.wrapper)}>
          <Typography variant="h5">{name}</Typography>
          <div className={styles.btnWrapper}>
            {loyaltyProgram != GLOBAL_CONNECT &&
            <Button
              id="kiosk_reboot_kiosk"
              size="small"
              variant="outlined"
              color="primary"
              disableElevation
              onClick={() => sendCommand('reboot')}
            >
             {REBOOT_KIOSK}
            </Button>
            }
            {isSuperAdmin && <Button
              size="small"
              id="kiosk_in_esper"
              variant="outlined"
              color="primary"
              disabled={isEmpty(esperId)}
              disableElevation
              onClick={() => window.open(`${REACT_APP_KIOSK_ESPER_URL}/${esperId}/${INFORMATION}`, BLANK, NO_OPENER)}
            >
              {!isEmpty(esperId) ? KIOSK_IN_ESPER : KIOSK_IN_ESPER_DISABLE}
            </Button>}
          </div>
        </div>
        <div className={clsx('d-flex', styles.infoWrapper)}>
          <div className={styles.subInfoWrapper}>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel htmlFor="serialNumber">
                Serial Number
              </InputLabel>
              <Input
                id={`#${index+1}_kiosk_serialNumber`}
                value={_serialNumber}
                onChange={e => setSerialNumber(e.target.value)}

              ></Input>
            </FormControl>
          </div>
          <div className={styles.subInfoWrapper}>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel htmlFor="uniqueLoginString">
                Kiosk ID
              </InputLabel>
              <Input
                id={`#${index+1}_kiosk_uniqueLoginString`}
                value={_uniqueLoginString}
              ></Input>
            </FormControl>
          </div>
          <div className={styles.subInfoWrapper}>
            <TextField
              id={`#${index+1}_kiosk_password`}
              type="password"
              margin="normal"
              label="Kiosk Password"
              value={_password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          <div className={styles.subInfoWrapper}>
            <div className={styles.selectBlock}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel htmlFor="creditCardReaderHardwareLabel">
                  Credit Card Reader Hardware
                </InputLabel>

                <Select
                  label="Credit Card Reader Hardware"
                  labelId="creditCardReaderHardwareLabel"
                  id={`#${index+1}_kiosk_creditCardReaderHardware`}
                  className={styles.input}
                  value={_creditCardReader}
                  onChange={e => setCreditCardReader(e.target.value)}
                >
                  {cardReaders.map(item => {
                    const { name, id } = item;

                    return (
                      <MenuItem key={id} value={name}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

          </div>
          <div className={styles.subInfoWrapper}>
            <div className={styles.selectBlock}>

              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel htmlFor="barcodeScannerHardwareLabel">
                  Barcode Scanner Hardware
                </InputLabel>

                <Select
                  label="Credit Card Reader Hardware"
                  labelId="barcodeScannerHardwareLabel"
                  id={`#${index+1}_kiosk_barcodeScannerHardware`}
                  className={styles.input}
                  value={_barcodeScanner}
                  onChange={e => setBarcodeScanner(e.target.value)}
                >
                  {barcodeScanners.map(item => {
                    const { name, id } = item;

                    return (
                      <MenuItem key={id} value={name}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

          </div>
          <div className={styles.subInfoWrapper}>
            <div className={styles.selectBlock}>

              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel htmlFor="tabletTypeLabel">
                  Tablet Type
                </InputLabel>

                <Select
                  label="Tablet Type"
                  labelId="tabletTypeLabel"
                  id={`#${index+1}_kiosk_tabletType`}
                  className={styles.input}
                  value={_tabletType}
                  onChange={e => setTabletType(e.target.value)}
                >
                  {tabletTypes.map(item => {
                    const { name } = item;

                    return (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={styles.selectBlock,styles.subInfoWrapper}>

            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel htmlFor="mountLabel">
                Mount
              </InputLabel>

              <Select
                label="Mount Type"
                labelId="mountLabel"
                id={`#${index+1}_kiosk_mount`}
                className={styles.input}
                value={_mount}
                onChange={e => setMount(e.target.value)}
              >
                {mountTypes.map(item => {
                  const { name, id } = item;

                  return (
                    <MenuItem key={id} value={name}>
                      {name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className={styles.selectBlock,styles.subInfoWrapper}>

            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel htmlFor="colorLabel">
                Color
              </InputLabel>

              <Select
                label="Color"
                labelId="colorLabel"
                id={`#${index+1}_kiosk_color`}
                className={styles.input}
                value={_color}
                onChange={e => setColor(e.target.value)}
              >
                {colors.map(item => {
                  const { name } = item;

                  return (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className={styles.selectBlock,styles.subInfoWrapper}>

            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel htmlFor="cellularProviderLabel">
                Cellular Provider
              </InputLabel>

              <Select
                label="Cellular Provider"
                labelId="cellularProviderLabel"
                id={`#${index+1}_kiosk_cellularProvider`}
                className={styles.input}
                value={_cellProvider}
                onChange={e => setCellProvider(e.target.value)}
              >
                {cellProviders.map(item => {
                  const { name } = item;

                  return (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>
        <Button
          className={styles.saveButton}
          size="large"
          variant="outlined"
          color="primary"
          disableElevation
          onClick={() => onSave()}
        >
          Save
        </Button>
      </CardContent>
      <ResponseModal
      isSuccess={responseValue?.isSuccess}
      message={responseValue?.message}
      open={responseValue?.isMessageOpen}
      onClose={value => setResponseValue({...responseValue, isMessageOpen:value})}
      />
    </Card>
  );
}
