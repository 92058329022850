import axios from "axios";

import { CANCEL_TOKEN, HTTP_HEADER_DETAILS } from "app/common/constant";

import {
  getDefaultParameters_V2,
  getStartDateAndEndDateInISOFormat,
} from "./helpers/getDefaultParameters";
import _ from "lodash";
import axiosInstance from "./helpers/axiosInstance";
const cancelToken = axios.CancelToken;

export const reportFormats = {
  CSV: "csv",
  XLS: "xls",
  JSON: "json",
  PDF: "pdf",
  ARRAYBUFFER: "arraybuffer",
};
/**
 *  Get dashboard charts and tables data
 * @param jsonQuery
 *  * {
 * "accountIds": string[],
 * "locationIds": string[],
 * "groupIds": string[],
 * "kioskIds": string[],
 * "timeMetric": string, ps: "week" is default
 *  and can be by second, minute, hour, day, week, month, quarter or year *
 * "datebegin": date string,
 * "dateend": date string
 * }
 * @returns {Promise<AxiosResponse<any>>}
 */
function getContentTypeHeader(givenReportFormat) {
  let contentTypeHeader;
  if (givenReportFormat === reportFormats.CSV) {
    contentTypeHeader = HTTP_HEADER_DETAILS.CONTENT_TYPE.CSV;
  } else if (givenReportFormat === reportFormats.XLS) {
    contentTypeHeader = HTTP_HEADER_DETAILS.CONTENT_TYPE.XLS;
  } else if (givenReportFormat === reportFormats.JSON) {
    contentTypeHeader = HTTP_HEADER_DETAILS.CONTENT_TYPE.JSON;
  }
  const responseTypeHeader =
    contentTypeHeader === HTTP_HEADER_DETAILS.CONTENT_TYPE.JSON
      ? reportFormats.JSON
      : reportFormats.ARRAYBUFFER;
  return { contentTypeHeader, responseTypeHeader };
}

export function getDashboardReport(jsonQuery) {
  const encodedJson = encodeURIComponent(JSON.stringify(jsonQuery));

  return axios.get(
    `/reports/dashboard/homepage?dashboardjsonquery=${encodedJson}` +
      getDefaultParameters_V2(false)
  );
}

let postDashboardCancel = null;
export function getDashboardReportV2(payload) {
  if (postDashboardCancel) {
    postDashboardCancel(CANCEL_TOKEN);
    postDashboardCancel = null;
  }
  return axios.post(`/reports/dashboard`, payload, {
    cancelToken: new cancelToken(function executor(c) {
      postDashboardCancel = c;
    }),
  });
}

let postFundsreportCancel = null;
export function getFundsreport(payload) {
  if (postFundsreportCancel) {
    postFundsreportCancel(CANCEL_TOKEN);
    postFundsreportCancel = null;
  }
  return axios.post(`/reports/dashboard/fundsreport`, payload, {
    cancelToken: new cancelToken(function executor(c) {
      postFundsreportCancel = c;
    }),
  });
}

let usertransactionreportCancel = null;
export function getUsertransactionreport(payload) {
  if (usertransactionreportCancel) {
    usertransactionreportCancel(CANCEL_TOKEN);
    usertransactionreportCancel = null;
  }
  return axios.post(`/reports/dashboard/usertransactionreport`, payload, {
    cancelToken: new cancelToken(function executor(c) {
      usertransactionreportCancel = c;
    }),
  });
}

let topsalereportCancel = null;
export function getTopsalereport(payload) {
  if (topsalereportCancel) {
    topsalereportCancel(CANCEL_TOKEN);
    topsalereportCancel = null;
  }
  return axios.post(
    `/reports/dashboard/topsalereport`,
    payload,
    {
      cancelToken: new cancelToken(function executor(c) {
        topsalereportCancel = c;
      }),
    }
  );
}

let salesreportCancel = null;
export function getSalesreportV2(payload) {
  if (salesreportCancel) {
    salesreportCancel(CANCEL_TOKEN);
    salesreportCancel = null;
  }
  return axios.post(
    `/reports/dashboard/salesreport`,
    payload,
    {
      cancelToken: new cancelToken(function executor(c) {
        salesreportCancel = c;
      }),
    }
  );
}

function updateDateRangeInURL(url, newStartDate, newEndDate) {
  const updatedURL = url
    .replace(/startDate=[^&]*/, `startDate=${encodeURIComponent(newStartDate)}`)
    .replace(/endDate=[^&]*/, `endDate=${encodeURIComponent(newEndDate)}`);
  return updatedURL;
}
/**
 * Get ledger section data
 * @augments available types: 'order', 'valueAdd', 'tabPayment'
 */
let ledgerCancel = null;
export function getLedger(
  type = "order",
  { limit = 10, page = 1 },
  customerId = null,
  { orderId = null, lastFour = null, deviceList = null } = {}
) {
  if (ledgerCancel) {
    ledgerCancel(CANCEL_TOKEN);
    ledgerCancel = null;
  }

  let url =
    `/reports/ledgers?type=${type}&limit=${limit}&page=${page}` +
    getDefaultParameters_V2(false);

  if (customerId) url += `&customerId=${customerId}`;
  if (orderId) url += `&orderId=${orderId}`;

  if (lastFour) url += `&lastFour=${lastFour}`;

  if (deviceList) url += `&paymentMethods=${deviceList}`;
  return axios.get(url, {
    cancelToken: new cancelToken(function executor(c) {
      ledgerCancel = c;
    }),
  });
}

export function getOrderHistory({ page = 1, limit = 10 }) {
  let url = `/orders?limit=${limit}&page=${page}`;
  return axios.get(url);
}

export function getGiftCards(id, { limit = 10, page = 1 }) {
  const {
    startDate,
    endDate,
    selectedTimeFilter,
  } = getStartDateAndEndDateInISOFormat();
  let url = `/utilities/accounts/${id}/giftcards?limit=${limit}&page=${page}&startDate=${startDate}&endDate=${endDate}&period=${selectedTimeFilter}`;
  return axios.get(url);
}

/**
 * Get customer users list
 */
let getCustomerCancel = null;
export function getCustomers({ limit = 10, page = 1 }, search, groupId) {
  if (getCustomerCancel) {
    getCustomerCancel(CANCEL_TOKEN);
    getCustomerCancel = null;
  }
  var url =
    `/reports/customers?limit=${limit}&page=${page}${
      search ? "&keywords=" + search : ""
    }${groupId ? "&group=" + groupId : ""}` + getDefaultParameters_V2(false);
  return axios.get(url, {
    cancelToken: new cancelToken(function executor(c) {
      getCustomerCancel = c;
    }),
  });
}

export function getCustomerReports(format = reportFormats.JSON, groupId) {
  let url =
    `/reports/download/customers?&format=${format}&isCheckinFlag=enabled` +
    getDefaultParameters_V2(false);
  if (groupId) {
    url += `&group=${groupId}`;
  }
  const { contentTypeHeader, responseTypeHeader } = getContentTypeHeader(
    format
  );
  return axios.get(url, {
    headers: {
      "Content-Type": contentTypeHeader,
    },
    responseType: responseTypeHeader,
  });
}
/*
* Function to get sales report data by selected query
* Detailed types for sales report json query composing
* Following code is not supported in js format but very informative
* I suggest to leave it here in case of further moving to TypeScript
* or updates in this method execution

interface ISalesReportingAggregateQuery {
  accountIds?: string[];
  locationIds?: string[];
  groupIds?: string[];
  kioskIds?: string[];
  timeMetric: date_trunc;
  dateRanges: {
    datebegin: string;
    dateend: string;
 }[];
  reports: ReportInterface[];
}

type ReportInterface = {
  NOTE: name is optional but it will help to track data in the response
  name?:string;
  reportTypeCategory:'ORDER'|'PRODUCT'|'INVENTORY';
  reportTypes: TReportType | Array<TReportType>;
  metrics: Array<metrics>;
  NOTE: default is true for group by saleperiod
  enableGroupbyDate?: boolean;
  orderBy?: Array<TReportType | metrics | 'saleperiod'>;
  searchfilters?: {
    basketSize?: {
      min?: number;
      max?: number;
      currency?: string;
    };
    paymentMethods?: Array<'creditCard' | 'cardPresent' | 'yokeCash' | 'tab' | 'cash'>;
 };
};

enum ReportTypesOrderBased {
  Locations = 'Locations',
  Customers = 'Customers',
  paymentMethods = 'paymentMethods',
  Kiosks = 'Kiosks',
  Terminals = 'Terminals', //same as kiosks
  Groups = 'Groups',
  SalesSummary = 'SalesSummary',
}

enum ReportTypesProductBased {
  Products = 'Products',
  productType = 'productType',
  Supplier = 'Supplier',
}

type date_trunc = 'NODATE'
  | 'second'
  | 'minute'
  | 'hour'
  | 'day'
  | 'week'
  | 'month'
  | 'quarter'
  | 'year';

type metrics = 'revenue'
  | 'basketSize'
  | 'basketValue'
  | 'tax'
  | 'avgtax'
  | 'grossProfit'
  | 'margin'
  | 'cogs'
  | 'firstSale'
  | 'lastSale'
  | 'customerCount'
  | 'discountTotal';

type TReportType =  'Locations'
  | 'Customers'
  | 'paymentMethods'
  | 'Kiosks'
  | 'Groups'
  | 'SalesSummary'
  | 'Products'
  | 'productType'
  | 'Supplier';
*/
export function getSalesReport(jsonQuery) {
  const encodedJson = encodeURIComponent(JSON.stringify(jsonQuery));

  return axios.get(
    `/reports/sales?aggjsonquery=${encodedJson}` +
      getDefaultParameters_V2(false)
  );
}

export function getSalesReport_2(payloadObj) {
  return axiosInstance.post("/reports/dashboard/salesMetrics", payloadObj);
}

export function getSalesReports(
  format = reportFormats.JSON,
  paymentMethods = null,
  orderType,
  custId
) {
  let url =
    `reports/download/ledgers?&format=${format}` +
    getDefaultParameters_V2(false);
  if (custId) {
    url += `&customerId=${custId}`;
  }
  if (orderType) {
    url += `&type=${orderType}`;
  }
  if (!_.isEmpty(paymentMethods)) {
    url += `&paymentMethods=${paymentMethods}`;
  }
  const { contentTypeHeader, responseTypeHeader } = getContentTypeHeader(
    format
  );
  return axios.get(url, {
    headers: {
      "Content-Type": contentTypeHeader,
    },
    responseType: responseTypeHeader,
  });
}

export function getCarts(
  { limit = 10, page = 1 },
  searchQuery,
  statusList,
  locationList
) {
  let url =
    `/carts?page=${page}&limit=${limit}&keywords=${searchQuery}&locationId=${locationList}&status=${statusList}` +
    getDefaultParameters_V2(false);
  return axios.get(url);
}

export function getCartImage(cartId) {
  let url = `/carts/${cartId}/image`;
  return axios.get(url);
}

export function getCartReports(format = reportFormats.JSON, statusList) {
  let url =
    `reports/download/sales/carts?&format=${format}&status=${statusList}` +
    getDefaultParameters_V2(false);
  const { contentTypeHeader, responseTypeHeader } = getContentTypeHeader(
    format
  );
  return axios.get(url, {
    headers: {
      "Content-Type": contentTypeHeader,
    },
    responseType: responseTypeHeader,
  });
}

export function getCashCollectorReports(format = reportFormats.CSV) {
  const params = new URLSearchParams(getDefaultParameters_V2(false));
  const location = params.get('location');
  const operator = params.get('operator');
  
  let url =
    `/billvalidators-download?format=${format}&location=${location}&operator=${operator}`;

  const { contentTypeHeader, responseTypeHeader } = getContentTypeHeader(
    format
  );
  return axios.get(url, {
    headers: {
      "Content-Type": contentTypeHeader,
    },
    responseType: responseTypeHeader,
  });
}
