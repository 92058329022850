import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  IconButton,
  Select,
  MenuItem,
  InputAdornment,
  FormControl,
  InputLabel,
  styled
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { SearchOutlined } from '@material-ui/icons';
import styles from './SearchInput.module.scss'


const SearchWithDropdown = ({ categories, placeholder, onSearch }) => {
  const [selectedCategory, setSelectedCategory] = useState(categories[0]?.id);
  const [searchTerm, setSearchTerm] = useState('');

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    onSearch(selectedCategory, searchTerm);
  };

  return (
      <Box display="flex" alignItems="center" width="100%" maxWidth="100%">
          
      <FormControl variant="outlined" size="small" className={styles.customFormControl} >
        <InputLabel>Category</InputLabel>
        <Select
          className={styles.selectInput}
          value={selectedCategory}
          onChange={handleCategoryChange}
          label="Category"
        >
          {categories.map((category) => (
            <MenuItem key={category.id} value={category?.id}>
              {category.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        variant="outlined"
        size="small"
        margin="none"
        inputProps={{
          style: {
            padding: "18px 5px"
          }
       }}
        className={ styles.customTextField}
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleInputChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" className={styles.customInputAdornment}>
              <IconButton onClick={handleSearch} className={styles.customIconButton}>
                <SearchOutlined />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

SearchWithDropdown.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
};

SearchWithDropdown.defaultProps = {
  placeholder: 'Search...',
};

export default SearchWithDropdown;
