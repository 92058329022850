import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Input,
} from "@material-ui/core";
import to from "await-to-js";
import { paymentMethods, RefundModalConstants } from "app/common/LedgerConst";

import { refund } from "../../components/Ledger/services/sales";

import styles from "./response.module.scss";
import { calculateRefundStatus, setPaymentTypes } from "app/common/utils/issueRefunds";
import { useSelector } from "react-redux";
import { ERefundStatusMessage, ETransactionStatus, NUMERIC_INPUT_REGEX, ORDER_TYPES } from "app/common/constant";
import _ from "lodash";
import { TransactionDetails } from "./TransactionDetails";

export default function RefundModal(props) {
  const {
    open,
    onClose,
    prettyId,
    orderId,
    transactions,
    setIsRefunded,
    openResponse,
    orderType,
    customersTransactionsData
  } = props;
  const [dropdown, setDropdown] = useState(false);
  const [transactionOneId, setTransactionOneId] = useState("");
  const [transactionOneAmount, setTransactionOneAmount] = useState(0);
  const [firstAmount, setFirstAmount] = useState(0);
  const [secondAmount, setSecondAmount] = useState(0);
  const [transactionOneMethod, setTransactionOneMethod] = useState("");
  const [transactionOneRefund, setTransactionOneRefund] = useState(false);
  const [firstTransactionRefundableAmount,setFirstTransactionRefundableAmount] = useState(0)
  const [secondTransactionRefundableAmount,setSecondTransactionRefundableAmount] = useState(0)
  const [firstTransactionRefundedAmount,setFirstTransactionRefundedAmount] = useState(0)
  const [secondTransactionRefundedAmount,setSecondTransactionRefundedAmount] = useState(0)
  const [transactionOneRefundValue,setTransactionOneRefundValue] = useState(0)
  const [transactionTwoRefundValue,setTransactionTwoRefundValue] = useState(0)
  const [firstTransactionRefundStatus,setFirstTransactionRefundStatus] = useState('')
  const [secondTransactionRefundStatus,setSecondTransactionRefundStatus] = useState('')
  const [transactionTwoId, setTransactionTwoId] = useState("");
  const [transactionTwoAmount, setTransactionTwoAmount] = useState(0);
  const [transactionTwoMethod, setTransactionTwoMethod] = useState("");
  const [transactionTwoRefund, setTransactionTwoRefund] = useState(false);
  const ledgerDataFromRedux = useSelector((state)=>state.ledgers.ledgersList)
  const valueAddDataFromRedux = useSelector((state)=>state.ledgers.valueAddsList)
  const isCustomersDataAvailable = !_.isUndefined(customersTransactionsData)
  const [refundMessage, setRefundMessage] = useState("");
  const ledgerData = orderType === ORDER_TYPES.ORDER ? ledgerDataFromRedux : valueAddDataFromRedux
  const refundData = orderId && calculateRefundStatus((isCustomersDataAvailable ? customersTransactionsData:ledgerData)?.find((ledger)=>ledger?.id === orderId))
  const isFirstTransactionFullyRefunded = !_.isEmpty(firstTransactionRefundStatus) && firstTransactionRefundStatus === ETransactionStatus.FULLY_REFUNDED
  const isSecondTransactionFullyRefunded = !_.isEmpty(secondTransactionRefundStatus) && secondTransactionRefundStatus === ETransactionStatus.FULLY_REFUNDED
  const [transactionsArray,setTransactionsArray] = useState([])
  const [refundSuccess,setRefundSuccess] = useState(false)
  const [isLoading,setIsLoading] = useState(null)


  useEffect(() => {
    var count = 0;
    transactions.forEach((elm) => {
      let paymentType = setPaymentTypes(elm)
      const {refundableAmount, refundedStatus , paymentMethod,refundedAmount} = refundData[elm.transactionId]
      if (count === 0) {
        setTransactionOneId(elm.transactionId); 
        setFirstTransactionRefundableAmount(refundableAmount.toFixed(2))
        setTransactionOneRefundValue(refundableAmount.toFixed(2))
        setFirstTransactionRefundedAmount(refundedAmount.toFixed(2))
        setFirstTransactionRefundStatus(refundedStatus)
        setTransactionOneMethod(paymentType);
        setTransactionOneAmount(elm.amount.toFixed(2));
        setFirstAmount(elm.amount.toFixed(2));
        setTransactionsArray([{
          transactionMethod: paymentType,
          totalAmount: elm.amount.toFixed(2),
          refundableAmount: refundableAmount.toFixed(2),
          refundedAmount: refundedAmount.toFixed(2),
        }])
      } else {
        setTransactionTwoId(elm.transactionId);
        setSecondTransactionRefundableAmount(refundableAmount.toFixed(2))
        setSecondTransactionRefundedAmount(refundedAmount.toFixed(2))
        setTransactionTwoRefundValue(refundableAmount.toFixed(2))
        setSecondTransactionRefundStatus(refundedStatus)
        setTransactionTwoMethod(paymentType);
        setTransactionTwoAmount(elm.amount.toFixed(2));
        setSecondAmount(elm.amount.toFixed(2));
        setTransactionsArray((prev)=>[...prev,{
          transactionMethod: paymentType,
          totalAmount: elm.amount.toFixed(2),
          refundableAmount: refundableAmount.toFixed(2),
          refundedAmount: refundedAmount.toFixed(2),
        }])
      }
      count++;
    });



    return () => {
      setTransactionOneId("");
      setTransactionOneMethod("");
      setTransactionOneAmount(0);
      setTransactionOneRefund(false);
      setFirstAmount(0);

      setTransactionTwoId("");
      setTransactionTwoMethod("");
      setTransactionTwoAmount(0);
      setTransactionTwoRefund(false);
      setSecondAmount(0);
      setTransactionsArray([])
    };
  }, [transactions,firstTransactionRefundStatus,customersTransactionsData]);

  function handleModalClose() {
    setDropdown(false);
    onClose(false);
    setRefundMessage("");
    setFirstTransactionRefundStatus("")
  }

  async function refundAction(transaction) {
    setIsLoading(transaction)
    let amount = 0;
    let transactionId = "";
    if (transaction === 1) {
      transactionId = transactionOneId;
      amount = parseFloat(transactionOneRefundValue);

    } else {
      transactionId = transactionTwoId;
      amount = parseFloat(transactionTwoRefundValue);
    }

    const [err, refundResponse] = await to(
      refund(orderId, amount, transactionId)
    );
    if (err) {
      console.error(err);
    setIsLoading(null)
    setRefundMessage("Refund Failed");
    setFirstTransactionRefundStatus("")
    } else if (refundResponse?.data?.refundResponse?.success === true) {
        setIsLoading(null)
        setRefundMessage(ERefundStatusMessage.REFUND_SUCCESSFUL);
        setIsRefunded(true);
        setRefundSuccess(true)
        handleModalClose();
        setFirstTransactionRefundStatus("")
        openResponse(true, false, ERefundStatusMessage.REFUND_SUCCESSFUL);
      } 
    return;
  }

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const isFirstTransactionRefundInput = e.target.name === RefundModalConstants.FIRST_TRANSACTION_INPUT_FIELD_NAME
    const isSecondTransactionRefundInput = e.target.name === RefundModalConstants.SECOND_TRANSACTION_INPUT_FIELD_NAME
    
    const showTemporaryErrorMessage = (message) => {
      setRefundMessage(message);
      setTimeout(() => {
        setRefundMessage('');
      }, 2000); 
    };
  
    if (isFirstTransactionRefundInput && parseFloat(inputValue) > parseFloat(firstTransactionRefundableAmount)) {
      showTemporaryErrorMessage(`Refund can't be more than ${transactionOneMethod} amount`);
      return;
    } else if (isSecondTransactionRefundInput && parseFloat(inputValue) > parseFloat(secondTransactionRefundableAmount)) {
      showTemporaryErrorMessage(`Refund can't be more than ${transactionTwoMethod} amount`);
      return;
    }
    // Allow only numbers and one decimal point
    if (NUMERIC_INPUT_REGEX.test(inputValue) && isFirstTransactionRefundInput) {
      setTransactionOneRefundValue(inputValue);
    }else if (NUMERIC_INPUT_REGEX.test(inputValue) && isSecondTransactionRefundInput){
      setTransactionTwoRefundValue(inputValue)
    }
  }

  return (
    <>
      <Dialog
        id={`refund_modal_dialog_wrapper`}
        open={open}
        onClose={handleModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogTitle id="simple-modal-title">{`Refund Order`}</DialogTitle>
        <DialogContent id="simple-modal-description" className={styles.wrapper}>
          {`Multiple transactions can be applied towards one order if a customer uses two payment methods on checkout. Please review the following transactions for order ${
            prettyId.split(":")[0]
          } carefully before refunding. Refunding cannot be reversed.`}
        </DialogContent>
        <DialogContent
          id="simple-modal-description"
          className={
            refundMessage === ERefundStatusMessage.REFUND_SUCCESSFUL
              ? styles.refundedWrapper
              : styles.refundFailWrapper
          }
        >
          {refundMessage}
        </DialogContent>
        <div
          id={`refund_modal_dialog_wrapper_all_button`}
          className={styles.allButton}
        >
          <div
            id={`refund_modal_dialog_wrapper_refund_buttons`}
            className={styles.refundButtons}
          >
           <DialogContent
              id="simple-modal-description"
              className={styles.wrapper}
            >
          <div className="container">
          {transactionsArray && <TransactionDetails transactions={transactionsArray} />}
          </div>
      
          <div className="container w-100 d-flex justify-content-around align-items-center p-1">
              <div className="container d-flex justify-content-center align-items-center mr-2">
                  <label 
                  htmlFor="transaction_one" 
                  className="me-3 mr-4" 
                  style={{ minWidth: "150px" }} 
                  >
                    {`${transactionOneMethod == paymentMethods.yokeCash ? paymentMethods.cantaloupeCash : transactionOneMethod}: `}</label>
                    <Input
                className={styles.input}
                id="transaction_one"
                value={isFirstTransactionFullyRefunded ? 0 :transactionOneRefundValue}
                error={!transactionOneAmount}
                readOnly = {isFirstTransactionFullyRefunded}
                name = {RefundModalConstants.FIRST_TRANSACTION_INPUT_FIELD_NAME}
                onClick={() => setRefundMessage("")}
                onChange={handleInputChange }
              />
               </div>

              <Button
                id={`refund_modal_dialog_refund_buttons`}
                className={styles.buttonWrapper}
                size="large"
                variant="contained"
                color="primary"
                onClick={() => refundAction(1)}
                disabled = {isFirstTransactionFullyRefunded || (isLoading == 1) || transactionOneRefundValue == 0}
                disableElevation
                style={{
                  color: (isLoading == 1) || isFirstTransactionFullyRefunded ? 'black' : 'white' 
                }}
              >
                {(isLoading == 1)? ETransactionStatus.PROCESSING:(isFirstTransactionFullyRefunded ? ETransactionStatus.FULLY_REFUNDED: 'Refund')}

              </Button>
          </div>
          </DialogContent>
          </div>
            
            {transactionTwoAmount !== 0 ? (
              <DialogContent
              id="simple-modal-description"
              className={styles.wrapper}
              >
            <div className="container w-100 d-flex justify-content-around align-items-center p-1">
              <div className="container d-flex justify-content-center align-items-center mr-2">
                <label
                 htmlFor="transactionOne" 
                 className="me-2 mr-4"
                 style={{ minWidth: "150px" }}
                 >
                  {`${transactionTwoMethod == paymentMethods.yokeCash ? paymentMethods.cantaloupeCash : transactionTwoMethod}: `}</label>

                <Input
                  className={styles.input}
                  id="transactionOne"
                  value={isSecondTransactionFullyRefunded ? 0 : transactionTwoRefundValue}
                  readOnly = {isSecondTransactionFullyRefunded}
                  error={!transactionTwoAmount}
                  name = {RefundModalConstants.SECOND_TRANSACTION_INPUT_FIELD_NAME}
                  onClick={() => setRefundMessage("")}
                  onChange={handleInputChange}
                />
                </div>
                <Button
                  id={`refund_modal_dialog_refund_buttons_1`}
                  className={styles.buttonWrapper}
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => refundAction(2)}
                  disabled = {isSecondTransactionFullyRefunded || (isLoading == 2)|| transactionTwoRefundValue == 0}
                  disableElevation
                  style={{
                    color: (isLoading == 2) || isSecondTransactionFullyRefunded ? 'black' : 'white' 
                  }}
                  >
                 {(isLoading == 2)? ETransactionStatus.PROCESSING:(isSecondTransactionFullyRefunded ? ETransactionStatus.FULLY_REFUNDED: 'Refund')}

                </Button>
                </div>
              </DialogContent>
            ) : null}

          <DialogContent
            id="simple-modal-actions"
            className={styles.cancelWrapper} 
          >
            <div
              className="d-flex justify-content-start p-3"
            >
            <Button
              color="primary"
              size="large"
              variant="contained"
              onClick={handleModalClose}
              id={`refund_modal_dialog_cancel_buttons`}
              >
              Cancel
            </Button>
              </div>
            
          </DialogContent>
        </div>
      </Dialog>
    </>
    
  );
}
