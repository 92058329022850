import _ from "lodash";
import { paymentMethods } from "../LedgerConst";
import { ETransactionStatus } from "../constant";

export  function setPaymentTypes (transaction) {
    let paymentType = ''
    switch (transaction.paymentType) {
      case 0:
        paymentType = paymentMethods.card;
        break;
      case 1:
        paymentType = paymentMethods.giftCard;
        break;
      case 2:
        paymentType = paymentMethods.loyalty;
        break;
      case 3:
        paymentType = paymentMethods.yokeCash;
        break;
      case 4:
        paymentType = paymentMethods.tab;
        break;
      default:
        paymentType = paymentMethods.tab;
    }
    return paymentType
  }

export function calculateRefundStatus (selectedOrder) {
    if (!_.isUndefined(selectedOrder) && selectedOrder?.status !== ETransactionStatus.ERROR_PAYING ) {
      const {refunds,transactions} = selectedOrder?.order
      const refundedAmounts = {};
  
      if (refunds) {
        refunds.forEach(refund => {
          if (refund.success) {
            if (refundedAmounts?.hasOwnProperty(refund?.transactionId)) {
              refundedAmounts[refund?.transactionId] += refund.amount;
            } else {
              refundedAmounts[refund?.transactionId] = refund.amount;
            }
          }
        });
      }
  
      const refundedStatus = {};
      transactions && transactions.forEach(transaction => {
        const refundedAmount = refundedAmounts[transaction?.transactionId];
        let refundedStatusObj = {
          transactionId: transaction?.transactionId,
          refundedAmount: refundedAmount || 0,
          refundableAmount: transaction?.amount - (refundedAmount || 0),
          paymentMethod: setPaymentTypes(transaction)
        };
      
        if (refundedAmount !== undefined) {
          refundedStatusObj.refundedStatus = refundedAmount >= transaction?.amount ? ETransactionStatus.FULLY_REFUNDED : ETransactionStatus.PARTIALLY_REFUNDED;
        } else {
          refundedStatusObj.refundedStatus = ETransactionStatus.NOT_REFUNDED;
        }
      
        refundedStatus[transaction?.transactionId] = refundedStatusObj;
      });
      return refundedStatus
    }
  }
  

 export function refundStatusForUI (refundedData,transactionStatus) {
    if (transactionStatus !== ETransactionStatus.ERROR_PAYING && transactionStatus !== ETransactionStatus.PROCESSING) {
        const transactionsArray = Object.values(refundedData);
        let orderStatus = transactionStatus;
        const hasPartiallyRefundedTransactions = transactionsArray.some(
          transaction => transaction.refundedStatus === ETransactionStatus.PARTIALLY_REFUNDED ||
          transaction.refundedStatus === ETransactionStatus.FULLY_REFUNDED
        );
        
        const allFullyRefunded = transactionsArray.every(
          transaction => transaction.refundedStatus === ETransactionStatus.FULLY_REFUNDED
        );
        
        const isPartiallyRefunded = hasPartiallyRefundedTransactions && !allFullyRefunded;

        if (isPartiallyRefunded) {
          orderStatus = ETransactionStatus.PARTIALLY_REFUNDED;
        } else if (allFullyRefunded) {
          orderStatus = ETransactionStatus.FULLY_REFUNDED;
        }        
        return orderStatus
    }else return transactionStatus
    }