import { HTTP_RESPONSES } from "app/common/constant";

export function removeCSSClass(ele, cls) {
  const reg = new RegExp(`(\\s|^)${cls}(\\s|$)`);
  ele.className = ele.className.replace(reg, ' ');
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

const apiExceptions = [
  '/reports/dashboard/homepage'
];

export function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken)
      /**
       * config.headers.Authorization = `Bearer ${authToken}`;
       * Allow to add Access-Control-Allow-Credentials for the requests
       * that expect cookies to be sent
       */
      if (!apiExceptions.some(exception => config.url.includes(exception)))
        config.withCredentials = true;

      return config;
    },
    err => Promise.reject(err)
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const status = error?.response?.status 

      // Handle unauthorized error (401)
      const currentUrl =  window.location.href ;
      if (status ===  HTTP_RESPONSES?.AUTHENTICATION_FAILED?.code && !currentUrl?.includes("/auth/login")) {
        // Redirect user to login page
        localStorage.clear();
        window.location.href = "/auth/login";
      }

      return Promise.reject(error);
    }
  );
}

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
export function removeStorage(key) {
  try {
    localStorage.setItem(key, '');
    localStorage.setItem(`${key}_expiresIn`, '');
  } catch (e) {  
    console.error(
      `removeStorage: Error removing key [${
        key
      }] from localStorage: ${
        JSON.stringify(e)}`
    );
    return false;
  }
  return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
        key <string> : localStorage key
    returns:
        <string> : value of localStorage key
        null : in case of expired key or failure
 */
export function getStorage(key) {
  const now = Date.now(); // epoch time, lets deal only with integer
  // set expiration for storage
  let expiresIn = localStorage.getItem(`${key}_expiresIn`);
  if (expiresIn === undefined || expiresIn === null)
    expiresIn = 0;

  expiresIn = Math.abs(expiresIn);
  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  }
  try {
    const value = localStorage.getItem(key);
    return value;
  } catch (e) {   
    console.eror(
      `getStorage: Error reading key [${
        key
      }] from localStorage: ${
        JSON.stringify(e)}`
    );
    return null;
  }
}
/*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of seconds from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
export function setStorage(key, value, expires) {
  if (expires === undefined || expires === null)
    expires = 24 * 60 * 60; // default: seconds for 1 day

  const now = Date.now(); // millisecs since epoch time, lets deal only with integer
  const schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(`${key}_expiresIn`, schedule);
  } catch (e) {
    console.error(
      `setStorage: Error setting key [${
        key
      }] in localStorage: ${
        JSON.stringify(e)}`
    );
    return false;
  }
  return true;
}
