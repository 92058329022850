import React from 'react';
import clsx from 'clsx';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Typography,
  Paper,
} from '@material-ui/core';
import styles from '../../../../_metronic/_assets/sass/pages/sales-report.module.scss';
import TableRowsLoader from "app/components/Helpers/Loader/TableLoader";
import { TABLE_ROWS_LOADER_COLSNUM, TABLE_ROWS_LOADER_ROWSNUM } from 'app/common/constant';

export default function ReportTable({ data, selectedMeasureFilter, loading }) {
  /**
   * Render table cell
   * @param cell
   * @param row
   */
  function renderTableCell(cell, row) {
    const {
      id,
      size,
      currency: parentCurrency,
      hasCurrency,
      className,
      secondaryClassName,
      value: { name, integer, decimal },
      rowSpan = 1,
      colSpan = 1,
      align = 'right',
      currency: childCurrency,
      currencyPosition = 'before',
      hasBorder = true
    } = cell;

    return (
      <TableCell
        key={id}
        colSpan={colSpan}
        rowSpan={rowSpan}
        align={align}
        size={size}
        className={clsx({
          [styles.withoutBorder]: !hasBorder,
          [styles.verticalHeader]: rowSpan > 1,
          [styles[className]]: className,
          [styles[secondaryClassName]]: secondaryClassName
        })}
      >
        <Typography variant="body1" color="textPrimary">
          {hasCurrency && currencyPosition === "before"
            ? childCurrency || parentCurrency
            : ""}
          {name ||
            (selectedMeasureFilter === "Basket Size"
              ? typeof integer === "number"
                ? integer?.toFixed(2)
                : integer || "0" // Handle non-numeric or undefined values
              : integer)}
          {decimal ? `.${decimal}` : null}
          {hasCurrency && currencyPosition === "after"
            ? childCurrency || parentCurrency
            : ""}
        </Typography>
      </TableCell>
    );
  }

  return (
    <Paper className={styles.root}>
    <TableContainer className={styles.tableContainer}>
      <Table stickyHeader aria-label="sticky table">
        {!loading.initialFetch ? (
         <>
            <TableHead>
              <TableRow>
                {data && data.firstHeadersRow.map(({ id, title, colSpan, className, secondaryClassName }) => (
                  <TableCell
                    key={id}
                    colSpan={colSpan}
                    align="center"
                    size="small"
                    className={clsx({
                      [styles[className]]: className,
                      [styles[secondaryClassName]]: secondaryClassName,
                    })}        
                  >
                 {title}
                </TableCell>
                      )
                    )}
                </TableRow>
                <TableRow>
                  {data &&
                    data.secondHeadersRow.map(
                      ({
                        id,
                        colSpan = 1,
                        title,
                        align = "right",
                        className,
                        secondaryClassName,
                      }) => (
                        <TableCell
                          key={id}
                          colSpan={colSpan}
                          align={align}
                          className={clsx({
                            [styles[className]]: className,
                            [styles[secondaryClassName]]: secondaryClassName,
                          })}
                        >
                          <Typography variant="body2" color="textSecondary">
                            {title}
                          </Typography>
                        </TableCell>
                      )
                    )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.body.map((row, index) => (
                  <TableRow
                    key={row.id}
                    hover={index === data?.body?.length - 1 ? false : true}
                    className={clsx(styles[row.className], {
                      [styles.stickyRow]: index === data?.body?.length - 1,
                    })}
                  >
                    {row.rowDetails.map((cell) => renderTableCell(cell, row))}
                  </TableRow>
                ))}
              </TableBody>
            </>
          ) : (
              <TableRowsLoader rowsNum={TABLE_ROWS_LOADER_ROWSNUM} colsNum={TABLE_ROWS_LOADER_COLSNUM} />
          )}
        </Table>
      </TableContainer>
    </Paper>
  );
}
