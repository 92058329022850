import React, { useEffect, useState } from "react";
import to from "await-to-js";
import clsx from "clsx";
import { Card, CardContent, Typography, Button } from "@material-ui/core";

import { Close } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";

import styles from "./giftCardsDetails.module.scss";
import styles2 from "_metronic/_assets/sass/loading-text.module.scss";

import ResponseModal from "app/components/Helpers/ResponseModal";
import { accessPermission } from "app/common/helperFunction";
import useCustomerHeader from "app/hooks/useCustomHeader";
import GiftCardsBasicDetails from "../components/GiftCardsBasicDetails";
import IssueDetails from "../components/IssueDetails";
import { createGiftCard, updateGiftCard } from "./giftcards";
import { GIFT_CARD_ISSUE_CONSTANTS,TYPE_ONCE} from "app/common/constant";
import { getUtcDateFormat } from "app/common/utils/generic";

export default function GiftCardsDetails(props) {
  const { id, isNew } = useCustomerHeader({
    property: "name",
    isCreationAllowed: true,
    createNew: "Gift Cards",
    id,
    isNew,
  });
  const { push } = useHistory();
  const [isLoaded, setIsLoaded] = useState(true);
  const [amount, setAmount] = useState(0);
  const [type, setType] = useState(TYPE_ONCE);
  const [expirationDate, setExpirationDate] = useState(null);
  const [issusedTo, setIssuedTo] = useState(0);
  const [issuedToType, setIssuedToType] = useState(GIFT_CARD_ISSUE_CONSTANTS.ISSUE_TYPE_SINGLE_USER);
  const [recurringMetric, setRecurringMetric] = useState(0);

  const [responseMessage, setResponseMessage] = useState("");
  const [responseMessageSuccess, setResponseMessageSuccess] = useState(true);
  const [responseMessageOpen, setResponseMessageOpen] = useState(false);
  const [isAmountValid, setIsAmountValid] = useState(true);

  /**
   * Fetch on component init
   */
  useEffect(() => {
    if (!accessPermission("PromosAndCouponsAll")) {
      push({ pathname: "/dashboard" });
    }
  }, []);

  function handlePopupClose() {
    setResponseMessageOpen(false);
  }

  function changeAmount(amount) {
    if (!isNaN(amount)) {
    amount = Math.abs(amount);
    setAmount(amount);
    setIsAmountValid(true)
    }
    else {
      setAmount(0);
      setIsAmountValid(false);
    }
  }

  function changeType(type) {
    setType(type);
    setRecurringMetric(0);
    setExpirationDate(null);
  }

  function changeExpirationDate(date) {
    setExpirationDate(date);
  }

  function changeIssedTo(to) {
    setIssuedTo(to);
  }

  function changeIssuedToType(type) {
    setIssuedToType(type);
  }

  function changeRecurringMetric(metric) {
    setRecurringMetric(metric);
    var today = new Date();
    var nextDate = new Date(today);
    if (metric ===  GIFT_CARD_ISSUE_CONSTANTS.ISSUEOPTION.DAILY) {
      nextDate.setDate(today.getDate() + 1);
      nextDate.toLocaleDateString();
    } else if (metric === GIFT_CARD_ISSUE_CONSTANTS.ISSUEOPTION.WEEKLY) {
      nextDate.setDate(today.getDate() + 7);
      nextDate.toLocaleDateString();
    } else if (metric === GIFT_CARD_ISSUE_CONSTANTS.ISSUEOPTION.MONTHLY) {
      nextDate.setMonth(today.getMonth() + 1);
      nextDate.toLocaleDateString();
    } else if (metric === GIFT_CARD_ISSUE_CONSTANTS.ISSUEOPTION.YEARLY) {
      nextDate.setFullYear(today.getFullYear() + 1);
      nextDate.toLocaleDateString();
    }

    setExpirationDate(nextDate);
  }

  function changeSelectedUser(amount) {
    console.log(`user ${amount}`);
  }

  function changedSelectedGroup(amount) {
    console.log(`group ${amount}`);
  }

  async function giftcardCreate() {
    /**
   * for validating the Amount field
   */
    if (!isAmountValid) {
      setResponseMessage("Invalid amount input. Please enter a valid number.");
      setResponseMessageSuccess(false);
      setResponseMessageOpen(true);
      return;
    }

    let payload = {
      amount: !isNaN(amount) ? parseFloat(amount).toFixed(2) : "0",
      recurring: type === TYPE_ONCE ? false : true,
      recurringIssueMetric:
        type === TYPE_ONCE
          ? recurringMetric.toString()
          : typeof recurringMetric === GIFT_CARD_ISSUE_CONSTANTS. RECURRING_METRIC_TYPE_STRING
          ? recurringMetric
          : GIFT_CARD_ISSUE_CONSTANTS.ISSUEOPTION.DAILY,
    };

    if (issuedToType === GIFT_CARD_ISSUE_CONSTANTS.ISSUE_TYPE_SINGLE_USER) {
      payload = { ...payload, userId: issusedTo };
    } else {
      payload = { ...payload, userGroupId: issusedTo };
    }
    const expiredDate  = await getUtcDateFormat(expirationDate);
    
    if (expirationDate) {
      payload = { ...payload,  expirationDate: expiredDate };
    }

    if (isNew) {
      const [err, res] = await to(createGiftCard(payload));
      if (err) {
        console.error(err);
        Sentry.captureException(err);
        setResponseMessage(
          `Error issuing gift card: ${
            err.response.data ? err.response.data.message : err
          }`
        );
        setResponseMessageSuccess(false);
        setResponseMessageOpen(true);
      } else {
        push({ pathname: "/giftCards" });
      }
    } else {
      const [err, res] = await to(updateGiftCard(payload, id));
      if (err) {
        console.error(err);
        Sentry.captureException(err);
        setResponseMessage(`Error updating gift card: ${err}`);
        setResponseMessageSuccess(false);
        setResponseMessageOpen(true);
      } else {
        push({ pathname: "/giftcards" });
      }
    }
  }

  /**
   * Send entered/updated data to store on server
   */
  async function handleUpdatePromotion() {}

  return (
    <Card>
      {!isLoaded && (
        <div className={styles2.loadingContainer}>
          <p className={styles2.loadingText}>Loading...</p>
        </div>
      )}
      <CardContent
        className={clsx("d-flex", "justify-content-end", styles.headerWrapper)}
      >
        <Button
          className={styles.cancelBtn}
          startIcon={<Close />}
          type="button"
          size="medium"
          variant="outlined"
          color="secondary"
          disableElevation
          onClick={() => push({ pathname: "/giftCards" })}
        >
          Cancel
        </Button>

        <Button
          type="button"
          size="medium"
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => giftcardCreate()}
        >
          Issue
        </Button>
      </CardContent>

      <CardContent>
        <Typography variant="h5">Gift Card Details</Typography>
        <GiftCardsBasicDetails
          selectedGiftCardId={id}
          changeAmount={(value, property) => changeAmount(value)}
          changeType={(value, property) => changeType(value)}
          changeExpirationDate={(value, property) =>
            changeExpirationDate(value)
          }
          changeRecurringMetric={(value, property) =>
            changeRecurringMetric(value)
          }
        />
      </CardContent>

      <CardContent>
        <Typography variant="h5">Issue To</Typography>
        <IssueDetails
          selectedGiftCardId={id}
          changedSelectedGroup={(value, property) =>
            changedSelectedGroup(value)
          }
          changeSelectedUser={(value, property) => changeSelectedUser(value)}
          changeToType={(value, property) => changeIssuedToType(value)}
          changeIssuedTo={(value, property) => changeIssedTo(value)}
        />
      </CardContent>

      <ResponseModal
        isSuccess={responseMessageSuccess}
        isServerError={true}
        message={responseMessage}
        open={responseMessageOpen}
        onClose={handlePopupClose}
      />
    </Card>
  );
}
