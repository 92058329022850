import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import to from "await-to-js";
import clsx from "clsx";
import { Paper, Tab, Tabs } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { jsPDF } from "jspdf";
import csvDownload from "json-to-csv-export";
import "jspdf-autotable";
import QRCode from "qrcode.react";
import { format } from 'date-fns';
import mergeImages from 'merge-images';
import * as Sentry from "@sentry/react";

import ResponseModal from "app/components/Helpers/ResponseModal";
import { accessPermission } from "app/common/helperFunction";
import LoadingSpinner from "app/partials/content/LoadingSpinner";
import { useCustomResponse } from "app/hooks/useCustomResponse";
import { CANCEL_TOKEN, SEARCH_DELAY_FOR_DEBOUNCE } from "app/common/constant";
import { setupLocationActions } from "app/store/actions/setupLocations.action";

import Search from "../../../partials/content/Customers/Search";
import ExportAndCustomFilters from "../../../partials/content/ExportAndCustomFilters";
import SetupLocationsTable from "./components/SetupLocationsTable/SetupLocationsTable";
import { getLocations, sendKioskCommand } from "../../../services/locations";
import poster from './11x17.png';
import styles from "./setup-locations.module.scss";
import { PAGINATION_INITIAL_STATE } from "app/common/utils/generic";
import useDebounce from "app/hooks/useDebounce";

let availableGroups = [];
let searchTimeOut = null;

export default function SetupLocations(props) {
  const {
    filter: { locations: filterLocations },
  } = props;
  const { push } = useHistory();
  const dispatch = useDispatch()
  const [searchQuery, setSearchQuery] = useState("");
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [resetSelected, setResetSelected] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [locationId, setLocationId] = useState("");
  const [showQrCode, setShowQrCode] = useState(false);
  const [responseValue, setResponseValue] = useCustomResponse()
  const { REACT_APP_DOWNLOAD_QRCODE_LOCATION_URL } = process.env;
  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION_INITIAL_STATE.limit);
  const [page, setPage] = useState(0);
  const[pagination,setPagination]=useState({});
  const Searchlocationquery = useDebounce(searchQuery, SEARCH_DELAY_FOR_DEBOUNCE); 

  /**
   * Reset selected locations on moving to group
   */
  useEffect(() => {
    if (!resetSelected) return;

    setResetSelected(false);
  }, [resetSelected]);

  /**
   * Handle data loading on component start
   */

  useEffect(() => {
    if (!accessPermission("SetupAll")) {
      push({ pathname: "/dashboard" });
    }
  },[]);

  useEffect(() => {  
    if (filterLocations && filterLocations.length) {
      getLocationsList();
    }
  }, [Searchlocationquery,page,rowsPerPage]);


  /**
   * Get list of locations
   */
  async function getLocationsList() {
    setIsLoaded(false);
    const [err, res] = await to(getLocations(true, true, searchQuery, null,null,page+1,rowsPerPage ));

    setIsLoaded(true);
    if (err) {
      if(err.message === CANCEL_TOKEN)return;
      Sentry.captureException(err);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`Error while getting location list. ${err}`});    
      return console.error("Error on setup locations fetch: ", err);
    }
    const locationsList = res.data.Locations.map(location => {
      location.isExpanded = true;
      return location;
    })
    dispatch(setupLocationActions.storeSetupLocations(locationsList))
    setLocations(locationsList);
    setPagination(res?.data?.pagination)
  }

  function downloadLocationQRCode(downloadLocationId, name) {
    setLocationId( `${REACT_APP_DOWNLOAD_QRCODE_LOCATION_URL}/checkin/${downloadLocationId}`);
    setShowQrCode(true);

    setTimeout(() => {
      getQRCode(downloadLocationId, name);
    }, 0);
  }

  function getQRCode(downloadLocationId, name) {
    const qrCodeURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    mergeImages([
      { src:poster, x: 0, y: 0 },
      { src: qrCodeURL, x: 2702, y: 3262 },
    ])
      .then(b64 => {

  let aEl = document.createElement("a");
    aEl.href = b64;
    aEl.download = `${name}_QR_Code.png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
      });;
    setShowQrCode(false);
  }

  async function handleAction(LocationId, id, action) {
    const [err, res] = await to(sendKioskCommand(LocationId, id, action));

    if (err) {
      Sentry.captureException(err);
      setResponseValue({...responseValue, isMessageOpen: true, isSuccess:false, message:`Error while trying to ${action} kisok. ${err}`});
      return console.error(`Add-on status - failed to ${action}: , ${err}`);
    }

    setResponseValue({...responseValue, isMessageOpen: true, isSuccess:true, message:"Action sent! This might take a few minutes"});
  }

  /**
   * Expand row
   * @param row
   */
  function onExpandRow(row) {
    const updatedLocations = locations.map((location) => {
      if (location.id === row.id)
        return {
          ...location,
          isExpanded: !location.isExpanded,
        };

      return location;
    });

    setLocations(updatedLocations);
  }

  async function handleExport(type) {

    if(locations.length === 0)
      return;

    const rows = locations;

    if (type === "pdf") {
      const exportRows = rows.map((ele) => [
        ele.name ? ele.name : "",
        ele.kiosks && ele.kiosks.length ? "-" : ele.connected ? "Yes" : "No",
        ele.kiosks && ele.kiosks.length ? "-" : ele.power ? "Yes" : "No",
      ]);

      const unit = "pt";
      const size = "A4";
      const orientation = "portrait";
      const marginLeft = 30;
      const doc = new jsPDF(orientation, unit, size);
      doc.setFontSize(15);

      const title = "Setup locations";
      const headers = [["Location Name", "Connected", "Power"]];

      let content = {
        startY: 50,
        head: headers,
        body: exportRows,
        styles: { overflow: "linebreak", fontSize: 8 },
      };

      doc.text(title, marginLeft, 30);
      doc.autoTable(content);
      doc.save(`Setup locations - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);
    } else if (type === "csv") {
      const exportRows = [];
      rows.forEach((ele) => {
        let obj = {
          "Location Name": ele.name ? ele.name : "",
        };
        exportRows.push(obj);
      });

      csvDownload(exportRows, `Setup locations - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
 
  const handleChangeRowsPerPage = (event) => {
    const newSize = parseInt(event.target.value);
    const newPage = parseInt(((page * rowsPerPage) + 1) / newSize);
    setRowsPerPage(newSize);
    setPage(newPage);
  };
 
  function handleSearchQueryOnChange(value) {
    setSearchQuery(value)
    setPage(PAGINATION_INITIAL_STATE.page - 1);
  }

  return (
    <>
      <ExportAndCustomFilters onExport={handleExport} />

      <>
        <Paper id={`setup_locations_paper_wrapper`} className={clsx(styles.wrapper, "d-flex justify-content-between")}>
          <Tabs
            id={`setup_locations_tabs`}
            value="locations"
            onChange={() => push({ pathname: "/setup/groups" })}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab id={`setup_locations_location_tab`} label="Locations" value="locations" />
            <Tab id={`setup_locations_groups_tab`} label="Groups" value="groups" />
          </Tabs>
        </Paper>

        <div id={`setup_locations_search_wrapper`} className={styles.wrapper}>
          <Search
            id="setupLocation"
            label="Search by location name"
            placeholder="Search"
            query={searchQuery}
            onChange={(e) => {handleSearchQueryOnChange(e.target.value)}}
          />
        </div>
        <div id={`setup_locations_location_tab_inner_wrapper`} className={styles.wrapper}></div>

        {!isLoaded ? (
          <LoadingSpinner/>
        ) : (
          <div id={`setup_locations_location_tab_inner_wrapper`} className={styles.tableWrapper}>
            <SetupLocationsTable
              preselected={[]}
              rows={locations}
              pagination={pagination}
              onExpandRow={onExpandRow}
              onEditRowClick={({ id }) =>
                push({ pathname: `/setup/locations/${id}` })
              }
              resetSelected={resetSelected}
              onSelectLocations={(e) => setSelectedLocations(e)}
              downloadLocationQRCode={downloadLocationQRCode}
              handleAction={handleAction}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={rowsPerPage}
              page={page}
            />
          </div>
        )}

       
        <ResponseModal
          isSuccess={responseValue?.isSuccess}
          message={responseValue?.message}
          open={responseValue?.isMessageOpen}
          onClose={(value) => setResponseValue({...responseValue, isMessageOpen:value})}
        />
        {showQrCode && (
          <div id={`setup_locations_location_qr_code`} style={{ display: "none" }}>
            <QRCode id="qrCodeEl" size={200} value={locationId} />
          </div>
        )}
      </>
    </>
  );
}
