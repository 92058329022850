/**
 * Readable section headings
 */
export const headingDictionary = {
  SalesAndReportingAll: 'Sales & Reporting',
  LedgerAll: 'Ledger',
  LoyaltyAll: 'Loyalty',
  PromosAndCouponsAll: 'Promos & Coupons',
  AddonsAll: 'Add-ons',
  OperatorsAll: 'Operators',
  SetupAll: 'Setup',
  CustomersAll: 'Customers'
};
export const reverseHeadingDictionary = {
  'Sales & Reporting': 'SalesAndReportingAll',
  Ledger: 'LedgerAll',
  Loyalty: 'LoyaltyAll',
  'Promos & Coupons': 'PromosAndCouponsAll',
  'Add-ons': 'AddonsAll',
  Operators: 'OperatorsAll',
  Setup: 'SetupAll',
  Customers: 'CustomersAll'
};

/**
 * Default view mapping for roles
 */
export const defaultView = [
  {
    id: 'SalesAndReportingAll',
    numericId:1,
    values: [
      {
        id: 'SalesAndReportingAll',
        numericId:1,
        variant: 'h4',
        name: 'Sales & Reporting',
        access: null
      },
      {
        id: 'SalesReports',
        numericId:2,
        variant: 'h6',
        name: 'Sales Reports',
        access: null
      },
      {
        id: 'InventoryReports',
        numericId:3,
        variant: 'h6',
        name: 'Inventory Reports',
        access: null
      },
      {
        id: 'CustomerReports',
        numericId:4,
        variant: 'h6',
        name: 'Customer Reports',
        access: null
      },
      {
        id: 'TaxReports',
        numericId:5,
        variant: 'h6',
        name: 'Tax Reports',
        access: null
      }
    ]
  },
  {
    id: 'CustomersAll',
    numericId:6,
    values: [
      {
        id: 'CustomersAll',
        numericId:6,
        variant: 'h4',
        name: 'Customers',
        access: null
      }
    ]
  },
  {
    id: 'LedgerAll',
    numericId:7,
    values: [
      {
        id: 'LedgerAll',
        numericId:7,
        variant: 'h4',
        name: 'Ledger',
        access: null
      }
    ]
  },
  {
    id: 'LoyaltyAll',
    numericId:8,
    values: [
      {
        id: 'LoyaltyAll',
        numericId:8,
        variant: 'h4',
        name: 'Loyalty',
        access: null
      }
    ]
  },
  {
    id: 'PromosAndCouponsAll',
    numericId:9,
    values: [
      {
        id: 'PromosAndCouponsAll',
        numericId:9,
        variant: 'h4',
        name: 'Promotions & Coupons',
        access: null
      }
    ]
  },
  {
    id: 'AddonsAll',
    numericId:10,
    values: [
      {
        id: 'AddonsAll',
        numericId:10,
        variant: 'h4',
        name: 'Add-ons',
        access: null
      }
    ]
  },
  {
    id: 'OperatorsAll',
    numericId:11,
    values: [
      {
        id: 'OperatorsAll',
        numericId:11,
        variant: 'h4',
        name: 'Operators',
        access: null
      }
    ]
  },
  {
    id: 'SetupAll',
    numericId:12,
    values: [
      {
        id: 'SetupAll',
        numericId:12,
        variant: 'h4',
        name: 'Setup',
        access: null
      },
      // {
      //   id: 'SetupTax',
      //   variant: 'h6',
      //   name: 'Tax',
      //   access: null
      // },
      {
        id: 'SetupUsers',
        numericId:14,
        variant: 'h6',
        name: 'Users',
        access: null
      },
      {
        id: 'SetupOperators',
        numericId:15,
        variant: 'h6',
        name: 'Operators',
        access: null
      },
      {
        id: 'SetupLocations',
        numericId:16,
        variant: 'h6',
        name: 'Locations',
        access: null
      }
    ]
  }
];

/**
 * Empty role structure
 */
export const defaultRequest = [
  {
    role: {
      name: '',
      description: '',
      GroupId: '',
      roleperms: [
        {
          access: null,
          permission: {
            name: 'SalesAndReportingAll'
          }
        },
        {
          access: null,
          permission: {
            name: 'CustomersAll'
          }
        },
        {
          access: null,
          permission: {
            name: 'LedgerAll'
          }
        },
        {
          access: null,
          permission: {
            name: 'LoyaltyAll'
          }
        },
        {
          access: null,
          permission: {
            name: 'PromosAndCouponsAll'
          }
        },
        {
          access: null,
          permission: {
            name: 'AddonsAll'
          }
        },
        {
          access: null,
          permission: {
            name: 'OperatorsAll'
          }
        },
        {
          access: null,
          permission: {
            name: 'SetupAll'
          }
        }
      ]
    }
  }
];

/**
 * Map response to the view
 */
export function mapRoleToView(roleperms) {
  if (!roleperms)
    return defaultView;

  const mappedRoles = JSON.parse(JSON.stringify(defaultView));


  for (let i = 0, n = roleperms.length; i < n; ++i) {
    const { permission: { name }, access } = roleperms[i];

    for (let j = 0, m = mappedRoles.length; j < m; ++j)
      mappedRoles[j].values.forEach(s => {
        if (s.id === name)
          s.access = access;
      });
  }

  return mappedRoles;
}

/**
 * Return data prepared to be send
 */
export function mapViewToRequest({ name, description, GroupId }, permissions) {
  const allPermissions = permissions.reduce((acc, cur) => ([...acc, ...cur.values]), []);

  return [
    {
      role: {
        name,
        description,
        GroupId,
        roleperms: allPermissions.map(({ id: numericId, access }) => ({
          access,
          permission: {
            name
          }
        }))
      }
    }
  ];
}


/**
 * Return data prepared to be send
 */
 export function mapViewToRequest2({ name, description, GroupId }, permissions) {
  const allPermissions = permissions.reduce((acc, cur) => ([...acc, ...cur.values]), []);
  return [
    {
      role: {
        name,
        description,
        GroupId,
        permissions: allPermissions.map(({ numericId, access }) => ([numericId, access]
         ))
      }
    }
  ];
}