import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  makeStyles
} from '@material-ui/core';
import { EditSharp } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import { getComparator, getDefaultStyles, stableSort } from '../../../../services/table';

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'startDate', numeric: false, disablePadding: false, label: 'Start date' },
  { id: 'endDate', numeric: false, disablePadding: false, label: 'End date' },
  { id: 'availableOn', numeric: false, disablePadding: false, label: 'Available on' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' }
];


function capitalize(s)
{
  if (s)
    return s[0].toUpperCase() + s.slice(1);
}

function EnhancedTableHead() {
  return (
    <TableHead id={`promotiond_table_header`}>
      <TableRow id={`promotiond_table_header_row`}>
        {headCells.map(headCell => (
          <TableCell
            id={`promotiond_table_header_cell_${headCell.id}`}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(getDefaultStyles);

export default function PromotionsTable(props) {
  const { push } = useHistory();
  const classes = useStyles();
  const [order] = useState('asc');
  const [orderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {
    rows
  } = props;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    const newSize = parseInt(event.target.value, 10);
    const newP = parseInt((page * rowsPerPage + 1) / newSize);
    setRowsPerPage(newSize);
    setPage(newP);
  };

  return (
    <div id={`promotiond_table_root_wrapper`} className={classes.root}>
      <Paper id={`promotiond_table_root_wrapper`} className={classes.paper}>
        <TableContainer id={`promotiond_table_container`}>
          <Table
            id={`promotiond_table_inner_container`}
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead/>
            <TableBody id={`promotiond_table_body_container`}>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  const { id, name, startDate, endDate, status, availableOn } = row;

                  return (
                    <TableRow
                      hover
                      key={id}
                      id={`promotiond_table_row_${id}`}
                    >
                      <TableCell id={`promotiond_table_name_cell_${id}`} align="left" padding="normal" scope="row">{name}</TableCell>
                      <TableCell id={`promotiond_table_start_cell_${id}`} align="left" padding="normal">{format(new Date(startDate), 'MM/dd/yyyy - hh:mm aa')}</TableCell>
                      <TableCell id={`promotiond_table_end_cell_${id}`} align="left" padding="normal">{format(new Date(endDate), 'MM/dd/yyyy - hh:mm aa')}</TableCell>
                      <TableCell id={`promotiond_table_available_cell_${id}`} align="left" padding="normal">{availableOn}</TableCell>
                      <TableCell id={`promotiond_table_end_date_cell_${id}`} align="left" padding="normal">{new Date(endDate) > new Date() ? capitalize(status == 'inactive' ? 'canceled' : status) : 'Expired'}</TableCell>
                      <TableCell id={`promotiond_table_icon_button_cell_${id}`} align="left" padding="normal">
                        <IconButton onClick={() => push({ pathname: `/promotions/${id}` })}>
                          <EditSharp/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          id={`promotiond_table_pagination`}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
