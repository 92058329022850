import { FONT_STYLES } from 'app/common/constant';
import { paymentMethods } from 'app/common/LedgerConst';
import React from 'react';

export const TransactionDetails = ({ transactions }) => {
  return (
    <table className="table table-bordered table-striped">
      <thead>
        <tr className='bg-secondary text-dark'>
          <th className= {FONT_STYLES.BOLD} >Payment Method</th>
          <th className= {FONT_STYLES.BOLD} >Total</th>
          <th className= {FONT_STYLES.BOLD} >Refundable</th>
          { <th className= {FONT_STYLES.BOLD} >Refunded</th>}
        </tr>
      </thead>
      <tbody>
        {transactions.map((transaction, index) => (
          <tr key={index}>
            <td className={FONT_STYLES.BOLD}>{transaction?.transactionMethod == paymentMethods.yokeCash  ? paymentMethods.cantaloupeCash : transaction?.transactionMethod}</td>
            <td className={`text-primary ${FONT_STYLES.BOLD}`}>{transaction?.transactionMethod === paymentMethods.loyalty ? transaction?.totalAmount : `$ ${transaction?.totalAmount}`}</td>
     
              <td className={FONT_STYLES.BOLD} style={{ color: 'green' }}>
                {transaction?.transactionMethod === paymentMethods.loyalty ? transaction?.refundableAmount : `$ ${transaction?.refundableAmount}`}
              </td>
            { (
              <td className={`text-danger ${FONT_STYLES.BOLD}`}>
                {transaction?.refundedAmount > 0 ? (transaction?.transactionMethod === paymentMethods.loyalty ? transaction?.refundedAmount : `$ ${transaction?.refundedAmount}`) : (transaction?.transactionMethod === paymentMethods.loyalty ? '0' : '$ 0')}
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
