import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import { getScope } from "app/services/helpers/account-id";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { getLoyaltyProgram } from "_metronic/layout/MenuConfig";
import _ from "lodash";
import { REBOOT, UPDATE } from "app/common/constant";

export default function DetailsRow(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { kiosks, onEditIconClick = () => {}, handleAction } = props;
  const [selectedKiosk, setSelectedKiosk] = useState({});
  const loyaltyProgram = useSelector(getLoyaltyProgram);

  /**
   * Handle click on Edit icon
   * @param e
   */
  function onSettingsClick(e, row) {
    e.stopPropagation();
    setSelectedKiosk(row);
    setAnchorEl(anchorEl ? null : e.currentTarget);
  }

  /**
   * Handle settings close
   * @param action
   */
  function onSettingsClose(action) {
    setAnchorEl(null);

    if (action) {
      handleAction(selectedKiosk.LocationId, selectedKiosk.id, action);
    }
  }

  return (
    <>
      {kiosks?.map((row) => {
        const { id, name, lastSync, power, diagnosticReport } = row;

        return (
          <TableRow hover key={id} id={`setup_locations_details_row_${id}`}>
            <TableCell align="left" padding="checkbox" />
            <TableCell align="left" padding="checkbox" />
            <TableCell align="center" padding="normal">
              {name}
            </TableCell>
            <TableCell align="left" padding="normal">
              {!_.isEmpty(diagnosticReport)
                ? new Date() - new Date(diagnosticReport?.creationDate ?? "") <
                  60 * 15 * 1000
                  ? `🌐 Connected via ${
                      diagnosticReport?.blob?.netStateType != null
                        ? diagnosticReport?.blob?.netStateType != "none"
                          ? diagnosticReport?.blob?.netStateType
                          : "Cell"
                        : diagnosticReport?.isWifi
                        ? "WiFi"
                        : "Cellular"
                    }`
                  : `❌ Offline`
                : "💭"}
            </TableCell>
            <TableCell
              id={`setup_locations_details_has_power_cell_${id}`}
              align="left"
              padding="normal"
            >
              {!_.isEmpty(diagnosticReport)
                ? diagnosticReport?.hasPower
                  ? `🔋 Getting Power - Battery ${diagnosticReport?.batteryLevel}%`
                  : `🔌 Unplugged ${diagnosticReport?.batteryLevel}%`
                : "💭"}
            </TableCell>
            <TableCell
              id={`setup_locations_details_diagnos_creation_date_cell_${id}`}
              align="left"
              padding="normal"
            >
              {!_.isEmpty(diagnosticReport)
                ? format(
                    new Date(diagnosticReport?.creationDate),
                    "MM/dd/yyyy - hh:mm aa"
                  )
                : "Never Checked In"}
            </TableCell>
            <TableCell
              id={`setup_locations_details_loyalty_program_cell_${id}`}
              align="left"
              padding="normal"
            >
              {loyaltyProgram != "globalConnect" && (
                <IconButton
                  id={`setup_locations_details_icon_button_cell`}
                  onClick={(e) => onSettingsClick(e, row)}
                >
                  <Settings />
                </IconButton>
              )}
              <Menu
                id="menu"
                anchorEl={anchorEl}
                keepMounted={false}
                open={Boolean(anchorEl)}
                onClose={() => {
                  setAnchorEl(null);
                }}
              >
                {loyaltyProgram != "globalConnect" && (
                  <MenuItem
                    id={`setup_locations_details_menu_item_rebot`}
                    onClick={() => onSettingsClose(REBOOT)}
                  >
                    Reboot Kiosk
                  </MenuItem>
                )}
                {getScope() === "super admin" && (
                  <MenuItem
                    id={`setup_locations_details_update`}
                    onClick={() => onSettingsClose(UPDATE)}
                  >
                    Update Kiosk
                  </MenuItem>
                )}
              </Menu>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
}
