import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  makeStyles,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import { EditSharp, ExpandMore, ExpandLess, CheckBox, Redeem } from "@material-ui/icons";
import { format } from "date-fns";

import {
  iphoneNames,
  paymentMethods,
  showLastFourDigit,
} from "app/common/LedgerConst";

import DetailsRow from "./components/DetailsRow/DetailsRow";
import {
  getComparator,
  getDefaultStyles,
  stableSort,
} from "../../../../services/table";
import _ from "lodash";
import { getScope } from "app/services/helpers/account-id";
import { ROLE } from "app/common/roleConstant";
import { calculateRefundStatus, refundStatusForUI } from "app/common/utils/issueRefunds";
import { ETransactionStatus } from "app/common/constant";
import { ERROR_PAYING, FULLY_REFUNDED, GLOBAL_CONNECT } from "app/common/constant";
import { useSelector } from "react-redux";
import { getLoyaltyProgram } from "_metronic/layout/MenuConfig";

const headCells = [
  { id: 'checkbox', numeric: false, disablePadding: false, label: '' },
  { id: "date", numeric: false, disablePadding: false, label: "Date" },
  {
    id: "orderNumber",
    numeric: false,
    disablePadding: false,
    label: "Order Number",
  },
  { id: "location", numeric: false, disablePadding: false, label: "Location" },
  { id: "customer", numeric: false, disablePadding: false, label: "Customer" },
  { id: "device", numeric: false, disablePadding: false, label: "Device" },
  {
    id: "paymentMethod",
    numeric: false,
    disablePadding: false,
    label: "Payment Method",
  },
  { id: "amount", numeric: false, disablePadding: false, label: "Amount" },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  { id: "actions", numeric: false, disablePadding: false, label: "Actions" },
];

function EnhancedTableHead({ finalHeadCells, rowsCount, onMasterToggle,
  selectedRows, }) {
  return (
    <TableHead id={`ledger_table_container_table_head_wrapper`}>
      <TableRow id={`ledger_table_container_table_head_row_wrapper`}>
        <TableCell
          id={`ledger_table_container_table_final_head_cell_wrapper`}
          padding="checkbox"
          style={{ width: 45 }}
        />
       {finalHeadCells?.map((headCell) => {
            if(getScope() === ROLE?.SUPERADMIN && headCell?.id === 'checkbox'){

            return(
              
                    <TableCell
                      key={headCell.id}
                      align="right"
                      padding="checkbox"
                    >
                       <Checkbox
                        indeterminate={selectedRows.length > 0 && selectedRows.length < rowsCount}
                        checked={selectedRows.length === rowsCount}
                        inputProps={{ 'aria-label': 'select all products' }}
                        onChange={() => onMasterToggle()}
                      />
                    </TableCell>
                  )
          }

          return(
          <TableCell
            id={`ledger_table_container_table_final_head_cell_label_${headCell.id}`}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        )})}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(getDefaultStyles);

export default function LedgerTable(props) {
  const loyaltyProgram = useSelector(getLoyaltyProgram);
  const {
    isLoads,
    rows = [],
    pagination = {
      page: 1,
      limit: 10,
    },
    salesTotal = 1,
    onExpandRow,
    onIssueRefund,
    onResendReceipt,
    onPaginationChange,
    isHideCustomerName = false,
    isOrderHistory = false,
    hideColumns,
    selectedRows,
    onSelectRow,
    onMasterToggle,
  } = props;
  const classes = useStyles();
  const [order] = useState("desc");
  const [orderBy] = useState("creationDate");
  const [finalHeadCells, setFinalHeadCells] = useState(headCells);
  const isSelected = id => selectedRows.indexOf(id) !== -1;
  
  useEffect(() => {
    if (hideColumns?.length) {
      setFinalHeadCells(
        headCells.filter((ele) => !_.includes(hideColumns, ele?.id))
      );
    }
  }, [hideColumns]);

  function handlePaginationChange({ target: { value } }, newPage = -1) {
    if (value) {
      const newSize = parseInt(value, 10);
      // calculating page no according new RowPerPage;
      const newP = parseInt(
        ((pagination.page - 1) * pagination.limit + 1) / newSize
      );
      onPaginationChange({
        page: newP + 1,
        limit: newSize,
      });
    }

    if (newPage >= 0 && typeof newPage !== "object") {
      onPaginationChange({
        page: newPage + 1,
        limit: pagination.limit,
      });
    }
  }
  return (
    <div id="ledger_table_root_wrapper" className={classes.root}>
      <Paper id="ledger_table_paper_wrapper" className={classes.paper}>
        <TableContainer id="ledger_table_container_wrapper">
          <Table
            id="ledger_table_container_inner_wrapper"
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead finalHeadCells={finalHeadCells} rowsCount={rows?.length} selectedRows={selectedRows} onMasterToggle={() => onMasterToggle()} />
            <TableBody id="ledger_table_container_body_wrapper">
              {stableSort(rows, getComparator(order, orderBy)).map((row) => {
                const {
                  id,
                  location,
                  user,
                  creationDate,
                  DeviceEndId,
                  DeviceStartId,
                  DeviceTypeEnd,
                  DeviceTypeStart,
                  order: { payment = [], prettyId: prettyId, refunds = [] },
                  total,
                  status,
                  statusMessage,
                  isExpanded,
                } = row;
                const refundStatus = refunds?.find((v) => v.success === true);
                const refundData = calculateRefundStatus(row)
                const refundStatusTextForUI = refundStatusForUI(refundData,status)
                return (
                  <React.Fragment key={id}>
                    <TableRow
                      id={`ledger_table_container_row_wrapper_${id}`}
                      hover
                      tabIndex={-1}
                      selected={isExpanded}
                      onClick={() => onExpandRow(row)}
                    >
                      <TableCell
                        id={`ledger_table_container_cell_button_wrapper_${id}`}
                        align="left"
                        padding="checkbox"
                      >
                        <IconButton>
                          {isExpanded ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                      </TableCell>
                     {getScope() === ROLE.SUPERADMIN && <TableCell
                        id={`ledger_table_container_cell_button_wrapper_checkbox_${id}`}
                        align="left"
                        padding="checkbox"
                      >
                         {refundStatusTextForUI !== ETransactionStatus.FULLY_REFUNDED && (status !== ETransactionStatus.ERROR_PAYING ) && <Checkbox
                              id={`product_table_product_checkbox`} 
                              checked={isSelected(id)}
                              inputProps={{ 'aria-label': 'select all products' }}
                              onChange={() =>  onSelectRow(id)}
                            />}
                      </TableCell>}
                      <TableCell
                        id={`ledger_table_container_cell_creationDate_wrapper_${id}`}
                        align="left"
                        padding="normal"
                      >
                        {format(
                          new Date(creationDate),
                          "MM/dd/yyyy - hh:mm aa"
                        )}
                      </TableCell>
                      {!hideColumns.includes('orderNumber') && (
                        <TableCell
                          id={`ledger_table_container_cell_prettyId_wrapper_${id}`}
                          align="left"
                          padding="normal"
                        >
                          {prettyId.split(":")[0] ?? id}
                        </TableCell>
                      )}
                      <TableCell
                        id={`ledger_table_container_cell_location_name_wrapper_${id}`}
                        align="left"
                        padding="normal"
                      >
                        {!location?.name ? "N/A" : location.name}
                      </TableCell>
                      {!isHideCustomerName && (
                        <TableCell align="left" padding="normal">
                          <ReactTooltip
                            id={`userTip${id}`}
                            place="top"
                            effect="solid"
                          >
                            {!user ? " " : `${user.email}`}
                          </ReactTooltip>
                          <a data-tip data-for={`userTip${id}`}>
                            {!user
                              ? "N/A"
                              : `${user.firstName} ${user.lastName}`}
                          </a>
                        </TableCell>
                      )}
                      <TableCell
                        id={`ledger_table_container_cell_device_start_id_wrapper_${id}`}
                        align="left"
                        padding="normal"
                      >
                        <div>
                          <ReactTooltip
                            id={`deviceTip1${id}`}
                            place="left"
                            effect="solid"
                          >
                            {!DeviceStartId ? "N/A" : DeviceStartId}
                          </ReactTooltip>
                          <a data-tip data-for={`deviceTip1${id}`}>
                            {iphoneNames[DeviceTypeStart]
                              ? iphoneNames[DeviceTypeStart]
                              : DeviceTypeStart}
                          </a>
                        </div>
                        <div
                          id={`ledger_table_container_cell_device_end_id_wrapper_${id}`}
                        >
                          <ReactTooltip
                            id={`deviceTip2${id}`}
                            place="right"
                            effect="solid"
                          >
                            {!DeviceEndId ? "N/A" : DeviceEndId}
                          </ReactTooltip>
                          <a data-tip data-for={`deviceTip2${id}`}>
                            {iphoneNames[DeviceTypeEnd]
                              ? iphoneNames[DeviceTypeEnd]
                              : DeviceTypeEnd}
                          </a>
                        </div>
                      </TableCell>
                      <TableCell
                        id={`ledger_table_container_cell_payment_wrapper_${id}`}
                        align="left"
                        padding="normal"
                      >
                        <div>
                          {/* condition for show the last four digit card num */}
                          {payment?.[0]&& (
                          <>
                          <ReactTooltip
                            id={`methodTip1${id}`}
                            place="right"
                            effect="solid"
                          >
                            {payment[0]?.method != "loyalty" ? "$" : <Redeem style={{ width: 15, height: 15, color: "#f50057" }} />}{" "}
                            {payment[0]?.amount
                              ? parseFloat(payment[0]?.amount)?.toFixed(2)
                              : "0.00"}{payment?.[1]?.method === "loyalty" ? "pts" : null}{" "}
                          </ReactTooltip>
                          <a data-tip data-for={`methodTip1${id}`}>
                            {paymentMethods[payment[0]?.method]}{" "}
                            {payment[0]?.lastFour && `(${payment[0]?.lastFour})`}
                            {showLastFourDigit(payment[1])  &&  loyaltyProgram === GLOBAL_CONNECT &&
                                  `(${payment[1]?.lastFour})`}
                          </a>
                          </>
                          )}
                          {payment?.[1] && (
                            <>
                              <ReactTooltip
                                id={`methodTip2${id}`}
                                place="right"
                                effect="solid"
                              >
                                {payment?.[1]?.method != "loyalty" ? "$" : <Redeem style={{ width: 15, height: 15, color: "#f50057" }} />}{" "}
                                {parseFloat(payment?.[1]?.amount)?.toFixed(2)} {payment?.[1]?.method === "loyalty" ? "pts" : null}{" "}
                              </ReactTooltip>
                              <a data-tip data-for={`methodTip2${id}`}>
                                {" & "}
                                {paymentMethods?.[payment?.[1]?.method]}
                                {payment?.[1]?.lastFour && `(${payment?.[1]?.lastFour})`}
                                {showLastFourDigit(payment?.[1]) && loyaltyProgram === GLOBAL_CONNECT && 
                                  `(${payment?.[1]?.lastFour})`}
                              </a>
                            </>
                          ) }
                        </div>
                      </TableCell>
                      <TableCell
                        id={`ledger_table_container_cell_total_wrapper_${id}`}
                        align="left"
                        padding="normal"
                      >
                        ${total}
                      </TableCell>
                      <TableCell
                        id={`ledger_table_container_cell_status_message_wrapper_${id}`}
                        align="left"
                        padding="normal"
                      >
                        {status.toLowerCase() !== "paid" && (
                          <ReactTooltip
                            id={`status${id}`}
                            place="top"
                            effect="solid"
                          >
                            {`${
                              statusMessage && statusMessage.name
                                ? statusMessage.name
                                : statusMessage
                            }`}
                          </ReactTooltip>
                        )}
                        <a
                          data-tip
                          data-for={`status${id}`}
                          style={{
                            color: status == "Paid" ? "green" : "red",
                            fontWeight: "bold",
                          }}
                        >
                          {refundStatusTextForUI}
                        </a>
                      </TableCell>
                      <TableCell
                        id={`ledger_table_container_cell_edit_button_wrapper_${id}`}
                        align="left"
                        padding="normal"
                      >
                        <IconButton onClick={(e) => onExpandRow(row)}>
                          <EditSharp />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    {isExpanded ? (
                      <DetailsRow
                        isLoads={isLoads}
                        currentRow={row}
                        onIssueRefund={onIssueRefund}
                        onResendReceipt={onResendReceipt}
                        isOrderHistory={isOrderHistory}
                        refundStatus = {refundStatusTextForUI}
                      />
                    ) : null}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          id={`ledger_table_container_pagination_wrapper`}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={salesTotal}
          rowsPerPage={pagination.limit}
          page={pagination.page - 1}
          onPageChange={handlePaginationChange}
          onRowsPerPageChange={handlePaginationChange}
        />
      </Paper>
    </div>
  );
}
