import React, { useEffect, useState } from 'react';
import to from 'await-to-js';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Typography, Divider, Button, TextField } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import jsPDF from "jspdf";
import { format } from 'date-fns';
import csvDownload from "json-to-csv-export";
import "jspdf-autotable";
import * as Sentry from "@sentry/react";

import ResponseModal from 'app/components/Helpers/ResponseModal';
import styles2 from '_metronic/_assets/sass/loading-text.module.scss';
import { accessPermission } from 'app/common/helperFunction';

import ExportAndCustomFilters from '../../../../../../partials/content/ExportAndCustomFilters';
import Search from '../../../../../../partials/content/Customers/Search';
import RemoveFromGroup from '../../../../../../partials/content/Customers/RemoveFromGroup';
import RemoveGroup from '../../../../../../partials/content/Groups/RemoveGroup';
import GroupCustomers from '../GroupCustomers/GroupCustomers';
import useCustomerHeader from '../../../../../../hooks/useCustomHeader';
import { getUserGroupById, updateGroupUsersList, createNewUserGroup, removeGroupUser, deleteGroup } from '../../../../../../services/groups';
import { getLoggedUserAccountId } from '../../../../../../services/helpers/account-id';

import styles from '../../../../../../../_metronic/_assets/sass/pages/groups.module.scss';
import { useCustomResponse } from 'app/hooks/useCustomResponse';
import getEllipsisFormat, { PAGINATION_INITIAL_STATE } from 'app/common/utils/generic';
import { CANCEL_TOKEN, CUSTOMERS, GROUP_CONSTANTS  } from 'app/common/constant';
import _ from 'lodash';
import { getCustomers, reportFormats } from 'app/services/reports';
import { HTTP_RESPONSE_MESSAGE } from 'app/common/messageConst';


let timeout;

export default function GroupDetails({ filter: { locations } }) {
  const { push } = useHistory();
  const [currentGroup, setCurrentGroup] = useState({});
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { id, isNew } = useCustomerHeader({
    property: 'name',
    isCreationAllowed: true,
    creatNew: 'Create New Group',
    staticHeader: 'Group Details'
  });

  const [sendToBack, setSendToBack] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [subHeaderName, setSubHeaderName] = useState("Create New")
  const [nameError, setNameError] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [responseValue, setResponseValue] = useCustomResponse();
  
  /**
   * Fetch group details on component mounting
   */
  useEffect(() => {
    if(!accessPermission("CustomersAll")){
      push({pathname: "/dashboard"});
    }

    if (locations && locations.length && !isNew)
      getGroupInformation();
    setIsLoaded(true)
      // eslint-disable-next-line
  }, []);

   useEffect(() => {
     const subheader = document.getElementById("subheader-title");
     subheader.innerText = getEllipsisFormat(subHeaderName);
   }, [subHeaderName]);

  /**
   * Get group related data
   */
  async function getGroupInformation() {
    const [err, res] = await to(getUserGroupById(id));
    
    setIsLoaded(true);
    if (err){
      Sentry.captureException(err); 
      setResponseValue({
        ...responseValue,  
        sMessageOpen: true,
        isSuccess: false,
        message: `Error while getting group info. ${err}`
      })  
      setIsLoading(false)
      return console.error('Error on group data retrieving: ', err);
    }

    const { userGroup } = res.data;
    if(!isNew) {
      setSubHeaderName(userGroup.name);
    }
    setTotalCustomers(userGroup.users.length);
    setCurrentGroup(userGroup);
    setIsLoading(false);
  }

  /**
   * Populate customers data from the child component
   */
  function initialCustomersSelection(customers) {
    setSelectedCustomers(customers);
  }

  /**
   * Handle state change on callback from child
   */
  function selectCustomer(customers) {
    if (!isNew) {
      setSelectedCustomers(customers);
      
    } else {
      setSelectedCustomers(customers);
      setCurrentGroup(oldData => ({ ...oldData, users: customers.map(c => c.id) }));
      setIsLoaded(true);
    }
  }

  function addMemberInGroup(customers) {
    setIsLoading(true)
    updateGroupUsersList(id, { users: customers.map(c => c.id) })
        .then(res => {
          setIsLoaded(true);
          getGroupInformation();
          setSelectedCustomers([])
        })
        .catch(err => {    
          setIsLoading(false);
          Sentry.captureException(err);
          setIsLoaded(true);
          console.error('Group users not updated: ', err);
        });
  }

  /**
   * Handle group properties change
   */
  function handleGroupNameChange(event) {
    if(nameError) setNameError(false);
    const { value: name } = event.target;

    setCurrentGroup(oldData => ({ ...oldData, name }));
  }

  /**
   * Remove customers from group
   */
  function onSingleRemoveFromGroup(customerId) {
    setIsLoading(true)
    removeGroupUser(id, customerId)
      .then(res => {
          getGroupInformation();
      })
      .catch(err => {
        setIsLoading(false)
        Sentry.captureException(err);
        setIsLoaded(true);
        console.error('Group member not deleted: ', err);
      })

  }

  function onRemoveFromGroup(groupId) {
    const APIMethods = [];
    selectedCustomers.forEach((member) => {
      if(currentGroup.users && currentGroup.users.some(user => user.id === member.id)) {
        APIMethods.push(removeGroupUser(groupId,member.id))
      }
    })
    Promise.all([...APIMethods])
      .then((res) => {
        res.forEach(ele => console.log(ele.data))
        push(`/groups/${id}`);

      })
      .catch(err => {
        Sentry.captureException(err);
        console.error('Group member not deleted: ', err);
        push(`/groups/${id}`);
      })
  }

  /**
   * Handle group deletion
   */
  function onDeleteGroup() {
    deleteGroup(currentGroup.id)
      .then(() => {
        /**
         * here we have a new group in the response res.data.userGroup
         * may be useful if we decide to stay on this view and just reload url
         */ 
        push({ pathname: '/groups' });
      })
      .catch(err => {
        Sentry.captureException(err);
        console.error('Group was not created: ', err);
      });
  }
  
  function handlePopupClose() {
    setResponseValue({...responseValue, isMessageOpen:false});
    if(sendToBack)
      push({ pathname: '/groups' });
  }

  /**
   * Update existing or create new group
   */
  async function onSave() {
    if (!currentGroup?.name?.trim()) {
      setNameError(GROUP_CONSTANTS.GROUP_NAME_ERROR)
    } else {
    const createdGroup = {
      ...currentGroup,
      users: selectedCustomers.map(c => c.id),
      AccountId: getLoggedUserAccountId()
    };

    try {
      await createNewUserGroup(createdGroup)
       setResponseValue({
        ...responseValue,  
        isMessageOpen: true,
        isSuccess:true,
        message:"Group Added"
       })
       setSendToBack(true)
    } catch (err) {
      if(err.response.status === 406) {
        setResponseValue({
          ...responseValue,
          isSuccess:false,
          isMessageOpen: true,
          isServerError: false,
          message:err.response.data.message
         })
         Sentry.captureException(err);         
         console.error('Group was not created: ', err);
            return;
          }
     }
    }
  }

  /**
   * Request data export to a certain format
   */
  async function handleExport(type) {
    const rows = currentGroup?.users;
    const [err, res] = await to(getCustomers({ page: PAGINATION_INITIAL_STATE.page,limit: rows?.length}, null,id));
    if (err) {
      Sentry.captureException(err);
      if (err.message === CANCEL_TOKEN) return;
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        isSuccess: false,
        message: `${HTTP_RESPONSE_MESSAGE.CUSTOMERS.GROUPS.ERROR} for group id - ${id} = ${err}`,
      });
      return console.error(HTTP_RESPONSE_MESSAGE.CUSTOMERS.GROUPS.ERROR);
    }
    const selectedCustomersData = res?.data?.users
    if (selectedCustomersData?.length > 0) {
     if (type === reportFormats.CSV) {
        const exportRows = [];
        selectedCustomersData.forEach((ele) => {
          const cashBalance = (ele?.funds?.length ? ele.funds[0]?.balance : 0);
          const loyaltyPoints = (ele?.points?.length ? ele.points[0]?.balance : 0);

          let obj = {
            Email: !ele.email
              ? "N/A"
              : ele.email,
            "First Name": !ele.firstName ? "-" : ele.firstName,
            "Last Name": !ele.lastName ? "-" : ele.lastName,
            "Yoke Cash Balance": `$ ${cashBalance}`,
            "Loyalty Points": `${loyaltyPoints} points`, 
          };
          exportRows.push(obj);
        });
        csvDownload(exportRows, `${currentGroup.name} members  - ${format(new Date(), 'MM/dd/yyyy')}.${type}`);
      }
    }else {
      setResponseValue({
        ...responseValue,
        isMessageOpen: true,
        isSuccess: false,
        message: HTTP_RESPONSE_MESSAGE.CUSTOMERS.GROUPS.ERROR,
      });
    }
  }

  return (
    <>
      {(!isNew && !currentGroup.id) ? 'Loading...' : (
        <>
          {!isNew && (
            <Search
              id="groupDetail_search"
              query={searchValue}
              onChange={e => {
                if(timeout){
                  clearTimeout(timeout);
                  timeout = null;
                }
                let text = e.target.value;
                timeout = setTimeout(() => {
                  setSearchQuery(text);
                }, 400)
                setSearchValue(e.target.value);
              }
              }
            />
          )}

          {!isNew && (
            <div id="group_detail_export_button" className={styles.buttonWrapper}>
              {currentGroup.users.length > 0  && <ExportAndCustomFilters onExport={handleExport}/>}
            </div>
          )}

          {isNew && (
            <>
              <Typography id="group_detail_name" variant="h4">Name: 
              <TextField
                id="filled-full-width"
                style={{ marginTop: 8, marginBottom: 40, width: 250, fontSize:20 }}
                placeholder="Enter a title"
                margin="normal"
                error={nameError}
                helperText={nameError === false ? '' : nameError}
                value={currentGroup.name || ''}
                name="name"
                onChange={handleGroupNameChange}
                InputLabelProps={{
                  shrink: false,
                }
              }
              />
              </Typography>
              
              <Typography variant="h4">Pick Users </Typography>
              <Divider/>
              <div className={clsx(styles.buttonWrapper, 'd-flex', 'justify-content-between')}>
                <Search
                  id="groupDetail_search"
                  iconPosition="start"
                  hasLabel={false}
                  placeholder="Filter Users"
                  query={searchValue}
                  onChange={e => {
                    clearTimeout(timeout);
                    let text = e.target.value;
                    timeout = setTimeout(() => {
                      setSearchQuery(text);
                    }, 400)
                    setSearchValue(e.target.value);
                  }
                  }
                />

                <Button
                  id="group_detail_primary_save_button"
                  startIcon={<Check/>}
                  type="button"
                  size="medium"
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() => onSave()}
                >
                  Save
                </Button>
              </div>
            </>
          )}

          {
            !isLoaded && <div id="group_detail_loading_container" className={styles2.loadingContainer}>
              <p id="group_detail_loading_paragrah" className ={styles2.loadingText}>
                Loading...
              </p>
            </div>
          }
          <GroupCustomers
            groupMembers={currentGroup.users}
            onInitialCustomersSelection={initialCustomersSelection}
            onSelectCustomers={selectCustomer}
            setIsLoaded={setIsLoaded}
            addMemberInGroup={addMemberInGroup}
            onRemoveFromGroup={onRemoveFromGroup}
            onSingleRemoveFromGroup={onSingleRemoveFromGroup}
            setTotalCustomers={setTotalCustomers}
            isLoading={isLoading}
            isNew={isNew}
            searchQuery={searchQuery}
          />
          
          {!isNew && (
            <>
              <div id="group_detail_remove_form_group_wrapper" className={clsx(styles.buttonWrapper, 'd-flex', 'justify-content-end')}>
                <RemoveFromGroup
                  selectedGroup={currentGroup.id}
                  selectedRows={selectedCustomers}
                  onUpdate={onRemoveFromGroup}
                />
              </div>

              <div id="group_detail_delete_form_group_wrapper" className={clsx(styles.buttonWrapper, 'd-flex', 'justify-content-end', 'align-items-center')}>
              <RemoveGroup
                  selectedGroup={currentGroup.id}
                  selectedCustomers={selectedCustomers}
                  totalItems={totalCustomers}
                  onUpdate={onDeleteGroup}
                  categoryOfDeletingItem={CUSTOMERS}
                />
              </div>
            </>
          )}
        </>
      )}
      <ResponseModal
        isSuccess={responseValue?.isSuccess}
        message={responseValue?.message}
        open={responseValue?.isMessageOpen}
        onClose={handlePopupClose}
        isServerError = {responseValue?.isServerError}
      />
    </>
   
  );
}
