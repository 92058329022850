import { NOT_APPLICABLE } from 'app/common/constant';
import { format, isAfter } from 'date-fns';

const ESalesAndReportType = {
  SALES: 'sales',
  CUSTOMERS: 'customers',
  Location: 'Location'
}

/**
 * Prepare report to view based on the received data
 * @param {*} summary by according report type
 * @param {*} byLocations report for the same metrics by locations
 * @param {*} relatedData request query data
 */
export function mapReportDataToView(summary, byLocations, relatedData, selectedMeasureFilter, selectedReportTypeFilter) {
  if (!summary || !byLocations)
    return {};

  const reportData = {};
  const { report } = summary;
  const { report: locationReports } = byLocations;
  const {
    locations,
    dateRange: {
      startDate,
      endDate
    },
    metrics: {
      values: metricValues,
      titles: metricTitles,
      selectedMeasure
    }
  } = relatedData;

  /**
   * Finalized algorithm block START
   */
  const sortedMetrics = metricValues.sort();
  const sortedDates = report.map(item => {
    const { saleperiod } = item;
    const date = new Date(saleperiod);
    return {
      saleperiod,
      title: format(date, 'MM/dd/yyyy'),
      value: date
    };
  }).sort((a, b) => (isAfter(b.value, a.value) ? -1 : 1)).filter((value, index, self) => self.findIndex(item=>(item.saleperiod === value.saleperiod))===index);
  
  
  const totalsForEachDate = {};
  const totalForEachMetric = {...locations.reduce((acc, cur) => ({ ...acc, [cur.id]: {} }), {})};
  // Prepare top level header
  reportData.firstHeadersRow = [
    {
      id: 'f1',
      title: '',
      colSpan: 1,
      className: 'generalInfo'
    }, {
      id: 'f2',
      title: `${format(startDate, 'MM/dd/yyyy')} - ${format(endDate, 'MM/dd/yyyy')}`,
      colSpan: report.length + 1,
      className: 'generalInfo',
      secondaryClassName: 'borderRight'
    }, {
      id: 'f3',
      title: 'TOTALS BY MARKETS',
      colSpan: metricValues.length,
      className: 'aggregated'
    }
  ];

  // Declare second level headers (dates and metrics)
  reportData.secondHeadersRow = [
    {
      id: 's1',
      title: 'Market',
      colSpan: 2,
      align: 'left',
      className: 'borderRight'
    },
    /**
     * Create column titles for every date available in the report
     */
    ...sortedDates.map((date, i) => {
      const { title, saleperiod } = date;

      // find all metrics for this day
      if (!totalsForEachDate.hasOwnProperty(saleperiod)) {
        const searchedItem = report.find(item => item.saleperiod === saleperiod);
        const { saleperiod: date, ...allMetrics } = searchedItem;

        totalsForEachDate[saleperiod] = { ...allMetrics };
      }

      return {
        id: `s${i + 2}`,
        title,
        className: (i === report.length - 1) ? 'borderRight' : null
      };
    }),
    /**
     * Create column title for every metric available in the report
     * for displaying their total values by each location
     */
    ...sortedMetrics.map((metric, i) => {
      const title = metricTitles.find(item => item.alias === metric).name;

      Object.keys(totalForEachMetric).forEach(key => {
        if (metric === 'margin')
          totalForEachMetric[key][metric] = [];
        else
          totalForEachMetric[key][metric] = 0;
      });

      for (const reportedDate of locationReports) {
        if (selectedReportTypeFilter == "Customers") {
          let { UserId } = reportedDate;
          if (metric === 'margin')
            totalForEachMetric[UserId][metric].push(parseFloat(reportedDate[metric]) * 100);
          else
            totalForEachMetric[UserId][metric] += parseFloat(reportedDate[metric]);
        } else {
            let { LocationId } = reportedDate;
            if (metric === 'margin')
              if (totalForEachMetric[LocationId] && totalForEachMetric[LocationId][metric])
                totalForEachMetric[LocationId][metric].push(parseFloat(reportedDate[metric]) * 100);
            else
              if (totalForEachMetric[LocationId] && totalForEachMetric[LocationId][metric])
                totalForEachMetric[LocationId][metric] += parseFloat(reportedDate[metric]);
        
        }
        
      }

      return {
        id: `s${i + sortedDates.length + 2}`,
        title
      };
    })
  ];

  // Declare the body of the table (info for each location with it's totals)
  reportData.body = [
    ...locations.map(({ id, name }, i) => ({
      id,
      currency: '$',
      hover: true,
      rowDetails: [
        {
          id: `b${i}${id}`,
          value: { name },
          colSpan: 2,
          align: 'left',
          className: 'borderRight'
        },
        /**
         * Prepare info for each of the sorted dates for current location
         */
        
        ...sortedDates.map((date, i) => {
          const { saleperiod } = date;
          let locationData = locationReports.find(item => item.saleperiod === saleperiod && item.LocationId === id);
          
          if (selectedReportTypeFilter == "Customers") 
            locationData = locationReports.find(item => item.saleperiod === saleperiod && item.UserId === id);


          let [integer, decimal] = "0.0".split('.');
          if (locationData){
            if(locationData[selectedMeasure])
              [integer, decimal] = (locationData[selectedMeasure].toString()).split('.');
            return {
              id: `b${i + 2}`,
              value: { integer: parseInt(integer, 10), decimal: !decimal ? '00' : decimal.slice(0, 2) },
              hasCurrency: true,
              className: i === sortedDates.length - 1 ? 'borderRight' : null
            };
          } else {
            return {
              id: `b${i + 2}`,
              value: { integer: 0, decimal: 0 },
              hasCurrency: true,
              className: i === sortedDates.length - 1 ? 'borderRight' : null
            };
        }
        }),
        /**
         * Fill the info about location's totals for each metrics
         */
        ...sortedMetrics.map((metric, i) => {
          if (metric === 'margin') {
            const marginArray = totalForEachMetric[id][metric];
            const [integer, decimal] = (marginArray.reduce((a, c) => a + c, 0) / (marginArray.length === 0 ? 1 : marginArray.length))
              .toString().split('.');
            return {
              id: `b${i + sortedDates.length + 2}`,
              value: { integer: parseInt(integer, 10), decimal: !decimal ? '00' : decimal.slice(0, 2) },
              currency: '%',
              hasCurrency: true,
              currencyPosition: 'after'
            };
          }

          if (metric === 'customerCount') {
            const [integer, decimal] = totalForEachMetric[id][metric].toString().split('.');


            return {
              id: `b${i + sortedDates.length + 2}`,
              value: { integer: parseInt(integer, 10) },
              currency: '',
              hasCurrency: false,
              currencyPosition: 'after'
            };
          }

          const [integer, decimal] = totalForEachMetric[id][metric].toString().split('.');

          return {
            id: `b${i + sortedDates.length + 2}`,
            value: { integer: parseInt(integer, 10), decimal: !decimal ? '00' : decimal.slice(0, 2) },
            hasCurrency: true
          };
        })
      ]
    })),
    {
      id: locations.length + 1,
      currency: '$',
      hover: false,
      className: 'totals',
      rowDetails: [
        {
          id: 't1',
          value: { name: 'Totals' },
          colSpan: 2,
          align: 'left',
          className: 'borderRight',
          secondaryClassName: 'boldText'
        },
        // Here we need to find all totals for each date by selected metric
        ...sortedDates.map((date, i) => {
          const { saleperiod } = date;
          // loop through each location and sum up all current metric for today
          const total = locations.reduce((a, c) => {
            const { id } = c;
            const locationTotal = locationReports.reduce((la, lc) => {

              if (lc.UserId === id && lc.saleperiod === saleperiod)
              return la + parseFloat(lc[selectedMeasure]);

              if (lc.LocationId === id && lc.saleperiod === saleperiod)
                return la + parseFloat(lc[selectedMeasure]);

              return la;
            }, 0);

            return a + locationTotal;
          }, 0);
          const [integer, decimal] = (selectedMeasure !== 'margin' ? total
            : total / locations.length).toString().split('.');

          return {
            id: `t${i + 2}`,
            value: { integer: parseInt(integer, 10), decimal: !decimal ? '00' : decimal.slice(0, 2) },
            hasCurrency: true,
            className: i === sortedDates.length - 1 ? 'borderRight' : null
          };
        }),
        ...sortedMetrics.map((metric, i) => {
          let currentMetricValue;

          if (metric === 'margin')
            currentMetricValue = [];
          else
            currentMetricValue = 0;

          for (const location in totalForEachMetric)
            if (metric === 'margin')
              currentMetricValue.push(...totalForEachMetric[location][metric]);
            else
              currentMetricValue += totalForEachMetric[location][metric];

          const totalValue = metric === 'margin'
            ? currentMetricValue.reduce((a, c) => a + c, 0) / (currentMetricValue.length === 0 ? 1 : currentMetricValue.length)
            : currentMetricValue;
          const [integer, decimal] = totalValue.toString().split('.');
          const mainPart = {
            id: `t${i + sortedDates.length + 2}`,
            value: { integer: parseInt(integer, 10), decimal: !decimal ? '00' : decimal.slice(0, 2) },
            hasCurrency: true
          };
          const customerCountPart = {
            id: `t${i + sortedDates.length + 2}`,
            value: { integer: parseInt(integer, 10) },
            hasCurrency: false
          };
          const percentageValues = {
            currency: '%',
            currencyPosition: 'after'
          };

          return metric === 'margin' ? { ...mainPart, ...percentageValues } : metric === 'customerCount' ? customerCountPart : mainPart;
        })
      ]
    }
  ];

  // Declare the footer ot the table (info wit totals by each metrics by date)
  reportData.footer = [
    ...sortedMetrics.map((metric, i) => {
      const { name } = metricTitles.find(item => item.alias === metric);
      const rowData = [
        {
          id: `0${metric}`,
          value: { name },
          align: 'left',
          className: 'borderRight'
        },
        ...sortedDates.map((date, i) => {
          const { saleperiod } = date;
          const totalByDate = metric === 'margin' ? totalsForEachDate[saleperiod][metric] * 100
            : totalsForEachDate[saleperiod][metric];
          let [integer, decimal] = totalByDate ? totalByDate.toString().split('.') : ["0", "00"];
          const percentageValues = {
            currency: '%',
            currencyPosition: 'after'
          };
          decimal = decimal == null ? "00" : decimal;
          const mainPart = {
            id: `${metric}${i + 1}`,
            value: { integer: parseInt(integer, 10), decimal: decimal.slice(0, 2) },
            hasCurrency: true,
            className: i === sortedDates.length - 1 ? 'borderRight' : null
          };
          const customerCountPart = {
            id: `${metric}${i + 1}`,
            value: { integer: parseInt(integer, 10) },
            hasCurrency: false,
            className: i === sortedDates.length - 1 ? 'borderRight' : null
          };

          return metric === 'margin' ? { ...mainPart, ...percentageValues } : metric === 'customerCount' ? customerCountPart : mainPart;
        }),
        {
          id: `${metric}${sortedDates.length + 1}`,
          value: {},
          colSpan: sortedMetrics.length,
          hasBorder: false
        }
      ];

      if (i === 0)
        rowData.unshift({
          id: 'metricTotalsByDate',
          value: {
            name: 'TOTALS BY DATE RANGE'
          },
          size: 'small',
          align: 'center',
          rowSpan: metricTitles.length
        });

      return {
        id: `f${i + 1}`,
        currency: '$',
        rowDetails: [...rowData]
      };
    })
  ];

  return reportData;
}

export function mapReportDateToView_V2(data, locations) {
  const { reports: [{ blocks, total, reportType, metric, startDate, endDate }] } = data;
  const reportData = {};

  if(!(blocks && blocks.length)) {
    return reportData;
  }

  //declare Top Level Header
  reportData.firstHeadersRow = [
    {
      id: 'f1',
      title: '',
      colSpan: 1,
      className: 'generalInfo'
    }, {
      id: 'f2',
      colSpan: total.length + 1,
      className: 'generalInfo',
      secondaryClassName: 'borderRight'
    },
  ];

  // declare second level header (dates)
  reportData.secondHeadersRow = [
    {
      id: 's1',
      title: 'Market',
      colSpan: 2,
      align: 'left',
      className: 'borderRight'
    },

    ...total.map((ele,i) => {
      return {
        id: `s${i + 2}`,
        title: ele.date,
        colSpan: 1,
        className: (i === blocks.length - 1) ? 'borderRight' : null // need to resolve properly
      };
    })

  ];

  // Declare the body of the table (info for each location with it's totals)
  reportData.body = [
    ...blocks.map((ele, i) => ({

      id: ele.id,
      currency: '$',
      hover: true,
      name: (reportType !== ESalesAndReportType.CUSTOMERS && ele.type === ESalesAndReportType.Location && locations?.find(l => l.id === ele.id)) 
      ? locations?.find(l => l.id === ele.id)?.name 
      : ele?.name || NOT_APPLICABLE,

      rowDetails: [
        {
          id: `b${i}${ele.id}`,
          value: {
            name: (reportType !== ESalesAndReportType.CUSTOMERS && ele.type === ESalesAndReportType.Location && locations.find(l => l.id === ele.id))
              ? (locations.find(l => l.id === ele.id)?.name)
              : (ele?.name || NOT_APPLICABLE)
          },
          colSpan: 2,
          align: 'left',
          className: 'borderRight'
        },
        ...calculateCellElements(ele.data,'b',metric)
      ]

    })),
    {
      id: blocks.length + 1,
      currency: '$',
      hover: false,
      className: 'totals',
      rowDetails: [
        {
          id: 't1',
          value: { name: 'Totals' },
          colSpan: 2,
          align: 'left',
          className: 'borderRight',
          secondaryClassName: 'boldText'
        },
        ...calculateCellElements(total,'t',metric)
      ]
    }
  ]

  return reportData;

}

function calculateCellElements(obj, uniqueText, metric) {
  const check = !(metric === "customerCount" || metric === "basketSize");
    return obj.length > 0 ? obj.map((ele,i)=>{
      const value = parseFloat(ele.value ? ele.value : "0.00"); 
      const [integer, decimal] = value.toString().split('.'); 
      const formattedDecimal = decimal ? (decimal.length === 1 ? decimal + '0' : decimal.slice(0, 2)) : '00'; 
      return {
        id: `${uniqueText}${i + 2}`,
        value: check
          ? { integer: integer, decimal: formattedDecimal } // Used the manually formatted decimal
          : { integer: ele.value },
        colSpan: 1,
        hasCurrency: check,
        className: i === obj.length - 1 ? 'borderRight' : null
      };
    }) : [];
}


