import axios from 'axios';
import { getDefaultParameters_V2 } from './helpers/getDefaultParameters';
import axiosInstance from './helpers/axiosInstance';
import _ from 'lodash';

/**
 * Get list all all available groups for current account
 */
export function getUserGroups(accountId, { limit = 10, page = 1 }, exportType,groupName) {
  let url = `/users/groups?accountId=${accountId}&limit=${limit}&page=${page}`+getDefaultParameters_V2(false);
  if (exportType)
    url += `&export=${exportType}`;
  if(!_.isNil(groupName) &&!_.isEmpty(groupName)){
    url += `&keywords=${groupName}`
  }
  return axios.get(url);
}

export function getUserGroupsdownload(accountId, { limit = 10, page = 1 }, exportType,fromat) {
  let url = `users/reportDownload?accountId=${accountId}&limit=${limit}&page=${page}&format=${fromat}`+getDefaultParameters_V2(false);
  if (exportType)
    url += `&export=${exportType}`;
  return axios.get(url,{ responseType: 'blob' });
}



/**
 * Get group details by id
 * @param {string} groupId
 */
export function getUserGroupById(groupId, exportType) {
  let url = `/users/groups/${groupId}`;
  if (exportType)
    url += `?export=${exportType}`+getDefaultParameters_V2(false);
  else url += getDefaultParameters_V2(true)
  return axios.get(url);
}

/**
 * Create a new group of users
 */
export function createNewUserGroup(data) {
  return axios.post('/users/groups', data);
}

/**
 * Update list of users related to current group
 */
export function updateGroupUsersList(groupId, data) {
  return axios.post(`/users/groups/${groupId}`, data);
}

/**
 * remove a particular user from current group
 */
export function removeGroupUser(groupId,userId) {
  return axiosInstance.delete(`/users/groups/${groupId}/${userId}`);
}

/**
 * remove a particular user from current group
 */
 export function deleteGroup(groupId) {
  return axiosInstance.delete(`/users/groups/${groupId}`);
}