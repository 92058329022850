import { FormHelperText, Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styles from "./GiftCardsBasicDetails.module.scss";
import map from "lodash/map";
import CustomButton from "app/components/Promotions/components/PromotionDetails/components/CustomButton/CustomButton";
import clsx from "clsx";
import Select from "react-select";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { useSelector } from "react-redux";
import { filterItemById } from "app/services/helpers/filterArrayById";
import { GIFTCARDTYPE } from "app/common/constant";

const GiftCardsTypeBtns = [
  { id: 1, title: "Once", value: "once" },
  { id: 2, title: "Recurring", value: "recurring" },
];

const IssuesOptions = [
  { id: 1, label: "Daily", value: "daily" },
  { id: 2, label: "Weekly", value: "weekly" },
  { id: 3, label: "Monthly", value: "monthly" },
];

const ExpiresOptions = [
  { id: 1, label: "End of day", value: "endOfDay" },
  { id: 2, label: "End of week", value: "endOfWeek" },
  { id: 3, label: "End of month", value: "endOfMonth" },
];

export default function GiftCardsBasicDetails(props) {
  const {
    changeAmount,
    changeExpirationDate,
    changeType,
    changeRecurringMetric,
    selectedGiftCardId
  } = props;
  const [amount, setAmount] = useState('');
  const [giftCardType, setGiftCardType] = useState(GIFTCARDTYPE.ONCE);
  const [issue, setIssue] = useState();
  const [expire, setExpire] = useState(null);
  const [noAmount, setNoAmount] = useState(true);
  const storedGiftCardsFromRedux = useSelector((state) => state.customers.giftCards);
  const today = new Date();

  let issueDate = moment(new Date()).format("MM/DD/YYYY h:mm a");
  const [minDate, setMinDate] = useState(new Date(new Date()?.setHours(new Date()?.getHours() + 2)));
  let expiresDate = null;

  
  useEffect(() => {
    const timer = setInterval(() => {
      setMinDate(new Date(new Date()?.setHours(new Date()?.getHours() + 2)));
    }, 1000);

    return () => clearInterval(timer); 
  }, []);


  useEffect(() => {
    handleEditGiftCardBasicDetails();
    return;
  }, [selectedGiftCardId]);

  function handleEditGiftCardBasicDetails () {
    try {
      const result = filterItemById(storedGiftCardsFromRedux,selectedGiftCardId)
      let recurringMetric = result.recurringIssueMetric;
      /**
       * @response result?.recurring 
       *  checking if recurring metrix then retun [ ] else return 0
       * */ 
     
      let selectedIssueOption = result?.recurring ? IssuesOptions?.filter((e,i)=>e.value === recurringMetric): 0;
        setAmount(result?.amount)
        changeAmount(result?.amount)
        setGiftCardType(result?.recurring ? GIFTCARDTYPE?.RECURRING:GIFTCARDTYPE?.ONCE)
        changeType(result?.recurring ? GIFTCARDTYPE?.RECURRING:GIFTCARDTYPE?.ONCE)
        setExpire(result?.expirationDate)
        changeExpirationDate(result?.expirationDate)
        setRecurringMetric(selectedIssueOption)
        changeRecurringMetric(selectedIssueOption)
      } catch (error) {
        console.error('Error fetching gift card data:', error);
      }
  }

  function setAmountMaster(amount) {
    changeAmount(amount)
    setAmount(amount)
    if (amount == null || amount == "") {
      setNoAmount(true)
    } else {
      setNoAmount(false)
    }
  }

  function setExpirationDateMaster(amount) {
    changeExpirationDate(amount)
    setExpire(amount)
  }

  function setTypeMaster(amount) {
    changeType(amount)
    setGiftCardType(amount)
    setExpire(null)
    setIssue(null)
  }

  function setRecurringMetric(amount) {
    setIssue(amount)
    changeRecurringMetric(amount.value)
  }
  return (
    <Grid
      id={`gift_card_basic_details_root_container`}
      container
      direction="row"
      alignItems="center"
      className={styles.wrapperGrid}
    >
      <Grid  id={`gift_card_basic_details_root_item_container`} item xs={10} className={styles.borderBox}>
        <div  id={`gift_card_basic_details_root_item_inner_container`}>
          <Typography id={`gift_card_basic_details_amount_label`} variant="h5">Amount</Typography>
          <Grid
            id={`gift_card_basic_details_row_amount_wrapper`}
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Typography  id={`gift_card_basic_details_amount_sign_label`} variant="h6" className={styles.advancedHead}>
              $
            </Typography>

            <TextField
              id={`gift_card_basic_details_amount_text_field`}
              required
              placeholder="0.00"
              value={amount}
              variant="outlined"
              onChange={(e) => {
                setAmountMaster(e.target.value);
              }}
              helperText={(noAmount) && (
                <FormHelperText id={`gift_card_basic_details_amount_form_helper_text_field`} style={{ color: "black", fontSize: "14px" }}>
                  Required
                </FormHelperText>
              )}
            />
          </Grid>
        </div>

        <div id={`gift_card_basic_details_grid_outer_wrapper`} className={styles.grids}>
          <Typography variant="h5">Type</Typography>
          <Grid
            id={`gift_card_basic_details_grid_custom_button_inner_wrapper`}
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid id={`gift_card_basic_details_grid_custom_item_button_wrapper`} item className={styles.btnGroup}>
              {map(GiftCardsTypeBtns, (btn) => (
                <CustomButton
                  {...btn}
                  isActive={giftCardType === btn.value}
                  onClick={(value) => setTypeMaster(value)}
                  key={btn.id}
                  hasMinWidth={false}
                  hasDivider={false}
                  small
                />
              ))}
            </Grid>
          </Grid>
        </div>



        <div id={`gift_card_basic_details_recurring_detail_wrapper`} style={{ marginTop: 20 }}>
          <Grid
            id={`gift_card_basic_details_recurring_detail_inner_wrapper`}
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Typography id={`gift_card_basic_details_recurring_label`}  variant="h6" className={styles.advancedHead}>
              {giftCardType === GIFTCARDTYPE.RECURRING ? "Reissues" : "Issues"}
            </Typography>

            <div id={`gift_card_basic_details_recurring_select_wrapper`} style={{ width: "30%" }}>
              {giftCardType === GIFTCARDTYPE.RECURRING ? (
                <Select
                  id={`gift_card_basic_details_recurring_select_input`}
                  value={issue}
                  options={IssuesOptions}
                  className={clsx("basic-multi-select", styles.filter)}
                  onChange={setRecurringMetric}
                />
              ) : (
                'The giftcard will issue immediately '
              )}
            </div>
          </Grid>
        </div>
        {giftCardType === GIFTCARDTYPE.RECURRING ? (
          <div id={`gift_card_basic_details_recurring_grid_wrapper`} className={styles.grids}>
          <Grid
            id={`gift_card_basic_details_recurring_grid_row_wrapper`}
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Typography id={`gift_card_basic_details_recurring_issues_label`} variant="h6" className={styles.advancedHead}>
              Issues
            </Typography>

            <div id={`gift_card_basic_details_recurring_issues_details_content`} style={{ width: "30%" }}>
              {giftCardType === "recurring" ? (
                'The giftcard will issue immediately '
              ) : ( null
              )}
            </div>
          </Grid>
        </div>
        ) : null}


        <div id={`gift_card_basic_details_expires_wrapper`} className={styles.grids}>
          <Grid
            id={`gift_card_basic_details_expires_inner_wrapper`}
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Typography id={`gift_card_basic_details_expires_label`} variant="h6" className={styles.advancedHead}>
              Expires
            </Typography>

            <div  id={`gift_card_basic_details_content_wrapper`} style={{ width: "30%" }}>
              {giftCardType === GIFTCARDTYPE.RECURRING ? (
                'The giftcard will expire when the new giftcard is issued.'
              ) : (
                <MuiPickersUtilsProvider  id={`gift_card_basic_details_mui_picker`} utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    disablePast={false}
                    className={styles.noMargin}
                    inputVariant="outlined"
                    variant="inline"
                    format="MM/dd/yyyy h:mm a"
                    margin="normal"
                    id="expires-date"
                    name="expiresDate"
                    minDate={minDate}

                    allowKeyboardControl
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    value={expire}
                    onChange={(e) => {setExpirationDateMaster(e)}}
                  />
                </MuiPickersUtilsProvider>
              )}
            </div>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}
