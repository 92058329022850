/**
 *  Modules party import
 * */

import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, ClickAwayListener, TextField } from "@material-ui/core";
import {
  ToggleButtonGroup,
  ToggleButton,
  Autocomplete,
} from "@material-ui/lab";
import { useHistory, useLocation } from "react-router-dom";
import { sub, add } from "date-fns";
import queryString from "query-string";
import * as Sentry from "@sentry/react";
import { isEmpty } from "lodash";

import {
  ALL_LOCATIONS,
  ALL_OPERATORS,
  CANCEL_TOKEN,
  DASHBOARDFILTERS,
  DATE_PICKER_COMPONENT_VIEWS,
  ERROR_MESSAGES_FOR_USERS,
  FILTERS,
  LOCATION_PARAM,
  OPERATOR_PARAM,
  PATHNAME,
} from "app/common/constant";
/**
 *  File import
 * */

import { getComparator, stableSort } from "app/services/table";
import { hasMultipleAccounts2 } from "app/services/helpers/account-id";
import { subHeaderActions } from "app/store/actions";
import { getAllLocations } from "../../../services/locations";
import { getOperatorV2 } from "../../../services/account";
/**
 * SCSS Import
 *  */

import styles from "./main-subheader-filter.module.scss";
import DatePickerComponent from "app/components/Helpers/DatePicker";

const today = new Date();
const defaultFilters = {
  period: DASHBOARDFILTERS[2].value,
  startDate: null,
  endDate: null,
  location: ALL_LOCATIONS.id,
  operator: ALL_OPERATORS.id,
};

export default function MainSubheaderFilter(props) {
  const { location, push } = useHistory();
  const { search } = useLocation();

  const [selectedFilters, setSelectedFilters] = useState(
    !location.search ? defaultFilters : getSerializedFilters(location.search)
  );
  const [locationOptions, setLocationOptions] = useState([
    { id: ALL_LOCATIONS.id, name: ALL_LOCATIONS.name },
  ]);
  const [searchLocationOptions, setSearchLocationOptions] = useState([
    { id: ALL_LOCATIONS.id, name: ALL_LOCATIONS.name },
  ]);
  const [operatorOptions, setOperatorOptions] = useState([
    { id: ALL_OPERATORS.id, name: ALL_OPERATORS.name },
  ]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [datePeriodVisible, setDatePeriodVisible] = useState(false);
  const [locationSearchText, setLocationSearchText] = useState("");
  const [operatorSearchText, setOperatorSearchText] = useState("");
  const [noLocation, setNoLocation] = useState("noLocation");
  const [noOperator, setNoOperator] = useState("noOperator");
  const [firstLocationFetch, setFirstLocationFetch] = useState(false);
  const [updatedLocationList, setUpdatedLocationList] = useState(true);
  const [filters, setFilters] = useState(FILTERS);
  const { onChange } = props;
  const updatedOperator = queryString.parse(search).operator;
  const updatedLocation = queryString.parse(search).location;
  const getCompressedLocation = true;
  const dispatch = useDispatch();
  const history = useLocation();
  const activeOperator = useSelector(
    (state) => state.subHeader.selectedOperator
  );
  const params = new URLSearchParams(search);
  let operatorFromURLParams = params.get("operator");

  const captureAllLocations = async () => {
    const locationsResponse = await getAllLocations();
    const allLocs = locationsResponse?.data?.data;
    if (allLocs.length > 0) {
      dispatch(subHeaderActions.storeOperatorAndLocationData(allLocs));
    }
    return allLocs;
  };

  const operatorAndLocationsDataFromRedux = useSelector(
    (state) => state.subHeader.operatorAndLocationsData
  );

  function getOperatorSpecificLocations(
    operatorAndAllLocationsData,
    operatorId
  ) {
    if (operatorId == 1) return operatorAndAllLocationsData;
    const locationDataByOperatorId = operatorAndAllLocationsData?.filter(
      (operatorAndLocationData) =>
        operatorAndLocationData.accountId === operatorId
    );
    return locationDataByOperatorId;
  }

  /**
   * Hide custom period block
   */
  const handleResetClick = useCallback(() => {
    setSelectedFilters((cur) => ({ ...cur, startDate: null, endDate: null }));
    setDatePeriodVisible(false);
  }, []);
  /**
   * Handle selected filters part change
   */
  const handleFilterChange = useCallback((value, key) => {
    setSelectedFilters((cur) => ({ ...cur, [key]: value }));
  }, []);

  /**
   * Fetch filters' options on component mounting
   */
  useEffect(() => {
    /**
     * fetching operator from url and updating dropdown
     */
    if (updatedOperator && updatedOperator != 1) {
      handleFilterChange(updatedOperator, OPERATOR_PARAM);
      setUpdatedLocationList(false);
    } else {
      setUpdatedLocationList(true);
    }
  }, [search]);

  useEffect(() => {
    if (activeOperator && activeOperator.id != 1) {
      handleFilterChange(
        activeOperator.id == null ? operatorOptions[0].id : activeOperator.id,
        OPERATOR_PARAM
      );
    }
    if (operatorOptions?.length === 1) {
      getOperatorsFiltersOptions();
    }
  }, [activeOperator]);

  useEffect(() => {
    if (isLoaded) return;
    getOperatorsFiltersOptions();
  }, []);

  const getLocationsByOperatorId = async () => {
    if (!operatorAndLocationsDataFromRedux?.length) {
      await captureAllLocations();
    } else {
      const locations = await getOperatorSpecificLocations(
        operatorAndLocationsDataFromRedux,
        operatorFromURLParams
      );
      if (locations?.length) {
        const mappedLocations = locations?.map((location) => {
          const { id, name } = location;
          return { id, name };
        });
        dispatch(subHeaderActions.storeLocations(mappedLocations));
        const sortedLocationsList = stableSort(
          mappedLocations,
          getComparator("asc", "name")
        );
        dispatch(
          subHeaderActions.storeCompressedLocations(sortedLocationsList)
        );
        if (!locationSearchText) {
          setLocationOptions([
            { id: ALL_LOCATIONS.id, name: ALL_LOCATIONS.name },
            ...sortedLocationsList,
          ]);
          setFirstLocationFetch(true);
        }
        setSearchLocationOptions([
          { id: ALL_LOCATIONS.id, name: ALL_LOCATIONS.name },
          ...sortedLocationsList,
        ]);
      } else {
        dispatch(subHeaderActions.storeCompressedLocations([]));
        dispatch(subHeaderActions.storeLocations([]));
        if (!locationSearchText) {
          setLocationOptions([
            { id: ALL_LOCATIONS.id, name: ALL_LOCATIONS.name },
          ]);
        }
        setSearchLocationOptions([
          { id: ALL_LOCATIONS.id, name: ALL_LOCATIONS.name },
        ]);
      }
    }
  };
  useEffect(() => {
    getLocationsByOperatorId();
    setIsLoaded(true);
    return;
  }, [
    operatorFromURLParams,
    operatorAndLocationsDataFromRedux,
    activeOperator,
    locationSearchText,
  ]);
  /**
   * Reset filters on url change
   */
  useEffect(() => {
    push({ search: stringifyFiltersToSearch(selectedFilters) });
  }, [location?.search, location?.pathname]);

  /**
   * Handle filter change and emit it to all child components
   */
  useEffect(() => {
    /**
     * always have at least one more location
     */
    if (
      locationOptions.length >= 1 &&
      (operatorOptions.length > 1 ||
        updatedOperator != 1 ||
        !Boolean(hasMultipleAccounts2()))
    ) {
      onChange({
        ...selectedFilters,
        locations: locationOptions,
        operators: operatorOptions,
      });

      push({ search: stringifyFiltersToSearch(selectedFilters) });
    }
  }, [selectedFilters, locationOptions, operatorOptions]);

  /**
   * This effect is used to auto populate dates that are missing
   * and warn user about not logical duration
   */
  useEffect(() => {
    if (!datePeriodVisible && selectedFilters.startDate) {
      setDatePeriodVisible(true);
    }

    if (!datePeriodVisible) return;

    if (selectedFilters.startDate && !selectedFilters.endDate)
      setSelectedFilters((cur) => ({
        ...cur,
        endDate: add(cur.startDate, { days: 1 }),
      }));
    else if (!selectedFilters.startDate && selectedFilters.endDate)
      setSelectedFilters((cur) => ({
        ...cur,
        startDate: sub(cur.endDate, { days: 1 }),
      }));
  }, [selectedFilters]);

  useEffect(() => {
    if (isEmpty(locationSearchText)) {
      setSearchLocationOptions([...locationOptions]);
    }
  }, [locationSearchText]);
  useEffect(() => {
    getOperatorsFiltersOptions();
  }, [operatorSearchText]);

  function handleTextChange(text, type) {
    let value = text ?? "";
    if (type === LOCATION_PARAM) {
      setLocationSearchText(value);
      if (text) setNoLocation(value);
    } else {
      setOperatorSearchText(value);
      if (text) setNoOperator(value);
    }
  }
  /**
   * Fetch filter options from the API
   */
  function getOperatorsFiltersOptions() {
    getOperatorV2({ limit: 100, page: 1 }, operatorSearchText)
      .then((operatorsRes) => {
        if (operatorsRes && operatorsRes.data) {
          const mappedOperators = operatorsRes.data.account.rows.map(
            (operator) => {
              const { id, name, loyaltyProgram } = operator;
              return { id, name, loyaltyProgram };
            }
          );
          dispatch(subHeaderActions.storeOperators(mappedOperators));
          dispatch(
            subHeaderActions.storeLoyaltyProgram(activeOperator?.loyaltyProgram)
          );
          const sortedOperatorsList = stableSort(
            mappedOperators,
            getComparator("asc", "name")
          );
          setOperatorOptions([ALL_OPERATORS, ...sortedOperatorsList]);
        }
      })
      .then(() => {
        setIsLoaded(true);
      })
      .catch((err) => {
        if (err.message === CANCEL_TOKEN) return;
        Sentry.captureException(err);
        console.error(ERROR_MESSAGES_FOR_USERS.FETCHING_OPERATORS_FILTERS_ERROR_MESSAGE, err);
      });
  }

  function handleOperatorChange(value) {
    setLocationSearchText("");
    if (value) {
      setNoOperator("text");
      handleFilterChange(ALL_LOCATIONS.id, LOCATION_PARAM);
      handleFilterChange(value.id, OPERATOR_PARAM);
      setLocationOptions([{ id: ALL_LOCATIONS.id, name: ALL_LOCATIONS.name }]);
      dispatch(subHeaderActions.storeSelectedOperator(value));
      dispatch(subHeaderActions.storeLoyaltyProgram(value.loyaltyProgram));
    } else {
      setNoOperator("");
    }
  }

  function handleLocationChange(value) {
    let val = value ?? "";
    if (val) {
      handleFilterChange(val, LOCATION_PARAM);
      dispatch(subHeaderActions.storeSelectedLocation(value));
      setNoLocation("text");
    } else {
      setNoLocation("");
    }
  }
  return (
    <div className={styles.filterWrapper} id="main-filters">
      {datePeriodVisible ? (
        <div className={styles.periodBlock}>
          <DatePickerComponent
            className={styles.inputPicker}
            label="Start date"
            views={DATE_PICKER_COMPONENT_VIEWS}
            maxDate={selectedFilters.endDate || today}
            value={selectedFilters.startDate}
            onChange={(date) => handleFilterChange(date, "startDate")}
          />
          <DatePickerComponent
            className={styles.inputPicker}
            label="End date"
            maxDate="2100-12-31"
            value={selectedFilters.endDate}
            views={DATE_PICKER_COMPONENT_VIEWS}
            minDate={selectedFilters.startDate || 0}
            onChange={(date) => handleFilterChange(date, "endDate")}
          />

          <Button
            size="large"
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleResetClick}
          >
            Reset
          </Button>
        </div>
      ) : (
        history?.pathname !== PATHNAME && (
          <Button
            className={styles.togglePeriod}
            size="large"
            variant="outlined"
            color="primary"
            disableElevation
            onClick={() => setDatePeriodVisible(true)}
          >
            Select custom period
          </Button>
        )
      )}

      {history?.pathname !== PATHNAME && (
        <div className={styles.periodContainer}>
          <ToggleButtonGroup
            exclusive
            size="small"
            value={selectedFilters.period}
            onChange={(e, value) =>{
              return(
              value && handleFilterChange(value, "period"))}
            }
          >
            {filters.map((filter) => {
              const { newAlias, value } = filter;

              return (
                <ToggleButton
                  className={styles.spacedButtons}
                  key={newAlias}
                  value={value}
                  aria-label={newAlias}
                >
                  {newAlias}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </div>
      )}
      {Boolean(updatedLocation === ALL_LOCATIONS.id || firstLocationFetch) && (
        <ClickAwayListener onClickAway={() => setNoLocation("noLocation")}>
          <Autocomplete
            options={searchLocationOptions}
            getOptionLabel={(option) => option.name}
            value={locationOptions.find((v) => v.id === updatedLocation)}
            onChange={(event, option) => handleLocationChange(option?.id)}
            style={{ minWidth: 160, marginLeft: "10px", display: "grid" }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                onChange={(e) =>
                  handleTextChange(e.target.value, LOCATION_PARAM)
                }
                onClick={(e) =>
                  handleTextChange(e.target.value, LOCATION_PARAM)
                }
                placeholder={"Select Location"}
              />
            )}
          />
        </ClickAwayListener>
      )}
      {Boolean(hasMultipleAccounts2()) && (
        <ClickAwayListener onClickAway={() => setNoOperator("noOperator")}>
          <Autocomplete
            getOptionLabel={(option) => option.name}
            value={
              activeOperator.id == null ? operatorOptions[0] : activeOperator
            }
            onChange={(event, option) => handleOperatorChange(option)}
            options={operatorOptions}
            style={{ minWidth: 160, marginLeft: "10px", display: "grid" }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                onClick={(e) => handleTextChange(e.target.value, "operator")}
                placeholder={"Select Operator"}
              />
            )}
          />
        </ClickAwayListener>
      )}
    </div>
  );
}

function getSerializedFilters(search) {
  const parsedSearch = queryString.parse(search);

  for (const key in parsedSearch)
    if (parsedSearch[key] && (key === "startDate" || key === "endDate")) {
      const dateString = parsedSearch[key];

      parsedSearch[key] = new Date(dateString);
    }

  return parsedSearch;
}

function stringifyFiltersToSearch(filters) {
  if (!filters) return "";

  const preparedFilters = {};

  for (const key of Object.keys(filters))
    if (filters[key]) {
      preparedFilters[key] = filters[key].toString();
    }

  return queryString.stringify(preparedFilters);
}
