import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  Input,
  InputLabel,
  InputAdornment,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import to from "await-to-js";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import * as Sentry from "@sentry/react";

import { getLocations } from "app/services/locations";
import {
  issueYokeCash,
  issueYokePoints,
} from "app/components/Customers/services/customers";
import { yokePointsValidator } from "app/components/OperatorApplication/components/OperatorApplication/validators/validators";
import Filter from "../Filter";

import styles from "../../../../_metronic/_assets/sass/pages/customers.module.scss";
import { useSelector } from "react-redux";
import { ISSUEYOKE_CREDIT_REQUIRED } from "app/common/constant";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
}));

export default function IssueYokeCredit(props) {
  const classes = useStyles();
  const [isModalOpen, setModalOpen] = useState(false);
  const [type, setType] = useState("Yoke Points");
  const [giftType, setGiftType] = useState("oneTime");
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedIssueOn, setSelectedIssueOn] = useState("");
  const [selectedExpiresOn, setSelectedExpiresOn] = useState("");
  const [customExpiresDate, setCustomExpiresDate] = useState(today);

  const [isResponse, setResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [isError, setIsError] = useState(false);

  const [credits, setCredits] = useState(0);
  const { selectedCustomers, onUpdate, groupsList = [] } = props;
  const compressedLocations = useSelector(
    (state) => state.subHeader.compressedLocationList
  );

  const IssueOnOptions = [
    {
      id: 1,
      value: "once",
      name: "once",
    },
    {
      id: 2,
      value: "every month",
      name: "Every Month",
    },
    {
      id: 3,
      value: "every quarter",
      name: "Every Quarter",
    },
  ];

  const ExpiresOnOptions = [
    {
      id: 1,
      value: "endOfMont",
      name: "End of month",
    },
    {
      id: 2,
      value: "endOfQuarter",
      name: "End of quarter",
    },
  ];

  const today = new Date();

 

  /**
   * Issue Yoke credit
   */
  function onIssueYokeCreditClick() {
    if (!selectedLocation || credits <= 0) {
      setIsError(true);
      return;
    }
    setModalOpen(false);
    onUpdate();
    const userIds = selectedCustomers.map((x) => x.id);
    var failed = [];
    var success = [];
    userIds.forEach(async (userId) => {
      var issue = null;
      try {
        if (type === "dollar") {
          issue = await to(issueYokeCash(userId, credits, selectedLocation));
        } else {
          issue = await to(issueYokePoints(userId, parseFloat(credits),selectedLocation));
        }

        console.log(issue);
        if (issue[0] && issue[0].status !== 200) {
          setResponseMessage(responseMessage + " Failed to send to " + userId);
          failed.push(userId);
        } else {
          success.push(userId);
        }
      } catch (e) {
        setResponseMessage(e.message);
        return;
      }
      setResponseMessage(
        success.length === 0 ? `failed to issue Yoke Points`:
        responseMessage +
          `${credits} ${type} were issued to ${success.length} customers.`
      );
    });
    setResponse(true);
    setModalOpen(true);
  }

  function handleSelectLocation(value) {
    setSelectedLocation(value);
  }

  function handleSelectGroup(value) {
    setSelectedGroup(value);
  }

  function handleSelectIssueOn(value) {
    setSelectedIssueOn(value);
  }

  function handleSelectExpiresOn(value) {
    setSelectedExpiresOn(value);
  }

  function handleCustomExpiresDate(value) {
    setCustomExpiresDate(value);
  }

  function handleModelClose() {
    setModalOpen(false);
    setCredits(0);
    setResponse(false);
    setResponseMessage("");
    setSelectedLocation("");
  }

  return (
    <>
      <Button
        className={styles.btn}
        size="large"
        variant="outlined"
        color="primary"
        disableElevation
        disabled={!selectedCustomers.length}
        onClick={() => [setModalOpen(true), setIsError(false)]}
      >
        Issue Yoke Points
      </Button>

      {isModalOpen ? (
        <Dialog
          open
          onClose={() => setModalOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Issue Yoke Points</DialogTitle>
          {isResponse ? (
            <DialogContent className="d-flex flex-column">
              {responseMessage ? responseMessage : "processing..."}
            </DialogContent>
          ) : null}
          <DialogContent hidden={isResponse} className="d-flex flex-column">
            <FormControl
              hidden={isResponse}
              component="fieldset"
              error={isError && !selectedLocation}
            >
              <FormLabel component="legend">Location</FormLabel>
              <Filter
                variant={null}
                idBased
                selectedFilter={selectedLocation}
                onChange={(e) => handleSelectLocation(e.target.value)}
                options={compressedLocations}
              />
              {isError && !selectedLocation &&<FormHelperText>{ISSUEYOKE_CREDIT_REQUIRED}</FormHelperText>}
            </FormControl>

            <FormControl
              hidden={type === "yokePoints"}
              component="fieldset"
              error={isError}
            >
              <FormLabel component="legend">Gift Card Type</FormLabel>
              <RadioGroup
                aria-label="giftCardType"
                name="giftCardType"
                value={giftType}
                onChange={(e) => setGiftType(e.target.value)}
              >
                <FormControlLabel
                  value="oneTime"
                  control={<Radio />}
                  label="One time"
                />
                <FormControlLabel
                  value="recurring"
                  control={<Radio />}
                  label="Recurring"
                />
              </RadioGroup>
            </FormControl>

            <FormControl
              hidden={giftType === "oneTime" || type === "yokePoints"}
              component="fieldset"
            >
              <FormLabel component="legend">Group</FormLabel>
              <Filter
                variant={null}
                idBased
                selectedFilter={selectedGroup}
                onChange={(e) => handleSelectGroup(e.target.value)}
                options={groupsList}
              />

              <FormLabel component="legend">Issue On</FormLabel>
              <Filter
                variant={null}
                idBased
                selectedFilter={selectedIssueOn}
                onChange={(e) => handleSelectIssueOn(e.target.value)}
                options={IssueOnOptions}
              />

              {selectedIssueOn !== 1 && (
                <>
                  <FormLabel component="legend">Expires On</FormLabel>
                  <Filter
                    variant={null}
                    idBased
                    selectedFilter={selectedExpiresOn}
                    onChange={(e) => handleSelectExpiresOn(e.target.value)}
                    options={ExpiresOnOptions}
                  />{" "}
                </>
              )}
            </FormControl>

            <FormControl
              hidden={
                type === "yokePoints" ||
                (giftType === "recurring" && selectedIssueOn !== 1)
              }
              component="fieldset"
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  views={['date', 'month' ,'year']}
                  format="MM/dd/yyyy"
                  margin="none"
                  minDate={today}
                  id="custom expires On"
                  label="Expires On"
                  value={customExpiresDate}
                  onChange={(date) => handleCustomExpiresDate(date)}
                  KeyboardButtonProps={{
                    "aria-label": "change start date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>

            <FormControl
              hidden={isResponse}
              fullWidth
              className={classes.margin}
              error={isError && credits <= 0}
            >
              <InputLabel htmlFor="standard-adornment-amount">
                Yoke Points
              </InputLabel>
              <Input
                type="number"
                id="standard-adornment-amount"
                value={credits}
                onKeyDown={yokePointsValidator}
                onChange={(e) => setCredits(e.target.value != '' ? e.target.value >= 0 ? e.target.value : '' : '')}
                startAdornment={
                  type === "dollar" && (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }
                endAdornment={
                  type === "points" && (
                    <InputAdornment position="end">points</InputAdornment>
                  )
                }
              />
              {isError && credits <= 0 &&<FormHelperText>{ISSUEYOKE_CREDIT_REQUIRED}</FormHelperText>}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModelClose} color="primary">
              {isResponse ? "Close" : "Cancel"}
            </Button>
            <Button
              hidden={isResponse}
              onClick={onIssueYokeCreditClick}
              autoFocus
              variant="contained"
              color="primary"
              disableElevation
            >
              Issue Yoke Points
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
}
